import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import EmptyIndexLayout from "layouts/baseComponents/EmptyIndexLayout";
import IndexLayout from "layouts/baseComponents/indexLayout";
import { getTableData } from "./inviteData/dataTableData";
import InvitesContext from "context/InvitesContext";
import CustomerContext from "context/CustomerContext";
import IndexLayoutWithOutAddNew from "layouts/baseComponents/indexLayoutWithoutAddNew";
import CircularProgress from "@mui/material/CircularProgress";

function Invites(): JSX.Element {
  const { invites } = useContext(InvitesContext);
  const { currentCustomer } = useContext(CustomerContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentCustomer && invites) {
      setIsLoading(false);
    }
  }, [currentCustomer, invites]);

  const handleRowClick = (inviteId: string) => {
    navigate(`/employees/edit-invite/${inviteId}`);
  };

  const createNewInvite = () => {
    navigate("/employees/create-invite");
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  // Conditionally render IndexLayoutWithOutAddNew if numberOfUsers >= invites.length
  if (currentCustomer.numberOfUsers <= invites.length) {
    return (
      <IndexLayoutWithOutAddNew
        title={"Inbjudningar: " + invites.length + "/" + currentCustomer.numberOfUsers}
      >
        <DataTableCard data={getTableData(invites)} onRowClick={() => {}} showHoverStyle={true} />
      </IndexLayoutWithOutAddNew>
    );
  }

  return (
    <IndexLayout
      title="Inbjudningar"
      createLink="/employees/create-invite"
      onNewItemClick={createNewInvite}
      addNewText="inbjudan"
    >
      <DataTableCard
        data={getTableData(invites)}
        onRowClick={handleRowClick}
        showHoverStyle={true}
      />
    </IndexLayout>
  );
}

export default Invites;
