import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDButton from "components/MDButton";
import { doc, updateDoc } from "firebase/firestore";
import { FirestoreDb } from "../../../../firebase";
import { AdminWithAppUserProps } from "db/models/users/AdminWithUserProps";

const db = FirestoreDb();

interface EditAdminDialogProps {
  open: boolean;
  onClose: () => void;
  admin: AdminWithAppUserProps;
}

const EditAdminDialog: React.FC<EditAdminDialogProps> = ({ open, onClose, admin }) => {
  const [formValues, setFormValues] = useState<AdminWithAppUserProps>({ ...admin });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = async () => {
    try {
      // const adminRef = doc(db, "admins", admin.id);
      // await updateDoc(adminRef, formValues);
      // In here we will update the user, not the admin itself, the "admins" and "users"
      // are only an id stored on the customer, we should save the actual user object with the properties
      // and add the id to "admins" and "appUsers" accordignly.
      if (formValues.isAppUser) {
        const userRef = doc(db, "users", admin.id);
        // await updateDoc(userRef, formValues);
      }
      onClose();
    } catch (error) {
      alert("Error updating admin: " + error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Admin</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              value={formValues.firstName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formValues.lastName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.isOwner}
                  onChange={handleInputChange}
                  name="isOwner"
                  color="primary"
                />
              }
              label="Is Owner"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.isAppUser}
                  onChange={handleInputChange}
                  name="isAppUser"
                  color="primary"
                />
              }
              label="Is App User"
            />
          </Grid>
          {formValues.isAppUser && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="personalSecurityNumber"
                  name="personalSecurityNumber"
                  label="Personal Security Number"
                  value={formValues.personalSecurityNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="role"
                  name="role"
                  label="Role"
                  value={formValues.role}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <DialogActions>
          <MDButton onClick={onClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={handleSave} color="primary">
            Save
          </MDButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditAdminDialog;
