/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://mmkdosemonitoring.seproduct/material-dashboard-2-pro-react-ts
* Copyright 2023 MMK Dose Monitoring (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  useState,
  useEffect,
  useMemo,
  JSXElementConstructor,
  Key,
  ReactElement,
  useContext,
} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import authentiactedRoutes from "routes";

import SignInCover from "layouts/authentication/sign-in";
import ResetPasswordCover from "layouts/authentication/reset-password";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/mmk-logga.png";
import brandDark from "assets/images/mmk-logga.png";
import { AuthContext } from "context/auth-context";
import CreateObject from "layouts/superAdmin/objects/create";
import CreateCustomer from "layouts/superAdmin/customers/create";
import CreatePlannedWorkshift from "layouts/workshifts/plannedWorkshifts/create";
import CreateEditProject from "layouts/projects/create";
import CreateDoneWorkshift from "layouts/workshifts/doneWorkshifts/create";
import EditWorkShift from "layouts/workshifts/doneWorkshifts/edit";
import EditPlannedWorkShift from "layouts/workshifts/plannedWorkshifts/edit";
import Employee from "layouts/employees/employee";
import CreateEditInvite from "layouts/employees/invites/createInvite";
import CreateAdmin from "layouts/admins/create";
import ConfirmResetPassword from "layouts/authentication/components/confirm-reset-password";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { currentUser, isSuperAdmin, userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const isAuthenticated = !!currentUser;

  // NOTE: console log for testing purposes
  // console.log("User:", !!currentUser);

  // Check if currentUser exists on initial render
  useEffect(() => {
    // Check if currentUser exists
    if (currentUser) {
      // Redirect to the analytics dashboard only if the current route is the sign-in page
      if (pathname === "/authentication/sign-in") {
        navigate("/dashboard");
      }
    } else {
      // Check if the current route is an authentication route
      const isAuthRoute = [
        "/authentication/sign-in",
        "/authentication/reset-password",
        "/authentication/confirm-reset-password",
      ].includes(pathname);
      if (!isAuthRoute) {
        // Redirect to the sign-in route if not already on an authentication route
        navigate("/authentication/sign-in");
      }
    }
  }, [currentUser, pathname]);

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  interface RouteObject {
    path: string;
    element: ReactElement;
    key: string;
  }

  const publicRoutes: RouteObject[] = [
    { path: "/authentication/sign-in", element: <SignInCover />, key: "signIn" },
    {
      path: "/authentication/reset-password",
      element: <ResetPasswordCover />,
      key: "resetPassword",
    },
    {
      path: "/authentication/confirm-reset-password",
      element: <ConfirmResetPassword />,
      key: "confirmResetPassword",
    },
  ];

  // Authenticated routes
  const authenticatedRoutes: RouteObject[] = [
    {
      path: "/superadmin/create-customer",
      element: <CreateCustomer mode="create" />,
      key: "createCustomer",
    },
    {
      path: "/superadmin/edit-customer/:customerId",
      element: <CreateCustomer mode="edit" />,
      key: "editCustomer",
    },
    {
      path: "/superadmin/create-object",
      element: <CreateObject mode="create" />,
      key: "createObject",
    },
    {
      path: "/superadmin/edit-object/:objectId",
      element: <CreateObject mode="edit" />,
      key: "editObject",
    },
    {
      path: "/workshifts/plannedshifts/create-planned-shift",
      element: <CreatePlannedWorkshift />,
      key: "createPlannedShift",
    },
    {
      path: "/workshifts/plannedshifts/edit-planned-shift/:shiftId",
      element: <EditPlannedWorkShift />,
      key: "editPlannedShift",
    },
    {
      path: "/workshifts/doneshifts/create-done-shift",
      element: <CreateDoneWorkshift />,
      key: "createDoneShift",
    },
    {
      path: "/workshifts/doneshifts/edit-done-shift/:shiftId",
      element: <EditWorkShift />,
      key: "editDoneShift",
    },
    {
      path: "/projects/create-project",
      element: <CreateEditProject mode="create" />,
      key: "createProject",
    },
    {
      path: "/projects/edit-project/:projectId",
      element: <CreateEditProject mode="edit" />,
      key: "editProject",
    },
    { path: "/employees/overview/:employeeId", element: <Employee />, key: "viewEmployee" },
    {
      path: "/employees/create-invite",
      element: <CreateEditInvite mode="create" />,
      key: "createInvite",
    },
    {
      path: "/employees/edit-invite/:inviteId",
      element: <CreateEditInvite mode="edit" />,
      key: "editInvite",
    },
    { path: "/admins/create-admin", element: <CreateAdmin />, key: "createAdmin" },
  ];

  // Function to get routes based on authentication status
  const getConditionalRoutes = (isAuthenticated: boolean): RouteObject[] => {
    return isAuthenticated ? authenticatedRoutes : publicRoutes;
  };

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="MMK DOSE MONITORING"
              routes={authentiactedRoutes(currentUser, isSuperAdmin, userData)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getConditionalRoutes(isAuthenticated).map(({ path, element, key }) => (
            <Route path={path} element={element} key={key} />
          ))}
          {getRoutes(authentiactedRoutes(currentUser, isSuperAdmin, userData))}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="MMK DOSE MONITORING"
            routes={authentiactedRoutes(currentUser, isSuperAdmin, userData)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getConditionalRoutes(isAuthenticated).map(({ path, element, key }) => (
          <Route path={path} element={element} key={key} />
        ))}

        {getRoutes(authentiactedRoutes(currentUser, isSuperAdmin, userData))}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
