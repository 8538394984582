import { format } from "date-fns";
import { WorkObject } from "db/models/workObject";
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";

const getTableData = (objects: Array<WorkObject>) => {
  var dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "name", accessor: "name" },
      { Header: "code", accessor: "code" },
      { Header: "customer", accessor: "customer" },
      { Header: "avaliable From", accessor: "avaliableFrom" },
      { Header: "avaliable To", accessor: "avaliableTo" },
      { Header: "action Plans", accessor: "actionPlans" },
    ],
    rows: objects.map((o) => {
      console.log("avaliableFrom:", o.avaliableFrom);
      console.log("avaliableTo:", o.avaliableTo);

      // Validate avaliableFrom and avaliableTo before formatting
      const formattedAvaliableFrom = o.avaliableFrom
        ? format(new Date(o.avaliableFrom.seconds * 1000), "MM/dd/yyyy HH:mm:ss")
        : "-";
      const formattedAvaliableTo = o.avaliableTo
        ? format(new Date(o.avaliableTo.seconds * 1000), "MM/dd/yyyy HH:mm:ss")
        : "-";

      return {
        id: o.id,
        name: o.name,
        code: o.code,
        customer: o.customer.name,
        avaliableFrom: formattedAvaliableFrom,
        avaliableTo: formattedAvaliableTo,
        actionPlans: o.actionPlans.length,
      };
    }),
  };
  return dataTableData;
};

export default getTableData;
