import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import { UserModel } from "db/models/users/UserModel";
import { toColor } from "helpers/monthlyDose";

interface UserExposure {
  userId: string;
  daysUnder70: number;
  daysUnder90: number;
  daysUnder100: number;
  daysOver100: number;
}

interface AggregateExposures {
  totalUsersUnder70: number;
  totalUsersUnder90: number;
  totalUsersUnder100: number;
  totalUsersOver100: number;
  color: string;
}

const maxRadiationPerYear = 720000;
const dailyMaxRadiation = maxRadiationPerYear / 365;

export const calculateUserExposures = (
  shifts: Array<DoneShift | PlannedShift>,
  users: UserModel[]
): AggregateExposures => {
  if (users.length === 0) {
    return {
      totalUsersUnder70: 0,
      totalUsersUnder90: 0,
      totalUsersUnder100: 0,
      totalUsersOver100: 0,
      color: toColor(0),
    };
  }

  const userDailyExposures: Record<string, Record<string, DailyExposure>> = {};

  shifts.forEach((shift) => {
    const userId = shift.user.id;
    if (!userDailyExposures[userId]) {
      userDailyExposures[userId] = {};
    }
    const userExposure = userDailyExposures[userId];
    const startTime =
      "actualStartTime" in shift
        ? new Date(shift.actualStartTime)
        : new Date(shift.plannedStartTime);
    const endTime =
      "actualEndTime" in shift ? new Date(shift.actualEndTime) : new Date(shift.plannedEndTime);
    const startDay = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
    const endDay = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());

    let currentDate = startDay;
    while (currentDate <= endDay) {
      const dateKey = currentDate.toISOString().split("T")[0]; // Format YYYY-MM-DD
      if (!userExposure[dateKey]) {
        userExposure[dateKey] = { totalDose: 0, count: 0 };
      }
      userExposure[dateKey].totalDose += shift.radiationDose;
      userExposure[dateKey].count++;
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });

  let totalUsersUnder70 = 0,
    totalUsersUnder90 = 0,
    totalUsersUnder100 = 0,
    totalUsersOver100 = 0;

  for (const userId in userDailyExposures) {
    let userMaxExposure = 0;
    Object.values(userDailyExposures[userId]).forEach(({ totalDose, count }) => {
      const dailyDose = totalDose / count;
      const dailyExposure = (dailyDose / dailyMaxRadiation) * 100;
      userMaxExposure = Math.max(userMaxExposure, dailyExposure);
    });

    if (userMaxExposure < 70) {
      totalUsersUnder70++;
    } else if (userMaxExposure < 90) {
      totalUsersUnder90++;
    } else if (userMaxExposure < 100) {
      totalUsersUnder100++;
    } else {
      totalUsersOver100++;
    }
  }

  const overallMaxExposure = Math.max(
    totalUsersUnder70,
    totalUsersUnder90,
    totalUsersUnder100,
    totalUsersOver100
  );

  const color = toColor(overallMaxExposure);

  return {
    totalUsersUnder70,
    totalUsersUnder90,
    totalUsersUnder100,
    totalUsersOver100,
    color,
  };
};

interface DailyExposure {
  totalDose: number;
  count: number;
}

export const calculateDaysAndHighlight = (shifts: Array<DoneShift | PlannedShift>) => {
  let highestDailyDosePercentage = 0;

  const dailyExposures: Record<string, DailyExposure> = {};

  shifts.forEach((shift) => {
    const startTime =
      "actualStartTime" in shift
        ? new Date(shift.actualStartTime)
        : new Date(shift.plannedStartTime);
    const endTime =
      "actualEndTime" in shift ? new Date(shift.actualEndTime) : new Date(shift.plannedEndTime);
    const startDay = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
    const endDay = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());

    let currentDay = startDay;
    while (currentDay <= endDay) {
      const key = currentDay.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
      if (!dailyExposures[key]) {
        dailyExposures[key] = { totalDose: 0, count: 0 };
      }

      dailyExposures[key].totalDose += shift.radiationDose;
      dailyExposures[key].count += 1;

      currentDay = new Date(currentDay.setDate(currentDay.getDate() + 1));
    }
  });

  let daysUnder70 = 0,
    daysUnder90 = 0,
    daysUnder100 = 0,
    daysOver100 = 0;

  Object.values(dailyExposures).forEach(({ totalDose, count }) => {
    const averageDose = totalDose / count;
    const dailyDosePercentage = (averageDose / dailyMaxRadiation) * 100;

    highestDailyDosePercentage = Math.max(highestDailyDosePercentage, dailyDosePercentage);

    if (dailyDosePercentage < 70) daysUnder70 += 1;
    else if (dailyDosePercentage < 90) daysUnder90 += 1;
    else if (dailyDosePercentage < 100) daysUnder100 += 1;
    else daysOver100 += 1;
  });

  const color = toColor(highestDailyDosePercentage);
  let label = "0-70%";
  if (highestDailyDosePercentage >= 100) {
    label = ">100%";
  } else if (highestDailyDosePercentage >= 90) {
    label = "90-100%";
  } else if (highestDailyDosePercentage >= 70) {
    label = "70-90%";
  }

  return {
    daysUnder70,
    daysUnder90,
    daysUnder100,
    daysOver100,
    color,
    label,
    highestValue: highestDailyDosePercentage.toFixed(0),
  };
};
