import { FirestoreDb } from "../../firebase";
import { addDoc, collection, deleteDoc, doc, updateDoc, writeBatch } from "firebase/firestore";
import { Shift } from "db/models/shiftsModels/Shift";

// import firestore
const db = FirestoreDb();

// collection name
const COLLECTION_NAME = "shifts";

export const createBatchShifts = async (shifts: Shift[]): Promise<string[]> => {
  try {
    const batch = writeBatch(db); // Create a new write batch
    const batchedShiftIds: string[] = [];

    shifts.forEach((shift) => {
      const customerRef = doc(db, "customers", shift.customer.id);
      const projectRef = doc(db, "projects", shift.project.id);

      const { id, ...shiftWithoutId } = shift;

      const shiftToSave = {
        ...shiftWithoutId,
        customerReference: customerRef,
        projectReference: projectRef,
      };

      const newDocRef = doc(collection(db, "shifts")); // Generate a new document reference
      batchedShiftIds.push(newDocRef.id); // Store the generated document ID

      batch.set(newDocRef, shiftToSave); // Use batch.set to add the document to the batch
    });

    // Commit the batched write operation
    await batch.commit();

    return batchedShiftIds;
  } catch (error) {
    console.error("Error creating shifts in batch:", error);
    throw error; // Optionally handle the error or throw it to the caller
  }
};

// Create a new shift
export const create = async (shift: Shift): Promise<string> => {
  try {
    const customerRef = doc(db, "customers", shift.customer.id);
    const projectRef = doc(db, "projects", shift.project.id);

    const { id, ...shiftWithoutId } = shift;

    const shiftToSave = {
      ...shiftWithoutId,
      customerReference: customerRef,
      projectReference: projectRef,
    };
    const docRef = await addDoc(collection(db, COLLECTION_NAME), shiftToSave);
    return docRef.id;
  } catch (error) {
    console.error("Error creating shift:", error);
    throw error; // Optionally handle the error or throw it to the caller
  }
};

// Update a shift
export const updateShift = async (id: string, shift: Shift): Promise<void> => {
  try {
    const docRef = doc(db, COLLECTION_NAME, id);

    // Convert the PlannedShift object to a plain JavaScript object
    const shiftData = { ...shift };

    await updateDoc(docRef, shiftData);
  } catch (error) {
    console.error("Error updating planned shift:", error);
    throw error; // Re-throw the error to be handled by the calling code if needed
  }
};

// Delete a shift
export const remove = async (id: string): Promise<void> => {
  try {
    const docRef = doc(db, COLLECTION_NAME, id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error deleting shift:", error);
    throw error; // Optionally handle the error or throw it to the caller
  }
};

// Batch delete shifts
export const deleteBatchShifts = async (shiftIds: string[]): Promise<void> => {
  try {
    const batch = writeBatch(db); // Create a new write batch

    shiftIds.forEach((shiftId) => {
      const docRef = doc(db, COLLECTION_NAME, shiftId);
      batch.delete(docRef); // Use batch.delete to add the delete operation to the batch
    });

    // Commit the batched delete operation
    await batch.commit();
    console.log("Shifts deleted successfully in batch.");
  } catch (error) {
    console.error("Error deleting shifts in batch:", error);
    throw error; // Optionally handle the error or throw it to the caller
  }
};
