import React, { useContext, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { WorkObject } from "db/models/workObject";
import FormField from "layouts/authentication/account/components/FormField";
import { Dispatch, SetStateAction } from "react";
import ObjectsContext from "context/ObjetcsContext";

interface WorkObjectDropDownProps {
  setWorkObject: Dispatch<SetStateAction<WorkObject | null>>;
  selectedObjectId?: string | null;
}

function WorkObjectDropDown({
  setWorkObject,
  selectedObjectId,
}: WorkObjectDropDownProps): JSX.Element {
  const [selectedWorkObject, setSelectedWorkObject] = useState<WorkObject | null>(null);
  const { objects } = useContext(ObjectsContext);

  useEffect(() => {
    // Match selected object with provided ID in edit mode
    if (selectedObjectId && objects.length > 0) {
      const foundObject = objects.find((obj) => obj.id === selectedObjectId);
      setSelectedWorkObject(foundObject || null);
      setWorkObject(foundObject);
    }
  }, [selectedObjectId, objects]);

  return (
    <Autocomplete
      id="work-object-autocomplete" // Add an id to the Autocomplete
      value={selectedWorkObject}
      options={objects.sort((a, b) => a.code.localeCompare(b.code))}
      onChange={(event, newValue) => {
        setSelectedWorkObject(newValue);
        setWorkObject(newValue);
      }}
      getOptionLabel={(option) => `${option.code} - ${option.name}`}
      renderInput={(params) => (
        <FormField
          id="object-selector" // Add an id to the input field
          {...params}
          label="Välj objekt"
          InputLabelProps={{ shrink: true }}
          placeholder="Vänligen välj objekt"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} data-testid={`work-object-option-${option.code}`}>
          {option.code} - {option.name}
        </li>
      )}
    />
  );
}

export default WorkObjectDropDown;
