import { format } from "date-fns";
import { WorkObject } from "db/models/workObject";

const getTableData = (objects: Array<WorkObject>) => {
  var dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Namn", accessor: "name" },
      { Header: "KOD", accessor: "code" },
      { Header: "TIllgänglig från", accessor: "avaliableFrom" },
      { Header: "Tillgänglig till", accessor: "avaliableTo" },
      { Header: "Mätvärde (Bq/m3)", accessor: "actionPlans", align: "right" },
    ],
    rows: objects.map((o) => {
      console.log("avaliableFrom:", o.avaliableFrom);
      console.log("avaliableTo:", o.avaliableTo);

      // Validate avaliableFrom and avaliableTo before formatting
      const formattedAvaliableFrom = o.avaliableFrom
        ? format(new Date(o.avaliableFrom.seconds * 1000), "MM/dd/yyyy HH:mm:ss")
        : "-";
      const formattedAvaliableTo = o.avaliableTo
        ? format(new Date(o.avaliableTo.seconds * 1000), "MM/dd/yyyy HH:mm:ss")
        : "-";

      // Get the latest action plan's radiation level based on measureDate
      const latestActionPlan = o.actionPlans
        .filter((plan) => plan.measureDate) // Ensure measureDate is not null
        .sort((a, b) => b.measureDate!.seconds - a.measureDate!.seconds)[0];

      const actionPlanRadiationLevel = latestActionPlan ? latestActionPlan.radiationLevel : "N/A";

      return {
        id: o.id,
        name: o.name,
        code: o.code,
        avaliableFrom: formattedAvaliableFrom,
        avaliableTo: formattedAvaliableTo,
        actionPlans: actionPlanRadiationLevel,
      };
    }),
  };
  return dataTableData;
};

export default getTableData;
