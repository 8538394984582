import { ActionPlanModel } from "../dataDuplicationModels/ActionPlanModel";
import { CustomerModel } from "../dataDuplicationModels/CustomerModel";
import { ObjectModel } from "../dataDuplicationModels/ObjectModel";
import { ProjectModel } from "../dataDuplicationModels/ProjectModel";
import { UserModel } from "../dataDuplicationModels/UserModel";

export class CalculatableShiftModel {
  id: string | null;
  project: ProjectModel | null;
  object: ObjectModel;
  actionPlan: ActionPlanModel;
  customer: CustomerModel;
  user: UserModel;
  radiationDose: number;
  startTime: Date;
  endTime: Date | null;
  shiftStaus: ShiftStatus;

  constructor(
    shiftId: string,
    project: ProjectModel,
    object: ObjectModel,
    actionPlan: ActionPlanModel,
    customer: CustomerModel,
    user: UserModel,
    radiationDose: number,
    startTime: Date,
    endTime: Date,
    shiftStatus: ShiftStatus
  ) {
    this.id = shiftId;
    this.project = project;
    this.object = object;
    this.actionPlan = actionPlan;
    this.customer = customer;
    this.user = user;
    this.radiationDose = radiationDose;
    this.startTime = startTime;
    this.endTime = endTime;
    this.shiftStaus = shiftStatus;
  }
}

export enum ShiftStatus {
  Planned,
  Done,
  Ongoing,
}
