import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";

export interface UnifiedShift {
  startTime: Date;
  endTime: Date;
  radiationDose: number;
}

class ShiftUnifier {
  static unifyShifts(
    doneShifts: DoneShift[],
    plannedShifts: PlannedShift[],
    includePlanned: boolean,
    startDate: Date,
    endDate: Date
  ): UnifiedShift[] {
    let unifiedShifts: UnifiedShift[] = doneShifts
      .filter((shift) => shift.actualEndTime <= endDate)
      .map((shift) => ({
        startTime: shift.actualStartTime,
        endTime: shift.actualEndTime,
        radiationDose: shift.radiationDose,
      }));

    if (includePlanned) {
      const filteredPlannedShifts = plannedShifts.filter(
        (shift) => shift.plannedEndTime <= endDate
      );
      const plannedToUnified = filteredPlannedShifts.map((shift) => ({
        startTime: shift.plannedStartTime,
        endTime: shift.plannedEndTime,
        radiationDose: shift.radiationDose,
      }));
      unifiedShifts = [...unifiedShifts, ...plannedToUnified];
    }

    return unifiedShifts;
  }
}

export default ShiftUnifier;
