import { TextField } from "@mui/material";

interface DateSelectionProps {
  label: string;
  startDate: Date; // Adjust to accept a Date object
  setStartDate: (date: Date) => void; // Accepts a Date object
}

export const SimpleDateSelection: React.FC<DateSelectionProps> = ({
  label,
  startDate,
  setStartDate,
}) => {
  // Convert Date object to string for input value
  const dateString = startDate.toISOString().substring(0, 10);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Convert string back to Date object when updating state
    setStartDate(new Date(event.target.value));
  };

  return (
    <TextField
      label={label}
      type="date"
      value={dateString}
      onChange={handleDateChange}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};
