const config = {
  apiKey: "AIzaSyD0QKXN1cYb_sMyCRITXEEoUTzXWJ8QFWU",
  authDomain: "app.dosemonitoring.se",
  projectId: "mmk-dose-monitoring",
  storageBucket: "mmk-dose-monitoring.appspot.com",
  messagingSenderId: "415788838238",
  appId: "1:415788838238:web:0a0dca5a31d7691f0e73cf",
  measurementId: "G-230VVH04KN",
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error(
      "No Firebase configuration object provided." +
        "\n" +
        "Add your web app's configuration object to firebase-config.ts"
    );
  } else {
    return config;
  }
}

export function getFirebaseAppCheckSiteKey() {
  return "6Lcy1mYqAAAAANt0fqr8vH__9LnnhkNHra7wF_Lp";
}
