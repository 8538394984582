import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  CircularProgress,
  Typography,
  Box,
  Card,
} from "@mui/material";
import { query, collection, where, orderBy, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import { FirestoreDb } from "../../../../firebase";
import { InviteModel } from "db/models/invites/inviteModel";
import { InviteDbModel } from "db/models/invites/inviteDbModel";
import { getTableData } from "./data/dataTableData";
import MDButton from "components/MDButton";
import CreateInviteDialog from "./createInviteComponent"; // Adjust the import path as necessary
import MDTypography from "components/MDTypography";
import ImportUserList from "./ImportUserList";

const db = FirestoreDb();

interface CustomerInvitesProps {
  customerId: string;
  customerName: string;
}

function CustomerInvites({ customerId, customerName }: CustomerInvitesProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [invites, setInvites] = useState<InviteModel[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState<InviteModel | null>(null);

  function mapToInviteModel(dbInvite: InviteDbModel): InviteModel {
    return new InviteModel(dbInvite);
  }

  useEffect(() => {
    let unsubscribe = () => {};

    if (customerId) {
      const q = query(
        collection(db, "invites"),
        where("customerId", "==", customerId),
        orderBy("avaliableTo", "desc")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedInvites: InviteDbModel[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as InviteDbModel[];
        setInvites(fetchedInvites.map((inv) => mapToInviteModel(inv)));
      });
    } else {
      setInvites([]); // Reset invites if there is no selected customer
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [customerId]);

  useEffect(() => {
    setIsLoading(false);
  }, [customerId, invites]);

  const handleRowClick = (inviteId: string) => {
    const invite = invites.find((inv) => inv.id === inviteId);
    if (invite) {
      setSelectedInvite(invite);
      setOpenConfirmDialog(true);
    }
  };

  const handleDelete = async () => {
    if (selectedInvite) {
      try {
        await deleteDoc(doc(db, "invites", selectedInvite.id));
        setInvites(invites.filter((inv) => inv.id !== selectedInvite.id));
        setOpenConfirmDialog(false);
      } catch (error) {
        alert("Error deleting invite: " + error);
      }
    }
  };

  const createNewInvite = () => {
    setSelectedInvite(null);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid item xs={12} m={3}>
        <MDTypography variant="h5" gradient="secondary">
          Customer employee invites
        </MDTypography>
      </Grid>
      {invites.length === 0 ? (
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          No invites
        </Typography>
      ) : (
        <DataTableCard
          data={getTableData(invites)}
          onRowClick={(rowData) => handleRowClick(rowData)}
          showHoverStyle={true}
        />
      )}
      <Grid item xs={12} m={3}>
        <MDButton variant="gradient" color="dark" onClick={createNewInvite}>
          Create New Invite
        </MDButton>
        <br></br>
        <br></br>
        <ImportUserList customerId={customerId} customerName={customerName} />
      </Grid>
      <CreateInviteDialog open={openDialog} onClose={closeDialog} customerId={customerId} />
      <Dialog
        open={openConfirmDialog}
        onClose={closeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Delete Invite</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to delete this invite?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={closeConfirmDialog} color="primary">
            Cancel
          </MDButton>
          <MDButton onClick={handleDelete} color="error" autoFocus>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomerInvites;
