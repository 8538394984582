import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { v4 as uuidv4 } from "uuid";
import { Timestamp } from "firebase/firestore";
import CustomerDropDown from "./components/customerDropDown";
import { format } from "date-fns";
import MDButton from "components/MDButton";
import { batchSaveObjects } from "db/repositories/objectsRepository";
import { WorkObject } from "db/models/workObject";

interface MeasuredObject {
  id: string;
  name: string;
  radiationLevel: number;
  avaliableFrom: Timestamp;
  avaliableTo: Timestamp;
  code: string;
  actionPlans: Array<ActionPlan>;
}

interface ActionPlan {
  id: string;
  name: string;
  radiationLevel: number;
  measureDate: Timestamp | null;
}

const ImportObjectList = () => {
  const [excelData, setExcelData] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [previewMode, setPreviewMode] = useState(false); // Toggle between mapping and preview
  const [previewData, setPreviewData] = useState<MeasuredObject[] | null>(null);
  const [selectedColumns, setSelectedColumns] = useState({
    name: "",
    radiationLevel: "",
    code: "",
    availableFrom: "",
    availableTo: "",
  });
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(jsonData);
      setModalOpen(true);
    };
    reader.readAsBinaryString(file);
  };

  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({}); // Track expanded rows by index

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({ ...prev, [index]: !prev[index] })); // Toggle expanded state for the row
  };

  const handleColumnSelection = (field: keyof typeof selectedColumns, value: string) => {
    setSelectedColumns((prev) => ({ ...prev, [field]: value }));
  };

  const generateMeasuredObjects = () => {
    const objects: MeasuredObject[] = [];
    const now = new Date();
    const defaultAvailableFrom = new Date(
      now.getFullYear(),
      now.getMonth() - 1,
      now.getDate(),
      8,
      0
    ); // 1 month before now at 08:00
    const defaultAvailableTo = new Date(now.getFullYear() + 5, now.getMonth(), now.getDate(), 8, 0); // 5 years from now at 08:00

    for (let index = 0; index < excelData.length; index++) {
      const row = excelData[index];
      const name = row[selectedColumns.name] || "Unnamed";

      // Validate radiation level
      const radiationLevel = parseFloat(row[selectedColumns.radiationLevel]);
      if (isNaN(radiationLevel) || radiationLevel <= 0) {
        alert(`Row ${index + 1} (${name}): Radiation level must be a positive number.`);
        return; // Stop processing completely
      }

      // Add valid object to the array
      objects.push({
        id: uuidv4(),
        name: name,
        radiationLevel: radiationLevel,
        avaliableFrom: selectedColumns.availableFrom
          ? Timestamp.fromDate(new Date(row[selectedColumns.availableFrom]))
          : Timestamp.fromDate(defaultAvailableFrom),
        avaliableTo: selectedColumns.availableTo
          ? Timestamp.fromDate(new Date(row[selectedColumns.availableTo]))
          : Timestamp.fromDate(defaultAvailableTo),
        code: row[selectedColumns.code] || "No Code",
        actionPlans: [
          {
            id: uuidv4(),
            name: "Standard",
            radiationLevel: radiationLevel,
            measureDate: selectedColumns.availableFrom
              ? Timestamp.fromDate(new Date(row[selectedColumns.availableFrom]))
              : Timestamp.fromDate(defaultAvailableFrom),
          },
        ],
      });
    }

    // Set preview data only if all rows are valid
    setPreviewData(objects);
    setPreviewMode(true); // Switch to preview mode
  };

  const validateObject = (obj: MeasuredObject) => {
    // Ensure required fields are not null or undefined
    if (!obj.name || obj.name.trim() === "") return "Name is required.";
    if (!obj.code || obj.code.trim() === "") return "Code is required.";
    if (!(obj.avaliableFrom instanceof Timestamp))
      return "Available From must be a valid Timestamp.";
    if (!(obj.avaliableTo instanceof Timestamp)) return "Available To must be a valid Timestamp.";

    // Validate Action Plans
    if (!Array.isArray(obj.actionPlans) || obj.actionPlans.length === 0) {
      return "At least one action plan is required.";
    }
    for (const plan of obj.actionPlans) {
      if (!plan.id) return "Action Plan ID is missing.";
      if (!plan.name || plan.name.trim() === "") return "Action Plan name is required.";
      if (typeof plan.radiationLevel !== "number" || isNaN(plan.radiationLevel)) {
        return "Action Plan radiation level must be a valid number.";
      }
      if (!(plan.measureDate instanceof Timestamp)) {
        return "Action Plan measure date must be a valid Timestamp.";
      }
    }

    // All validations passed
    return null;
  };

  const saveObjects = async () => {
    if (!previewData || previewData.length === 0) {
      console.error("No objects to save.");
      return;
    }

    if (!selectedCustomer || !selectedCustomer.id) {
      alert("Please select a valid customer before saving objects.");
      console.error("No valid customer selected.");
      return;
    }

    for (const obj of previewData) {
      const validationError = validateObject(obj);
      if (validationError) {
        alert(`Validation Error: ${validationError}`);
        return;
      }
    }

    const confirmation = window.confirm(
      `Are you sure you want to create ${previewData.length} objects for ${
        selectedCustomer?.name || "No Customer Selected"
      }?`
    );

    if (!confirmation) {
      console.log("Save operation cancelled by the user.");
      return;
    }

    try {
      // Convert previewData into WorkObject instances if needed
      const objectsToSave = previewData.map((obj) => ({
        id: obj.id,
        name: obj.name,
        radiationLevel: obj.radiationLevel,
        avaliableFrom: obj.avaliableFrom,
        avaliableTo: obj.avaliableTo,
        code: obj.code,
        customer: selectedCustomer,
        actionPlans: obj.actionPlans,
      }));

      // Call saveObjects function
      await batchSaveObjects(objectsToSave);

      console.log("Objects saved successfully.");

      setModalOpen(false);
    } catch (error) {
      console.error("Error saving objects:", error);
    }
  };

  return (
    <Box>
      <MDButton variant="gradient" color="dark" component="label">
        Upload Excel or Numbers Document
        <input type="file" accept=".xlsx, .xls, .numbers" hidden onChange={handleFileUpload} />
      </MDButton>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>
          {previewMode
            ? `Preview Generated Objects for: ${selectedCustomer?.name || "No Customer Selected"}`
            : "Map Columns and Preview Data"}
        </DialogTitle>
        <DialogContent>
          {!previewMode ? (
            <>
              <Typography variant="body1" gutterBottom>
                Select columns for required and optional fields:
              </Typography>
              <CustomerDropDown
                setCustomer={setSelectedCustomer}
                preSelectedCustomer={selectedCustomer}
              />
              <br />
              <Grid container spacing={2} mb={2}>
                {Object.keys(selectedColumns).map((key) => (
                  <Grid item xs={6} sm={4} key={key}>
                    <Select
                      fullWidth
                      value={selectedColumns[key as keyof typeof selectedColumns]}
                      onChange={(e) =>
                        handleColumnSelection(key as keyof typeof selectedColumns, e.target.value)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        {key === "name" || key === "radiationLevel" || key === "code"
                          ? `Select column for ${key} (Required)`
                          : `Select column for ${key} (Optional)`}
                      </MenuItem>
                      {excelData.length > 0 &&
                        Object.keys(excelData[0]).map((column) => (
                          <MenuItem value={column} key={column}>
                            {column}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                ))}
              </Grid>
              <table
                style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    {excelData.length > 0 &&
                      Object.keys(excelData[0]).map((column) => (
                        <th key={column} style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {column}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.keys(row).map((key) => (
                        <td key={key} style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table
                style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                      Expand
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                      Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                      Code
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                      Radiation Level
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                      Available From
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                      Available To
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {previewData!.map((obj, index) => (
                    <React.Fragment key={index}>
                      {/* Main Object Row */}
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}
                        >
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                              color: "blue",
                            }}
                            onClick={() => toggleRow(index)}
                          >
                            {expandedRows[index] ? "Hide" : "Show action plan"}
                          </button>
                        </td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{obj.name}</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{obj.code}</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {obj.radiationLevel}
                        </td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {obj.avaliableFrom
                            ? format(
                                new Date(obj.avaliableFrom.seconds * 1000),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            : "N/A"}
                        </td>
                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {obj.avaliableTo
                            ? format(
                                new Date(obj.avaliableTo.seconds * 1000),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            : "N/A"}
                        </td>
                      </tr>

                      {/* Collapsible Action Plans Row */}
                      {expandedRows[index] && (
                        <tr>
                          <td colSpan={6} style={{ border: "1px solid #ddd", padding: "8px" }}>
                            <strong>Action Plans:</strong>
                            <table
                              style={{
                                width: "100%",
                                marginTop: "8px",
                                border: "1px solid #ddd",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    ID
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Radiation Level
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Measure Date
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {obj.actionPlans.map((plan) => (
                                  <tr key={plan.id}>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                      {plan.id}
                                    </td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                      {plan.name}
                                    </td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                      {plan.radiationLevel}
                                    </td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                      {plan.measureDate
                                        ? format(
                                            new Date(plan.measureDate.seconds * 1000),
                                            "MM/dd/yyyy HH:mm:ss"
                                          )
                                        : "N/A"}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!previewMode ? (
            <>
              <Button onClick={() => setModalOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={generateMeasuredObjects}
                color="primary"
                disabled={
                  !selectedColumns.name ||
                  !selectedColumns.radiationLevel ||
                  !selectedColumns.code ||
                  !selectedCustomer
                }
              >
                Generate Objects
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setPreviewMode(false)} color="secondary">
                Back
              </Button>
              <Button onClick={saveObjects} color="primary">
                Save Objects
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImportObjectList;
