import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { resetPassword } from "../../../../firebase";

function Cover(): JSX.Element {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode"); // Get the oobCode from URL
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (newPassword !== confirmPassword) {
      setError("Lösenorden matchar inte.");
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(
        "Lösenordet måste innehålla minst åtta tecken, en stor bokstav, en liten bokstav, ett nummer och ett specialtecken"
      );
      return;
    }

    if (!oobCode) {
      setError("Återställningskoden är ogiltig eller saknas.");
      return;
    }

    try {
      await resetPassword(oobCode, newPassword);
      alert("Ditt lösenord har återställts. Nu kan du logga in med dina uppgifter.");
      navigate("/authentication/sign-in");
    } catch (error) {
      console.error("Error resetting password:", error);
      setError("Misslyckades med att återställa lösenordet.");
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Återställ lösenord
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Nytt lösenord"
                variant="standard"
                fullWidth
                value={newPassword}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setNewPassword(e.target.value)
                }
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Bekräfta nytt lösenord"
                variant="standard"
                fullWidth
                value={confirmPassword}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setConfirmPassword(e.target.value)
                }
              />
            </MDBox>
            {error && (
              <MDBox my={2} style={{ color: "red", textAlign: "center" }}>
                {error}
              </MDBox>
            )}
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Återställ lösenord
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
