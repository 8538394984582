import { Timestamp } from "firebase/firestore";
import { ActionPlanModel } from "../dataDuplicationModels/ActionPlanModel";
import { Shift } from "./Shift";
import { BaseShiftModel } from "./baseShiftModel";

export class PlannedShift extends BaseShiftModel {
  plannedStartTime: Date;
  plannedEndTime: Date;
  estimatedWorkMinutes: number;

  constructor(shift: Shift) {
    super(shift);
    this.plannedStartTime = shift.plannedStartTime.toDate();
    this.plannedEndTime = shift.plannedEndTime.toDate();
    this.estimatedWorkMinutes = shift.estimatedWorkMinutes;
  }
}
