import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Timestamp } from "firebase/firestore";
import MDButton from "components/MDButton";
import { batchSaveInvites } from "db/repositories/invitesRepository"; // Save function for invites
import { convertModelToDbModel } from "db/models/invites/inviteMapper";
import { InviteValidationSchema } from "helpers/validationSchemes/InviteValidationScheme";
import { useExistingUserData } from "./hooks/ExistingUserData";

interface UserInvite {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  personalSecurityNumber: string;
  role: string;
  employedFrom: Timestamp;
  employedTo: Timestamp;
  code: string;
  startRadiationDoseBqh: string;
}

interface ImportUserListProps {
  customerId: string;
  customerName: string;
}

const ImportUserList: React.FC<ImportUserListProps> = ({ customerId, customerName }) => {
  const { existingEmailAdresses, existingPersonalSecurityNumbers } =
    useExistingUserData(customerId);

  const [excelData, setExcelData] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [previewData, setPreviewData] = useState<UserInvite[] | null>(null);
  const [selectedColumns, setSelectedColumns] = useState({
    firstName: "",
    lastName: "",
    email: "",
    personalSecurityNumber: "",
    role: "",
    startRadiationDoseBqh: "",
  });

  const columnLabels: Record<keyof typeof selectedColumns, string> = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    personalSecurityNumber: "Personal Security Number",
    role: "Role",
    startRadiationDoseBqh: "Start Radiation Dose (Bqh)",
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(jsonData);
      setModalOpen(true);
    };
    reader.readAsBinaryString(file);
  };

  const handleColumnSelection = (field: keyof typeof selectedColumns, value: string) => {
    setSelectedColumns((prev) => ({ ...prev, [field]: value }));
  };

  const generateUserInvites = () => {
    const invites: UserInvite[] = [];
    const validationSchema = new InviteValidationSchema(
      existingEmailAdresses,
      existingPersonalSecurityNumbers
    ).getSchema();

    for (let index = 0; index < excelData.length; index++) {
      const row = excelData[index];

      // Parse the radiation dose or default to 0 if not set
      const startRadiationDoseBqh = parseFloat(row[selectedColumns.startRadiationDoseBqh]) || 0;

      // Prepare the invite object
      const invite = {
        id: uuidv4(),
        firstName: row[selectedColumns.firstName] || "",
        lastName: row[selectedColumns.lastName] || "",
        email: row[selectedColumns.email] || "",
        personalSecurityNumber: row[selectedColumns.personalSecurityNumber] || "",
        role: row[selectedColumns.role] || "N/A",
        startRadiationDoseBqh: startRadiationDoseBqh.toString(),
        employedFrom: Timestamp.now(),
        employedTo: Timestamp.fromDate(
          new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())
        ),
        code: Math.random().toString().slice(2, 11),
      };

      // Validate the invite object
      try {
        validationSchema.validateSync(invite, { abortEarly: false });
      } catch (validationError: any) {
        // Display validation errors for the current row
        const errors = validationError.inner.map((err: any) => err.message).join(", ");
        alert(`Row ${index + 1}: ${errors}`);
        return; // Stop processing further rows
      }

      // Add the valid invite to the array
      invites.push(invite);
    }

    setPreviewData(invites);
    setPreviewMode(true);
  };

  const saveUserInvites = async () => {
    if (!previewData || previewData.length === 0) {
      console.error("No invites to save.");
      return;
    }

    const confirmation = window.confirm(
      `Are you sure you want to create ${previewData.length} invites for ${
        customerName || "No Customer Selected"
      }?`
    );

    if (!confirmation) return;

    try {
      const inviteDbModels = previewData.map((invite) => {
        const employedFromDate =
          invite.employedFrom instanceof Timestamp
            ? invite.employedFrom.toDate()
            : invite.employedFrom;
        const employedToDate =
          invite.employedTo instanceof Timestamp ? invite.employedTo.toDate() : invite.employedTo;

        return convertModelToDbModel({
          ...invite,
          customerId: customerId,
          employedFrom: employedFromDate,
          employedTo: employedToDate,
          avaliableTo: new Date(
            new Date().getFullYear() + 1,
            new Date().getMonth(),
            new Date().getDate()
          ),
          acceptedAt: null,
          declinedAt: null,
          code: invite.code,
          startRadiationDoseBqh: parseFloat(invite.startRadiationDoseBqh) || 0,
        });
      });

      // Call batchSaveInvites with the transformed data
      await batchSaveInvites(inviteDbModels);
      console.log("User invites saved successfully.");
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving user invites:", error);
    }
  };

  return (
    <Box>
      <MDButton variant="gradient" color="dark" component="label">
        Upload Excel or Numbers Document
        <input type="file" accept=".xlsx, .xls, .numbers" hidden onChange={handleFileUpload} />
      </MDButton>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>
          {previewMode
            ? `Preview Generated User Invites for: ${customerName || "No Customer Selected"}`
            : "Map Columns and Preview Data"}
        </DialogTitle>
        <DialogContent>
          {!previewMode ? (
            <>
              <Typography variant="body1" gutterBottom>
                Import users for: {customerName}
              </Typography>
              <br />
              <Grid container spacing={2} mb={2}>
                {Object.keys(selectedColumns).map((key) => (
                  <Grid item xs={6} sm={4} key={key}>
                    <Select
                      fullWidth
                      value={selectedColumns[key as keyof typeof selectedColumns]}
                      onChange={(e) =>
                        handleColumnSelection(key as keyof typeof selectedColumns, e.target.value)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        {columnLabels[key as keyof typeof selectedColumns]} (Required)
                      </MenuItem>
                      {excelData.length > 0 &&
                        Object.keys(excelData[0]).map((column) => (
                          <MenuItem value={column} key={column}>
                            {column}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                ))}
              </Grid>
              <table
                style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    {excelData.length > 0 &&
                      Object.keys(excelData[0]).map((column) => (
                        <th key={column} style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {column}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.keys(row).map((key) => (
                        <td key={key} style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table
                style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>First Name</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Last Name</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Email</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Personal Security Number
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Role</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Start Radiation Dose (Bqh)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {previewData!.map((user, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.firstName}</td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.lastName}</td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.email}</td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {user.personalSecurityNumber}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.role}</td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {user.startRadiationDoseBqh}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Box mt={4}>
                <Typography variant="h6">
                  Generated JSON (radiation dose will be converter to number)
                </Typography>
                <pre
                  style={{
                    backgroundColor: "#f4f4f4",
                    padding: "1em",
                    borderRadius: "5px",
                    overflowX: "auto",
                  }}
                >
                  {JSON.stringify(previewData, null, 2)}
                </pre>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!previewMode ? (
            <>
              <Button onClick={() => setModalOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={generateUserInvites}
                color="primary"
                disabled={
                  !selectedColumns.firstName ||
                  !selectedColumns.lastName ||
                  !selectedColumns.email ||
                  !selectedColumns.personalSecurityNumber ||
                  !selectedColumns.role ||
                  !customerId
                }
              >
                Preview User Invites
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setPreviewMode(false)} color="secondary">
                Back
              </Button>
              <Button onClick={saveUserInvites} color="primary">
                Save User Invites
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImportUserList;
