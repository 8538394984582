import { Project } from "db/models/Project";
import { UserAdminModel } from "db/models/users/UserAdminModel";

const getTableData = (admins: UserAdminModel[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Förnamn", accessor: "firstName" },
      { Header: "Efternamn", accessor: "surName" },
      { Header: "E-post", accessor: "email" },
    ],
    rows: admins.map((admni) => ({
      id: admni.id,
      firstName: admni.firstName,
      surName: admni.lastName,
      email: admni.email,
    })),
  };
  return dataTableData;
};

export default getTableData;
