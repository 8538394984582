// UsersContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { collection, query, where, orderBy, onSnapshot, doc } from "firebase/firestore";
import CustomerContext from "./CustomerContext";
import { UserDbModel } from "db/models/users/user";

import { FirestoreDb } from "../firebase";
import { UserModel } from "db/models/users/UserModel";

const db = FirestoreDb();

interface UsersContextType {
  users: UserModel[];
}

const UsersContext = createContext<UsersContextType>({
  users: [],
});

export const UsersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const { currentCustomer } = useContext(CustomerContext);

  function mapToUserModel(user: UserDbModel): UserModel {
    return new UserModel(user);
  }

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentCustomer?.id) {
      const q = query(
        collection(db, "users"),
        where("customerId", "==", currentCustomer.id),
        orderBy("firstName", "asc")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedUsers: UserDbModel[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as UserDbModel[];
        setUsers(fetchedUsers.map((dbUser) => mapToUserModel(dbUser)));
      });
    } else {
      setUsers([]); // Reset users if there is no selected customer
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [currentCustomer]);

  return <UsersContext.Provider value={{ users }}>{children}</UsersContext.Provider>;
};

export default UsersContext;
