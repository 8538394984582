// AdminsContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import { FirestoreDb } from "../firebase";
import { collection, query, where, orderBy, onSnapshot, doc } from "firebase/firestore";
import { UserAdminModel } from "db/models/users/UserAdminModel";
import CustomerContext from "./CustomerContext";

const db = FirestoreDb();

interface AdminsContextType {
  admins: UserAdminModel[];
}

const AdminsContext = createContext<AdminsContextType>({
  admins: [],
});

export const AdminsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [admins, setAdmins] = useState<UserAdminModel[]>([]);
  const { currentCustomer } = useContext(CustomerContext);

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentCustomer) {
      const q = query(
        collection(db, "admins"),
        where("customerId", "==", currentCustomer.id),
        orderBy("firstName", "asc")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedAdmins: UserAdminModel[] = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as UserAdminModel)
        );
        setAdmins(fetchedAdmins);
      });
    } else {
      setAdmins([]);
    }

    return () => unsubscribe();
  }, [currentCustomer]);

  return <AdminsContext.Provider value={{ admins }}>{children}</AdminsContext.Provider>;
};

export default AdminsContext;
