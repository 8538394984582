import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Define the types for the props
interface SetDoseDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (doseData: { date: string; radiationDose: number }) => void;
}

// Define validation schema using Yup
const validationSchema = Yup.object({
  date: Yup.string().required("Datum är obligatoriskt"),
  radiationDose: Yup.number()
    .required("Strålningsdos är obligatoriskt")
    .min(0, "Strålningsdos kan inte vara negativ"),
});

// Define initial form values
const initialValues = {
  date: "",
  radiationDose: "",
};

const SetDoseDialog: React.FC<SetDoseDialogProps> = ({ open, onClose, onSave }) => {
  const handleSave = (values: typeof initialValues) => {
    onSave({ date: values.date, radiationDose: Number(values.radiationDose) });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Ange manuellt dosvärde</DialogTitle>
      <Typography variant="body2" color="textSecondary" sx={{ px: 3, pb: 2 }}>
        Obs! Om befintliga arbetspass finns sedan tidigare kommer denna dosen att läggas till
        utöver. Dvs det kommer att skapas ett dummy arbetspass som innehåller ett värde, detta
        värdet kommer sedan att räknas som ett vanligt arbetspass när total dos räknas ut.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
          <Form>
            <DialogContent>
              {/* Date Input */}
              <TextField
                margin="dense"
                label="Datum"
                type="date"
                name="date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={touched.date && Boolean(errors.date)}
                helperText={touched.date && errors.date}
              />
              {/* Radiation Dose Input */}
              <TextField
                margin="dense"
                label="Strålningsdos (Bq)"
                type="number"
                name="radiationDose"
                value={values.radiationDose}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.radiationDose && Boolean(errors.radiationDose)}
                helperText={touched.radiationDose && errors.radiationDose}
              />
            </DialogContent>
            <DialogActions>
              {/* Cancel Button */}
              <Button onClick={onClose} color="secondary" sx={{ textTransform: "none" }}>
                Avbryt
              </Button>
              {/* Save Button */}
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ textTransform: "none", color: "white" }}
                style={{ color: "white" }}
                disabled={!isValid}
              >
                Sätt dos
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SetDoseDialog;
