import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import IndexLayout from "layouts/baseComponents/indexLayout";
import EmptyIndexLayout from "layouts/baseComponents/EmptyIndexLayout";
import getTableData from "./data/dataTableData";
import ProjectsContext from "context/ProjectsContext";
import ShiftsContext from "context/ShiftsContext";
import { Project, ProjectWithShiftsCount } from "db/models/Project";
import { CalculatableShiftModel } from "db/models/shiftsModels/CalculatableShiftModel";

function Projects(): JSX.Element {
  const { projects } = useContext(ProjectsContext);
  const { allShifts } = useContext(ShiftsContext);
  const navigate = useNavigate();

  function aggregateProjectsWithShiftsCount(
    projects: Project[],
    shifts: CalculatableShiftModel[]
  ): ProjectWithShiftsCount[] {
    return projects.map((project) => {
      const shiftsCount = shifts.filter((shift) => shift.project?.id === project.id).length;
      return new ProjectWithShiftsCount(project.id, project.code, project.name, shiftsCount);
    });
  }

  const createNewProject = () => {
    navigate("/projects/create-project"); // Update the route for creating a new project
  };

  const handleRowClick = (projectId: string) => {
    navigate(`/projects/edit-project/${projectId}`); // Update the route for editing a project
  };

  if (!projects || projects.length === 0) {
    return <EmptyIndexLayout pageTitle="Projekt" createNewItemClick={createNewProject} />;
  }

  return (
    <IndexLayout
      title="Projekt"
      createLink="/projects/create-project" // Update the route for creating a new project
      onNewItemClick={createNewProject}
      addNewText="projekt"
    >
      <DataTableCard
        data={getTableData(aggregateProjectsWithShiftsCount(projects, allShifts))}
        onRowClick={handleRowClick}
        showHoverStyle={true}
      />
    </IndexLayout>
  );
}

export default Projects;
