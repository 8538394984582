import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react"; // Use QRCodeSVG for generating SVG-based QR code
import { WorkObject } from "db/models/workObject";
import { Project } from "db/models/Project";
import { saveAs } from "file-saver"; // Import file-saver for SVG download
import { Timestamp } from "firebase/firestore";
import ProjectsContext from "context/ProjectsContext"; // Import ProjectsContext
import ActionPlanDropDown from "layouts/workshifts/components/actionPlanDropDown"; // Import ActionPlanDropDown
import ProjectDropDown from "layouts/workshifts/components/projectDropDown"; // Import ProjectDropDown
import { ActionPlan } from "db/models/ActionPlan";

interface ObjectDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  workObject?: WorkObject;
}

// Helper function to format Timestamp to a readable date string
const formatTimestamp = (timestamp: Timestamp | undefined): string => {
  return timestamp ? timestamp.toDate().toLocaleDateString() : "N/A";
};

// Helper function to format radiation level value to cubic meters (m³)
const formatRadiationLevel = (level: number | undefined): string => {
  return level ? level.toLocaleString("sv-SE") : "N/A";
};

const ObjectDetailsDialog: React.FC<ObjectDetailsDialogProps> = ({ open, onClose, workObject }) => {
  const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlan | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [showProjectSelectionDialog, setShowProjectSelectionDialog] = useState<boolean>(true);

  // Update the selectedActionPlan state when workObject changes
  useEffect(() => {
    if (workObject?.actionPlans && workObject.actionPlans.length > 0) {
      setSelectedActionPlan(workObject.actionPlans[0]); // Set the first action plan as default
    }
  }, [workObject]);

  // Generate QR code data based on selected values
  const qrData = JSON.stringify({
    object: workObject?.id || "",
    action: selectedActionPlan?.id || "",
    project: selectedProject?.id || "",
    projectSelection: showProjectSelectionDialog,
  });

  // Function to handle QR code download as SVG
  const handleDownloadQR = () => {
    const svgElement = document.getElementById("qr-code-svg") as unknown as SVGSVGElement;
    if (!svgElement) return;

    // Get the SVG content as a string
    const svgContent = new XMLSerializer().serializeToString(svgElement);

    // Create a blob from the SVG content
    const blob = new Blob([svgContent], { type: "image/svg+xml;charset=utf-8" });

    // Use file-saver to download the SVG as a file
    saveAs(blob, `qr-code-${workObject?.name || "object"}.svg`);
  };

  // Determine if the "Skriv ut QR-kod" button should be enabled
  const isPrintButtonDisabled = !selectedActionPlan; // Action plan is always required

  // Define the fixed height for the QR code and placeholder to ensure consistent view
  const qrCodeHeight = 400;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {/* Dialog Title */}
      <DialogTitle>
        Skapa QR-kod för: <strong>{workObject?.name ?? "Objekt"}</strong>
      </DialogTitle>

      <DialogContent>
        {/* Rules Explanation */}
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          <strong>Regler för att generera QR-kod:</strong> För att generera en QR-kod måste du
          alltid välja en åtgärdsplan. Projektvalet är valfritt men kan konfigureras så att
          användaren kan ändra projekt vid QR-kodsläsning. Om du tillåter projektändring kan kommer
          användaren få en dialog där man väljer projekt vid scanning av QR-koden.
        </Typography>

        {/* Object Availability Information */}
        {workObject && (
          <Box mb={4}>
            <Typography variant="body2" color="textSecondary">
              <strong>Objektet är tillgängligt:</strong> {formatTimestamp(workObject.avaliableFrom)}{" "}
              - {formatTimestamp(workObject.avaliableTo)}
            </Typography>
          </Box>
        )}

        <Grid container spacing={2} alignItems="center">
          {/* Action Plan Dropdown */}
          <Grid item xs={12} sm={12} sx={{ pb: 2 }}>
            <ActionPlanDropDown setActionPlan={setSelectedActionPlan} workObject={workObject} />
            {!selectedActionPlan && (
              <Typography variant="body2" color="error">
                Vänligen välj åtgärdsplan för att generera QR-kod.
              </Typography>
            )}

            {/* Display Availability and Radiation Information for Selected Action Plan */}
            {selectedActionPlan && (
              <Box mt={0.5}>
                <Typography variant="caption" color="textSecondary">
                  <strong>Senaste mätning: </strong>
                  {selectedActionPlan.measureDate
                    ? `${formatTimestamp(selectedActionPlan.measureDate)}`
                    : "Ingen mätning"}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ display: "block", mt: 0.5 }}
                >
                  <strong>Strålningsnivå: </strong>
                  {formatRadiationLevel(selectedActionPlan.radiationLevel)} Bq/m³
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Project Dropdown */}
          <Grid item xs={12} sm={12} sx={{ pb: 1 }}>
            <ProjectDropDown
              setProject={setSelectedProject}
              selectedProjectId={selectedProject?.id ?? ""}
            />
          </Grid>

          {/* Project Selection Checkbox */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showProjectSelectionDialog}
                  onChange={(e) => setShowProjectSelectionDialog(e.target.checked)}
                  color="primary"
                />
              }
              label="Tillåt att användaren kan ändra projekt vid QR-kodsläsning."
            />
          </Grid>

          {/* QR Code Preview or Placeholder */}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              {/* Container Box with 50% width and fixed height */}
              <Box
                sx={{
                  width: "50%",
                  height: qrCodeHeight,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {!isPrintButtonDisabled ? (
                  /* QR Code SVG with full width inside its container */
                  <QRCodeSVG
                    id="qr-code-svg"
                    value={qrData}
                    style={{ width: "100%", height: "auto" }}
                    includeMargin
                  />
                ) : (
                  /* Placeholder Message */
                  <Typography variant="body2" color="textSecondary">
                    En QR-kod kommer att genereras när du har valt åtgärdsplan och uppfyller alla
                    kriterier för att spara.
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {/* Cancel Button */}
        <Button onClick={onClose} color="secondary" sx={{ textTransform: "none" }}>
          Avbryt
        </Button>
        {/* Print QR Code Button */}
        <Button
          onClick={handleDownloadQR}
          color="primary"
          variant="contained"
          sx={{ textTransform: "none", color: "white" }}
          style={{ color: "white" }}
          disabled={isPrintButtonDisabled} // Disable if no action plan is selected
        >
          Skriv ut QR-kod
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectDetailsDialog;
