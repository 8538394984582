import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
import { Project } from "db/models/Project";
import CustomerContext from "./CustomerContext";
import { FirestoreDb } from "../firebase";

const db = FirestoreDb();

interface ProjectsContextType {
  projects: Project[];
}

const ProjectsContext = createContext<ProjectsContextType>({
  projects: [],
});

export const ProjectsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const { currentCustomer } = useContext(CustomerContext);

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentCustomer?.id) {
      const q = query(collection(db, "projects"), where("customerId", "==", currentCustomer.id));

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedProjects: Project[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Project[];
        setProjects(fetchedProjects);
      });
    } else {
      setProjects([]); // Reset projects if there is no selected customer
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [currentCustomer]);

  return <ProjectsContext.Provider value={{ projects }}>{children}</ProjectsContext.Provider>;
};

export default ProjectsContext;
