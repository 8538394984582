import React, { useMemo } from "react";
import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningIcon from "@mui/icons-material/Warning";
import Overview from "./overView";
import WorkShiftOverview from "./workshiftOverview";
import MissingMeasureValue from "./missingMeasureValue";
import { ShiftsOverviewService } from "../models/shiftsOverViewConverter";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";

interface ShiftsOverviewProps {
  title: string;
  subtitle: string;
  doneShifts: DoneShift[];
  plannedShifts: PlannedShift[];
}

const ShiftsOverview: React.FC<ShiftsOverviewProps> = ({
  title,
  subtitle,
  doneShifts,
  plannedShifts,
}) => {
  const doneShiftsWithoutMeasurement = useMemo(
    () => ShiftsOverviewService.filterShiftsWithoutMeasurement(doneShifts),
    [doneShifts]
  );
  const plannedShiftsWithoutMeasurement = useMemo(
    () => ShiftsOverviewService.filterShiftsWithoutMeasurement(plannedShifts),
    [plannedShifts]
  );

  // Error or loading states can be managed here if data fetching is asynchronous
  if (!doneShifts || !plannedShifts) {
    return <CircularProgress />;
  }

  return (
    <Grid item xs={12} sm={8}>
      <Box p={4}>
        <Overview title={title} subtitle={subtitle} />

        <Grid container spacing={3} alignItems="center">
          <WorkShiftOverview
            title="Genomförda arbetspass"
            icon={<CheckCircleOutlineIcon />}
            shiftsCount={doneShifts.length}
            totalTime={ShiftsOverviewService.calculateTotalWorkTime(doneShifts)}
            exposure={ShiftsOverviewService.calculateTotalRadiation(doneShifts)}
          />
          <WorkShiftOverview
            title="Genomförda arbetspass utan mätvärde"
            icon={<WarningIcon />}
            shiftsCount={doneShiftsWithoutMeasurement.length}
            totalTime={ShiftsOverviewService.calculateTotalWorkTime(doneShiftsWithoutMeasurement)}
          />
        </Grid>

        <br></br>

        <Grid container spacing={3}>
          {plannedShifts.length > 0 && (
            <WorkShiftOverview
              title="Planerade arbeten"
              icon={<AccessTimeIcon />}
              shiftsCount={plannedShifts.length}
              totalTime={ShiftsOverviewService.calculateTotalWorkTime(plannedShifts)}
              exposure={ShiftsOverviewService.calculateTotalRadiation(plannedShifts)}
            />
          )}
          {plannedShiftsWithoutMeasurement.length > 0 && (
            <WorkShiftOverview
              title="Planerade arbeten utan mätvärde"
              icon={<WarningIcon />}
              shiftsCount={plannedShiftsWithoutMeasurement.length}
              totalTime={ShiftsOverviewService.calculateTotalWorkTime(
                plannedShiftsWithoutMeasurement
              )}
            />
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default ShiftsOverview;
