import { format, isValid } from "date-fns";
import { OngoingShift } from "db/models/shiftsModels/ongoingShiftModel";
import { getTimeDifferenceInSeconds } from "layouts/workshifts/helpers/dateCalculator";
import { calculateOngoingExposureFromTime } from "layouts/workshifts/helpers/exposureCalcluator";

const getTableData = (shifts: OngoingShift[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Person", accessor: "user" },
      { Header: "Objekt", accessor: "object" },
      { Header: "Projekt", accessor: "project" },
      { Header: "Fr.o.m", accessor: "actualStartTime" },
      { Header: "T.o.m", accessor: "plannedEndTime" },
      { Header: "Tid (min)", accessor: "time", align: "right" },
      { Header: "Strålningsnivå", accessor: "exposure", align: "right" },
      { Header: "Dos (Bqh/m³)", accessor: "dose", align: "right" },
    ],
    rows: shifts.map((shift) => ({
      id: shift.id,
      user: shift.user.name,
      actualStartTime: isValid(shift.actualStartTime)
        ? format(shift.actualStartTime, "yyyy-MM-dd HH:mm:ss")
        : "",
      plannedEndTime: "Pågår...",
      time: getTimeDifferenceInSeconds(shift.actualStartTime, new Date()),
      project: shift.project?.name ?? "",
      object: shift.object?.name ?? "",
      exposure: shift.actionPlan.radiationLevel,
      dose: calculateOngoingExposureFromTime(
        shift.actionPlan.radiationLevel,
        shift.actualStartTime
      ),
    })),
  };
  return dataTableData;
};

export default getTableData;
