import React from "react";
import { Box, Card, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import ShiftsOverview from "./shiftsOverview";
import { createSquareStyle } from "../styles/squareStyle";
import { UserModel } from "db/models/users/UserModel";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import RadiationDoseChart from "layouts/employees/data/radiationDoseChart";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import { getDoneWorkShiftsData } from "layouts/employees/data/dataTableData";
import getTableData from "layouts/workshifts/plannedWorkshifts/data/dataTableData";
import { ShiftsOverviewService } from "../models/shiftsOverViewConverter";
import RadiationBadge from "./radiationBadge";
import { calculateDaysAndHighlight } from "./helpers/shiftCalculations";
import { toColor, toNamedColor } from "helpers/monthlyDose";

interface UserReportProps {
  user: UserModel;
  doneShifts: DoneShift[];
  plannedShifts: PlannedShift[];
  includePlannedWorkshifts: boolean;
  includeDiagram: boolean;
  includeListOfWorkshifts: boolean;
  startDate: Date;
  endDate: Date;
}

const UserReport: React.FC<UserReportProps> = ({
  user,
  doneShifts,
  plannedShifts,
  includePlannedWorkshifts,
  includeDiagram,
  includeListOfWorkshifts,
  startDate,
  endDate,
}) => {
  const title = `${user.firstName} ${user.lastName} ${user.personalSecurityNumber}`;
  const subtitle = user.role;

  const allShifts = includePlannedWorkshifts ? [...doneShifts, ...plannedShifts] : [...doneShifts];
  const { daysUnder70, daysUnder90, daysUnder100, daysOver100, color, label, highestValue } =
    calculateDaysAndHighlight(allShifts);

  const timeDifference = endDate.getTime() - startDate.getTime(); // Corrected subtraction
  const daysInRange = Math.ceil(timeDifference / (1000 * 3600 * 24));
  const maxRadiationInRange = (daysInRange / 365) * 720000;

  const totalRadiation = ShiftsOverviewService.calculateTotalRadiationNumber(doneShifts);
  const totalPlannedRadiation = ShiftsOverviewService.calculateTotalRadiationNumber(plannedShifts);
  const totalPercentage = ((totalRadiation + totalPlannedRadiation) / maxRadiationInRange) * 100;

  const squareStyle = createSquareStyle(toColor(totalPercentage));

  return (
    <Box mt={4} bgcolor="white" p={0}>
      <Card style={{ backgroundColor: "white" }}>
        <Grid container spacing={0} id={`user-report-overview-${user.id}`}>
          <ShiftsOverview
            title={title}
            subtitle={subtitle}
            doneShifts={doneShifts}
            plannedShifts={plannedShifts}
          />

          <Grid item sm={4}>
            <Paper style={{ ...squareStyle, backgroundColor: "white" }} elevation={0}>
              <Box display="flex" alignItems="center" mt={2}>
                <Typography variant="h4">Total dos för perioden</Typography>
                <Chip
                  label={`${totalPercentage.toFixed(0)}%`}
                  color={toNamedColor(totalPercentage)}
                  style={{ marginLeft: "8px" }}
                />
              </Box>
              <br />
              {/* <Typography variant="h5">Högsta dagliga dos</Typography>
              <Typography variant="h3" color="success">
                {highestValue}%
              </Typography>
              <Typography>{startDate.toLocaleDateString()}</Typography>

              <Grid container>
                <RadiationBadge
                  daysUnder70={daysUnder70}
                  daysUnder90={daysUnder90}
                  daysUnder100={daysUnder100}
                  daysOver100={daysOver100}
                  color={color}
                  label={label}
                  highestValue={highestValue}
                />
              </Grid>*/}
            </Paper>
          </Grid>
          {includeDiagram && (
            <Grid item xs={12} px={4} py={4}>
              <RadiationDoseChart
                doneShifts={doneShifts}
                plannedShifts={plannedShifts}
                excludePlanned={!includePlannedWorkshifts}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          )}
        </Grid>
      </Card>
      {includeListOfWorkshifts && (
        <Box id={`user-report-done-shifts-${user.id}`} bgcolor="white" mt={2}>
          <Card style={{ backgroundColor: "white" }}>
            <Grid item xs={12} px={4} py={4} style={{ backgroundColor: "white" }}>
              <Box p={3}>
                <Typography variant="h6">Genomförda arbetspass</Typography>
              </Box>
              <DataTableCard
                data={getDoneWorkShiftsData(doneShifts)}
                onRowClick={() => {}}
                showHoverStyle={false}
              />
              <Divider />
            </Grid>
          </Card>
        </Box>
      )}
      {includeListOfWorkshifts && includePlannedWorkshifts && plannedShifts.length > 0 && (
        <Box id={`user-report-planned-shifts-${user.id}`} bgcolor="white" mt={2}>
          <Card style={{ backgroundColor: "white" }}>
            <Grid item xs={12} px={4} py={4} style={{ backgroundColor: "white" }}>
              <Box p={3}>
                <Typography variant="h6">Planerade arbetspass</Typography>
              </Box>
              <DataTableCard
                data={getTableData(plannedShifts)}
                onRowClick={() => {}}
                showHoverStyle={false}
              />
              <Divider />
            </Grid>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default UserReport;
