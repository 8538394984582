import { Timestamp } from "firebase/firestore";
import { InviteDbModel } from "./inviteDbModel";
import { InviteModel } from "./inviteModel";
import { safeTimestampFromDate2 } from "db/helpers/dateMapperHelpers";

export function convertModelToDbModel(inviteModel: InviteModel): InviteDbModel {
  return {
    id: inviteModel.id,
    firstName: inviteModel.firstName,
    lastName: inviteModel.lastName,
    role: inviteModel.role,
    email: inviteModel.email,
    personalSecurityNumber: inviteModel.personalSecurityNumber,
    employedFrom: safeTimestampFromDate2(inviteModel.employedFrom),
    employedTo: safeTimestampFromDate2(inviteModel.employedTo),
    avaliableTo: safeTimestampFromDate2(inviteModel.avaliableTo),
    acceptedAt: safeTimestampFromDate2(inviteModel.acceptedAt),
    declinedAt: safeTimestampFromDate2(inviteModel.declinedAt),
    code: inviteModel.code,
    customerId: inviteModel.customerId,
    startRadiationDoseBqh: inviteModel.startRadiationDoseBqh,
  };
}
