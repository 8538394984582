import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import { FirestoreDb } from "../firebase";
import { collection, query, where, orderBy, onSnapshot, doc } from "firebase/firestore";
import { AuthContext } from "./auth-context";
import { Customer } from "db/models/Customer";

const db = FirestoreDb();

interface SuperAdminCustomersContextType {
  allCustomers: Customer[];
}

const SuperAdminCustomersContext = createContext<SuperAdminCustomersContextType>({
  allCustomers: [],
});

export const SuperAdminsCustomersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [allCustomers, setCustomers] = useState<Customer[]>([]);
  const { isSuperAdmin } = useContext(AuthContext);

  useEffect(() => {
    let unsubscribe = () => {};

    if (isSuperAdmin) {
      const q = query(collection(db, "customers"), orderBy("name", "asc"));

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedCustomers: Customer[] = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Customer)
        );
        setCustomers(fetchedCustomers);
      });
    } else {
      setCustomers([]);
    }

    return () => unsubscribe();
  }, [isSuperAdmin]);

  return (
    <SuperAdminCustomersContext.Provider value={{ allCustomers }}>
      {children}
    </SuperAdminCustomersContext.Provider>
  );
};

export default SuperAdminCustomersContext;
