import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import { createAdmin } from "db/repositories/adminRepository";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { FirestoreDb } from "../../../../firebase";

interface CreateAdminDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
}

const db = FirestoreDb();

const CreateAdminDialog: React.FC<CreateAdminDialogProps> = ({ open, onClose, customerId }) => {
  const [existingEmailAdresses, setExistingEmailAdresses] = useState<string[]>([]);
  const [addAsAppUser, setAddAsAppUser] = useState<boolean>(false);

  useEffect(() => {
    let unsubscribe = () => {};

    if (customerId) {
      const q = query(collection(db, "admins"), where("customerId", "==", customerId));

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedEmails: string[] = snapshot.docs.map((doc) => doc.data().email);
        setExistingEmailAdresses(fetchedEmails);
      });
    } else {
      setExistingEmailAdresses([]);
    }

    return () => unsubscribe();
  }, [customerId]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Förnamn krävs"),
    lastName: Yup.string().required("Efternamn krävs"),
    email: Yup.string()
      .email("Ogiltig e-postadress")
      .required("E-post är obligatorisk")
      .notOneOf(existingEmailAdresses, "E-postadressen finns redan"),
    password: Yup.string()
      .required("Lösenord krävs")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Lösenordet måste innehålla minst åtta tecken, en stor bokstav, en liten bokstav, ett nummer och ett specialtecken"
      ),
    repeatPassword: Yup.string()
      .required("Bekräfta lösenordet")
      .oneOf([Yup.ref("password"), null], "Lösenorden matchar inte"),
    personalSecurityNumber: Yup.string().when("addAsAppUser", {
      is: true,
      then: (schema) => schema.required("Personnummer krävs"),
      otherwise: (schema) => schema.notRequired(),
    }),
    role: Yup.string().when("addAsAppUser", {
      is: true,
      then: (schema) => schema.required("Roll krävs"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeatPassword: "",
      customerId: customerId,
      personalSecurityNumber: "",
      role: "",
      addAsAppUser: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await createAdmin(values, customerId);
        onClose();
      } catch (error) {
        alert("Error creating admin: " + error);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Admin</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="Förnamn"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Efternamn"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-post"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Lösenord"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="repeatPassword"
                name="repeatPassword"
                label="Bekräfta lösenordet"
                type="password"
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.addAsAppUser}
                    onChange={(e) => {
                      formik.setFieldValue("addAsAppUser", e.target.checked);
                      setAddAsAppUser(e.target.checked);
                    }}
                    name="addAsAppUser"
                    color="primary"
                  />
                }
                label="Add as app user"
              />
            </Grid>
            {addAsAppUser && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="personalSecurityNumber"
                    name="personalSecurityNumber"
                    label="Personnummer"
                    value={formik.values.personalSecurityNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.personalSecurityNumber &&
                      Boolean(formik.errors.personalSecurityNumber)
                    }
                    helperText={
                      formik.touched.personalSecurityNumber && formik.errors.personalSecurityNumber
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="role"
                    name="role"
                    label="Roll"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <DialogActions>
            <MDButton onClick={onClose} color="secondary">
              Cancel
            </MDButton>
            <MDButton type="submit" color="primary">
              Create
            </MDButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAdminDialog;
