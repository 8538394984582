export type Project = {
  id: string;
  code: string;
  name: string;
};

export class ProjectWithShiftsCount {
  id: string;
  code: string;
  name: string;
  shiftsCount: number;

  constructor(id: string, code: string, name: string, shiftsCount: number) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.shiftsCount = shiftsCount;
  }
}
