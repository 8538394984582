import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import IndexLayout from "layouts/baseComponents/indexLayout";
import EmptyIndexLayout from "layouts/baseComponents/EmptyIndexLayout";
import getTableData from "./data/dataTableData";
import ShiftsContext from "context/ShiftsContext";

function DoneShifts(): JSX.Element {
  const { doneShifts } = useContext(ShiftsContext);
  const navigate = useNavigate();

  const createNewDoneShift = () => {
    navigate("/workshifts/doneshifts/create-done-shift");
  };

  const handleRowClick = (shiftId: string) => {
    navigate(`/workshifts/doneshifts/edit-done-shift/${shiftId}`);
  };

  if (!doneShifts || doneShifts.length === 0) {
    return (
      <EmptyIndexLayout pageTitle="Genomförda arbetspass" createNewItemClick={createNewDoneShift} />
    );
  }

  return (
    <IndexLayout
      title="Genomförda arbetspass"
      createLink="/workshifts/doneshifts/create-done-shift"
      onNewItemClick={createNewDoneShift}
      addNewText="genomfört arbetspass"
    >
      <DataTableCard
        data={getTableData(doneShifts)}
        onRowClick={handleRowClick}
        showHoverStyle={true}
      />
    </IndexLayout>
  );
}

export default DoneShifts;
