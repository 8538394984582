/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://mmkdosemonitoring.seproduct/material-dashboard-2-pro-react-ts
* Copyright 2023 MMK Dose Monitoring (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import SignInCover from "layouts/authentication/sign-in";

// Material Dashboard 2 PRO React TS components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

import { User } from "firebase/auth";
import Objects from "layouts/superAdmin/objects";
import Customers from "layouts/superAdmin/customers";
import PlannedShifts from "layouts/workshifts/plannedWorkshifts";
import { UserData } from "components/userHelper";
import Projects from "layouts/projects";
import DoneShifts from "layouts/workshifts/doneWorkshifts";
import Dashboard from "layouts/dashboard";
import Employees from "layouts/employees";
import Invites from "layouts/employees/invites";
import CustomerObjects from "layouts/objects";
import Administrators from "layouts/admins";
import Settings from "layouts/account/settings";

import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MapIcon from "@mui/icons-material/Map";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Reports from "layouts/reports";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function authentiactedRoutes(user: User, isSuperAdmin: boolean, userData: UserData | null) {
  if (isSuperAdmin) {
    // Insert super admin routes after the user-specific routes
    const superAdminRoutes = [
      {
        type: "collapse",
        name: user === null ? "" : user.email,
        key: "user-email",
        icon: <MDAvatar src={user === null ? "" : user.photoURL} alt="Brooklyn Alice" size="sm" />,
        collapse: [
          {
            name: "Settings",
            key: "profile-settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Logout",
            key: "logout",
            route: "/authentication/sign-in",
            component: <SignInCover />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Kunder",
        key: "customers",
        component: <Customers />,
        route: "/superadmin/customers",
        icon: <PeopleIcon fontSize="medium" />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Objekt",
        key: "objects",
        component: <Objects />,
        route: "/superadmin/objects",
        icon: <HomeIcon fontSize="medium" />,
        noCollapse: true,
      },
    ];
    return superAdminRoutes;
  }

  return [
    {
      type: "collapse",
      name: "Översikt",
      key: "dashboard",
      component: <Dashboard />,
      route: "/dashboard",
      icon: <HomeIcon fontSize="medium" />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Personal",
      key: "employees",
      icon: <PeopleIcon fontSize="medium" />,
      collapse: [
        {
          name: "Översikt",
          key: "overview",
          route: "/employees/overview",
          component: <Employees />,
        },
        {
          name: "Inbjudningar",
          key: "invites",
          route: "/employees/invites",
          component: <Invites />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Arbetspass",
      key: "workshifts",
      icon: <AccessTimeIcon fontSize="medium" />,
      collapse: [
        {
          name: "Planerade arbetspass",
          key: "plannedshifts",
          route: "/workshifts/plannedshifts",
          component: <PlannedShifts />,
        },
        {
          name: "Genomförda arbetspass",
          key: "doneshifts",
          route: "/workshifts/doneshifts",
          component: <DoneShifts />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Projekt",
      key: "projects",
      component: <Projects />,
      route: "/projects",
      icon: <AssignmentIcon fontSize="medium" />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Rapporter",
      key: "reports",
      component: <Reports />,
      route: "/reports",
      icon: <AssessmentIcon fontSize="medium" />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Objekt",
      key: "objects",
      component: <CustomerObjects />,
      route: "/objects",
      icon: <MapIcon fontSize="medium" />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Administratörer",
      key: "admins",
      component: <Administrators />,
      route: "/admins",
      icon: <AdminPanelSettingsIcon fontSize="medium" />,
      noCollapse: true,
    },
    { type: "divider", key: "divider-1" },
    {
      type: "collapse",
      name: userData === undefined ? "" : userData.firstName + " " + userData.lastName,
      key: "user-email",
      icon: <MDAvatar src={user === null ? "" : user.photoURL} alt="Brooklyn Alice" size="sm" />,
      collapse: [
        {
          name: "Mitt konto",
          key: "settings",
          route: "/settings",
          component: <Settings />,
        },
        {
          name: "Logga ut",
          key: "logout",
          route: "/authentication/sign-in",
          component: <SignInCover />,
        },
      ],
    },
  ];
}

export default authentiactedRoutes;
