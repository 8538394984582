import React, { useContext, useEffect } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { create, update } from "db/repositories/projectsRepository";
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import MDButton from "components/MDButton";
import { Project } from "db/models/Project";
import ProjectsContext from "context/ProjectsContext";
import CustomerContext from "context/CustomerContext";

interface FormValues extends Project {}

interface CreateEditProjectProps {
  mode: "create" | "edit";
  project?: Project; // For edit mode, provide the project object to edit
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Projektet måste ha en kod"),
  name: Yup.string().required("Projektet måste ha ett namn"),
});

const CreateEditProject: React.FC<CreateEditProjectProps> = ({ mode, project }) => {
  const { projects } = useContext(ProjectsContext);
  const { currentCustomer } = useContext(CustomerContext);
  const navigate = useNavigate();
  const { projectId } = useParams();

  const initialValues: FormValues =
    mode === "edit" && project
      ? { ...project }
      : {
          id: "", // Initialize with empty string or default value
          code: "",
          name: "",
        };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (mode === "create") {
          const createdProject = await create(values, currentCustomer.id);
          console.log("Project created:", createdProject);
        } else if (mode === "edit" && projectId) {
          const updatedProject = await update(projectId, values, currentCustomer.id);
          console.log("Project updated:", updatedProject);
        }
        navigate("/projects");
      } catch (error) {
        alert("Error creating project: " + error);
        // Handle error, show error message, etc.
      }
    },
  });

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        if (mode === "edit" && projectId) {
          const projectData = projects.find((project) => project.id === projectId);
          formik.setValues((prevValues) => ({
            ...prevValues,
            id: projectData.id,
            code: projectData.code,
            name: projectData.name,
          }));
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };
    fetchProjectData();
  }, [mode, projectId, currentCustomer.id]);

  return (
    <BaseCreateEdit
      title={mode === "create" ? "Skapa Projekt" : "Editera Projekt"}
      onSubmit={formik.handleSubmit}
    >
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Namn"
          id="name"
          name="name"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Kod"
          id="code"
          name="code"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.code}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <MDButton variant="gradient" color="dark" type="submit">
            {"Spara"}
          </MDButton>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => {
              navigate("/projects");
            }}
          >
            Avbryt
          </MDButton>
        </Box>
      </Grid>
    </BaseCreateEdit>
  );
};

export default CreateEditProject;
