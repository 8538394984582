import React, { useContext, useEffect } from "react";
import { Grid, Card, Typography, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Customer } from "db/models/Customer";
import { useNavigate, useParams } from "react-router-dom";
import { create, getCustomerById, update } from "db/repositories/customersRepository";
import SuperAdminCustomersContext from "context/SuperAdminCustomersProvider";
import MDTypography from "components/MDTypography";
import CusomerInvites from "./users/cusomerInvites";
import CustomerAdmins from "./admins/customerAdmins";

interface FormValues extends Customer {}

interface CreateCustomerProps {
  mode: "create" | "edit";
  customer?: Customer; // For edit mode, provide the customer object to edit
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  numberOfUsers: Yup.number().required("Required"),
  // Add validation for other fields if needed
});

const CreateCustomer: React.FC<CreateCustomerProps> = ({ mode, customer }) => {
  const navigate = useNavigate();
  const { allCustomers } = useContext(SuperAdminCustomersContext);

  const initialValues: FormValues =
    mode === "edit" && customer
      ? { ...customer }
      : {
          id: "", // Initialize with empty string or default value
          name: "",
          numberOfUsers: 0,
          admins: [],
          appUsers: [],
        };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      try {
        // Remove id from the values object
        const { id, ...customerData } = values;

        let toSave = customerData as Customer;

        if (mode === "create") {
          const createdCustomer = await create(toSave);
          console.log("Customer created:", toSave);
        } else if (mode === "edit" && toSave) {
          // Cast customerData to the Customer type
          const updatedCustomer = await update(id, toSave);
          console.log("Customer updated:", updatedCustomer);
        }
        navigate("/superadmin/customers");
        // Optionally, you can redirect or show a success message here
      } catch (error) {
        console.error("Error:", error);
        // Handle error, show error message, etc.
      }
    },
  });

  const { customerId } = useParams();
  const [customerName, setCustomerName] = React.useState<string>("");

  useEffect(() => {
    if (mode === "edit" && customerId) {
      // Find customer data in allCustomers array
      const customerData = allCustomers.find((customer) => customer.id === customerId);
      if (customerData) {
        // Update formik values with found customerData
        formik.setValues((prevValues) => ({
          ...prevValues,
          ...customerData,
        }));
        setCustomerName(customerData.name);
      } else {
        console.error("Customer not found");
      }
    }
  }, [mode, customerId, allCustomers]);

  return (
    <DashboardLayout>
      <Box p={4}>
        <Card>
          <Box p={3}>
            <Typography variant="h5">{mode === "create" ? "Create" : "Edit"} Customer</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box p={3}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Customer Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Maximum number of users"
                    name="numberOfUsers"
                    type="number"
                    value={formik.values.numberOfUsers}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.numberOfUsers && Boolean(formik.errors.numberOfUsers)}
                    helperText={formik.touched.numberOfUsers && formik.errors.numberOfUsers}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="gradient" color="dark" type="submit">
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Card>
      </Box>
      {/* Render cusomerInvites component here */}
      <Box p={4}>
        <Card>
          {customerId && customerName && (
            <CusomerInvites customerId={customerId} customerName={customerName} />
          )}
        </Card>
      </Box>
      <Box p={4}>
        <Card>{customerId && <CustomerAdmins customerId={customerId} />}</Card>
      </Box>
    </DashboardLayout>
  );
};

export default CreateCustomer;
