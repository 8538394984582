import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { ActionPlan } from "db/models/ActionPlan";
import FormField from "layouts/authentication/account/components/FormField";
import { Dispatch, SetStateAction } from "react";
import { WorkObject } from "db/models/workObject";

interface ActionPlanDropDownProps {
  setActionPlan: Dispatch<SetStateAction<ActionPlan | null>>;
  selectedActionPlanId?: string | null; // Optional selected action plan ID
  workObject: WorkObject | null;
}

function ActionPlanDropDown({
  setActionPlan,
  selectedActionPlanId,
  workObject,
}: ActionPlanDropDownProps): JSX.Element {
  const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlan | null>(null);
  const [actionPlans, setActionPlans] = useState<ActionPlan[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  var preSelectedActionPlanId = "";

  useEffect(() => {
    if (workObject && workObject.actionPlans && workObject.actionPlans.length > 0) {
      setActionPlans(workObject.actionPlans);
      setDisabled(false);
    } else {
      setActionPlans([]);
      setDisabled(true);
    }
  }, [workObject]);

  useEffect(() => {
    // Match selected action plan with provided ID in edit mode
    if (selectedActionPlanId && actionPlans.length > 0) {
      const foundActionPlan = actionPlans.find((plan) => plan.id === selectedActionPlanId);
      setSelectedActionPlan(foundActionPlan || null);
      setActionPlan(foundActionPlan);
    }
  }, [selectedActionPlanId, actionPlans]);

  useEffect(() => {
    setActionPlan(selectedActionPlan);
  }, [selectedActionPlan, setActionPlan]);

  return (
    <Autocomplete
      id="action-plan-drop-down"
      value={selectedActionPlan}
      options={actionPlans}
      onChange={(event, newValue) => {
        setSelectedActionPlan(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <FormField
          {...params}
          label="Välj åtgärdsplan"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          placeholder={"Vänligen välj åtgärdsplan"}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} data-testid={`action-plan-option-${option.id}`}>
          {option.name}
        </li>
      )}
    />
  );
}

export default ActionPlanDropDown;
