import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import { UserModel } from "db/models/users/UserModel";
import UserReport from "./userReport";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import MDButton from "components/MDButton";

interface UsersReportsProps {
  filteredDoneShifts: DoneShift[];
  filteredPlannedShifts: PlannedShift[];
  selectedUsers: UserModel[];
  includePlannedWorkshifts: boolean;
  includeDiagram: boolean;
  includeListOfWorkshifts: boolean;
  startDate: Date;
  endDate: Date;
}

const UsersReports: React.FC<UsersReportsProps> = ({
  filteredDoneShifts,
  filteredPlannedShifts,
  selectedUsers,
  includePlannedWorkshifts,
  includeDiagram,
  includeListOfWorkshifts,
  startDate,
  endDate,
}) => {
  const generatePdf = async () => {
    const padding = 5;

    const renderSection = async (pdf: jsPDF, elementId: string) => {
      const input = document.getElementById(elementId);
      if (input) {
        const canvas = await html2canvas(input, { backgroundColor: "#ffffff" });
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        let heightLeft = pdfHeight;
        let position = padding;

        pdf.addImage(imgData, "PNG", padding, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight() - 2 * padding;

        while (heightLeft >= 0) {
          position = heightLeft - pdfHeight + 2 * padding;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", padding, position, pdfWidth, pdfHeight);
          heightLeft -= pdf.internal.pageSize.getHeight() - 2 * padding;
        }
      }
    };

    for (const user of selectedUsers) {
      const pdf = new jsPDF("p", "mm", "a4");

      await renderSection(pdf, `user-report-overview-${user.id}`);
      if (includeListOfWorkshifts) {
        pdf.addPage();
        await renderSection(pdf, `user-report-done-shifts-${user.id}`);
        if (includePlannedWorkshifts) {
          pdf.addPage();
          await renderSection(pdf, `user-report-planned-shifts-${user.id}`);
        }
      }

      const startDateStr = startDate.toISOString().split("T")[0];
      const endDateStr = endDate.toISOString().split("T")[0];
      const filename = `${user.firstName}_${user.lastName}_${startDateStr}_to_${endDateStr}.pdf`;
      pdf.save(filename);
    }
  };

  return (
    <Box bgcolor="white">
      {selectedUsers.length > 0 && (
        <Typography marginTop={8} variant="h4" gutterBottom>
          Personal
        </Typography>
      )}
      <MDButton color="secondary" onClick={generatePdf}>
        Skapa PDF-rapporter
      </MDButton>
      {selectedUsers.map((user) => (
        <Box key={user.id} bgcolor="white">
          <UserReport
            user={user}
            doneShifts={filteredDoneShifts.filter((shift) => shift.user.id === user.id)}
            plannedShifts={filteredPlannedShifts.filter((shift) => shift.user.id === user.id)}
            includePlannedWorkshifts={includePlannedWorkshifts}
            includeDiagram={includeDiagram}
            includeListOfWorkshifts={includeListOfWorkshifts}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
      ))}
    </Box>
  );
};

export default UsersReports;
