import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import { AuthContext } from "context/auth-context";
import MDButton from "components/MDButton";
import { CreateUserAdminModel } from "db/models/users/CreateUserAdminModel";
import { createAdmin } from "db/repositories/adminRepository";
import AdminsContext from "context/AdminsContext";

interface FormValues extends CreateUserAdminModel {}

const CreateAdmin: React.FC = () => {
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const { projectId } = useParams(); // Get projectId from the route params
  const [existingEmailAdresses, setExistingEmailAdresses] = useState<string[]>([]);
  const { admins } = useContext(AdminsContext);

  const getValidationSchema = () => {
    return Yup.object({
      firstName: Yup.string().required("Förnamn krävs"),
      lastName: Yup.string().required("Efternamn krävs"),
      email: Yup.string()
        .email("Ogiltig e-postadress")
        .required("E-post är obligatorisk")
        .notOneOf(existingEmailAdresses, "E-postadressen finns redan"),
      password: Yup.string()
        .required("Lösenord krävs")
        // Example regex for a strong password: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Lösenordet måste innehålla minst åtta tecken, en stor bokstav, en liten bokstav, ett nummer och ett specialtecken"
        ),
      repeatPassword: Yup.string()
        .required("Bekräfta lösenordet")
        // Use oneOf to reference another field, ensuring match
        .oneOf([Yup.ref("password"), null], "Lösenorden matchar inte"),
    });
  };

  const validationSchema = getValidationSchema();

  const initialValues: FormValues = {
    id: "", // Initialize with empty string or default value
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
    customerId: "",
  };

  useEffect(() => {
    const existingUserEmailAdresses = admins.map((usr) => {
      return usr.email;
    });

    setExistingEmailAdresses(existingUserEmailAdresses);
  }, [userData.customerId]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const createdProject = await createAdmin(values, userData.customerId);
        console.log("Admin created:", createdProject);

        navigate("/admins");
      } catch (error) {
        alert("Error creating admin: " + error);
      }
    },
  });

  return (
    <BaseCreateEdit title={"Lägg till administratör"} onSubmit={formik.handleSubmit}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Förnamn"
          id="surname"
          name="firstName"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Efternamn"
          id="lastName"
          name="lastName"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      {/* Email Field */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="E-post"
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>

      {/* Password Field */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Lösenord"
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Grid>

      {/* Repeat Password Field */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Bekräfta lösenord"
          id="repeatPassword"
          name="repeatPassword"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.repeatPassword}
          error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
          helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <MDButton variant="gradient" color="dark" type="submit">
            {"Spara"}
          </MDButton>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => {
              navigate("/admins");
            }}
          >
            Avbryt
          </MDButton>
        </Box>
      </Grid>
    </BaseCreateEdit>
  );
};

export default CreateAdmin;
