import { FirestoreDb } from "../../firebase";

import { setDoc, doc, getDocs, collection, addDoc, deleteDoc, getDoc } from "firebase/firestore";
import { Customer } from "db/models/Customer";

// import firestore
const db = FirestoreDb();

// collection name
const COLLECTION_NAME = "customers";

// retrieve all measured customer
export const all = async (): Promise<Array<Customer>> => {
  const snapshot = await getDocs(collection(db, COLLECTION_NAME));
  const data: Array<any> = [];

  snapshot.docs.map((_data) => {
    data.push({
      id: _data.id, // because id field in separate function in firestore
      ..._data.data(), // the remaining fields
    });

    return data;
  });

  // return and convert back it array of todo
  return data as Array<Customer>;
};

// create a customer
export const create = async (customer: Customer): Promise<string> => {
  const docRef = await addDoc(collection(db, COLLECTION_NAME), customer);
  return docRef.id;
};

// update object
export const update = async (id: string, updatedFields: Partial<Customer>): Promise<Customer> => {
  const customerRef = doc(db, COLLECTION_NAME, id);

  // Get the existing customer data
  const customerDoc = await getDoc(customerRef);

  if (!customerDoc.exists()) {
    throw new Error("Customer not found");
  }

  // Merge the updated fields with the existing customer data
  const updatedCustomerData = {
    ...customerDoc.data(),
    ...updatedFields,
  };

  try {
    // Update the customer document with the merged data
    await setDoc(customerRef, updatedCustomerData);

    // Return the updated customer data
    return {
      ...updatedCustomerData,
      id: id,
    } as Customer;
  } catch (error) {
    console.error("Error updating customer:", error);
    throw new Error("Failed to update customer");
  }
};

// delete the customer
export const remove = async (id: string) => {
  await deleteDoc(doc(db, COLLECTION_NAME, id));
};

// Get a customer by ID
export const getCustomerById = async (customerId: string): Promise<Customer | null> => {
  try {
    const customerDoc = await getDoc(doc(db, COLLECTION_NAME, customerId));
    if (customerDoc.exists()) {
      const customerData = customerDoc.data();
      return {
        id: customerId,
        name: customerData.name || "", // Provide default values if necessary
        numberOfUsers: customerData.numberOfUsers || 0,
        admins: customerData.admins || [],
        appUsers: customerData.appUsers || [],
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching customer by ID:", error);
    throw error;
  }
};
