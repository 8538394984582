import { ProjectWithShiftsCount } from "db/models/Project";

const getTableData = (projects: ProjectWithShiftsCount[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Namn", accessor: "name" },
      { Header: "Kod", accessor: "code", align: "right" },
      { Header: "Arbetspass", accessor: "workshifts", align: "right" },
    ],
    rows: projects.map((project) => ({
      id: project.id,
      name: project.name,
      code: project.code,
      workshifts: project.shiftsCount,
    })),
  };
  return dataTableData;
};

export default getTableData;
