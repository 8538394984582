import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import IndexLayout from "layouts/baseComponents/indexLayout";
import EmptyIndexLayout from "layouts/baseComponents/EmptyIndexLayout";
import getTableData from "./data/dataTableData";
import AdminsContext from "context/AdminsContext";
import CustomerContext from "context/CustomerContext";
import AdminDialog from "./components/AdminDialog";
import { createUser } from "db/repositories/usersRepository";
import { AdminToUserData } from "./AdminToUserData";
import { Snackbar, Alert } from "@mui/material"; // Import Snackbar and Alert components
import { Timestamp } from "firebase/firestore";

function Administrators(): JSX.Element {
  const { admins } = useContext(AdminsContext);
  const { currentCustomer } = useContext(CustomerContext);
  const navigate = useNavigate();
  const [selectedAdminId, setSelectedAdminId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Snackbar state management
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  // Open dialog when row is clicked
  const handleRowClick = (adminId: string) => {
    setSelectedAdminId(adminId);
    setDialogOpen(true);
  };

  // Close dialog and reset state
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedAdminId(null);
  };

  // Close snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Create a new admin
  const createNewAdmin = () => {
    navigate("/admins/create-admin");
  };

  // Handle dialog save action
  const handleDialogDone = async (adminId: string, adminToUserData: AdminToUserData | null) => {
    if (!adminToUserData) {
      return; // Exit early if adminToUserData is null (meaning no conversion needed)
    }

    const employedFromTimestamp = Timestamp.fromDate(adminToUserData.employedFrom);
    const employedToTimestamp = adminToUserData.employedTo
      ? Timestamp.fromDate(adminToUserData.employedTo)
      : null;

    // Create the new user data to be saved in the "users" collection
    const newUser = {
      id: adminId,
      firstName: admins.find((admin) => admin.id === adminId)?.firstName || "",
      lastName: admins.find((admin) => admin.id === adminId)?.lastName || "",
      email: admins.find((admin) => admin.id === adminId)?.email || "",
      role: "Admin",
      personalSecurityNumber: adminToUserData.personalSecurityNumber,
      employedFrom: employedFromTimestamp,
      employedTo: employedToTimestamp,
      customer: {},
      customerId: currentCustomer.id,
      pushInterval: 0,
    };

    try {
      // Call the createUser function to add the new user to Firestore
      const createdUser = await createUser(newUser);

      // Show success message in Snackbar
      setSnackbarMessage(
        `${createdUser.firstName} ${createdUser.lastName} blev tillagd som appanvändare!`
      );
      setSnackbarSeverity("success");
    } catch (error) {
      // Show error message in Snackbar
      setSnackbarMessage("Ett fel uppstod när användaren skulle skapas.");
      setSnackbarSeverity("error");
      console.error("Error creating user:", error);
    } finally {
      // Show the Snackbar
      setSnackbarOpen(true);

      // Close the dialog after saving
      handleDialogClose();
    }
  };

  // Display empty layout if no admins are available
  if (!admins || admins.length === 0) {
    return <EmptyIndexLayout pageTitle="Administratörer" createNewItemClick={createNewAdmin} />;
  }

  return (
    <>
      <IndexLayout
        title="Administratörer"
        createLink="/admins/create-admin"
        onNewItemClick={createNewAdmin}
        addNewText="administratör"
      >
        <DataTableCard
          data={getTableData(admins)}
          onRowClick={handleRowClick}
          showHoverStyle={true}
        />
      </IndexLayout>

      {/* Render Admin Dialog */}
      {selectedAdminId && (
        <AdminDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onSave={handleDialogDone}
          adminId={selectedAdminId}
          adminData={admins.find((admin) => admin.id === selectedAdminId)!}
        />
      )}

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Administrators;
