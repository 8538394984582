import { Shift } from "./Shift";
import { BaseShiftModel } from "./baseShiftModel";

export class DoneShift extends BaseShiftModel {
  actualStartTime: Date;
  actualEndTime: Date;
  actualWorkMinutes: number;

  constructor(shift: Shift) {
    super(shift);
    this.actualStartTime = shift.actualStartTime.toDate();
    this.actualEndTime = shift.actualEndTime.toDate();
    this.actualWorkMinutes = shift.actualWorkMinutes;
  }
}
