import { UserAdminModel } from "./UserAdminModel";
import { UserDbModel } from "./user";

export class AdminWithAppUserProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  customerId: string;
  isOwner: boolean;
  isAppUser: boolean;
  personalSecurityNumber: string;
  role: string;

  constructor(admin: Partial<UserAdminModel>, user?: Partial<UserDbModel>) {
    this.id = admin.id;
    this.firstName = admin.firstName;
    this.lastName = admin.lastName;
    this.email = admin.email;
    this.customerId = admin.customerId;
    this.isOwner = admin.isOwner;
    this.isAppUser = !!user;
    this.personalSecurityNumber = user?.personalSecurityNumber || "";
    this.role = user?.role || "";
  }
}
