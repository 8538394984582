// EmptyIndexLayout.tsx
import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

interface EmptyIndexLayoutProps {
  pageTitle: string;
  createNewItemClick: () => void;
}

const EmptyIndexLayout: React.FC<EmptyIndexLayoutProps> = ({ pageTitle, createNewItemClick }) => {
  return (
    <DashboardLayout>
      <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="left" mt={{ xs: 2, sm: "auto" }}>
        <MDButton type="submit" variant="gradient" color="dark" onClick={createNewItemClick}>
          Skapa {pageTitle.toLowerCase()}
        </MDButton>
      </MDBox>
    </DashboardLayout>
  );
};

export default EmptyIndexLayout;
