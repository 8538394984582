import { Timestamp } from "firebase/firestore";

/**
 * Converts a JavaScript Date object to a Firestore Timestamp in a safe manner.
 *
 * This utility function is designed to handle conversion of dates to Firestore Timestamps
 * while gracefully handling cases where the input might be null or undefined.
 * It ensures that only valid Date objects are converted; otherwise, it returns null.
 *
 * Usage of this function is particularly useful when dealing with data that may
 * or may not include proper Date objects, such as data fetched from an API or user input
 * that has not been validated. It helps prevent runtime errors that could occur
 * from attempting to convert invalid or non-existent dates to Firestore Timestamps.
 *
 * @param {Date | null | undefined} date - The date to be converted to a Firestore Timestamp.
 *     Can be a Date object, null, or undefined. If the value is a valid Date object,
 *     the function converts it to a Firestore Timestamp. If the value is null or undefined,
 *     or not a Date object, the function returns null.
 *
 * @returns {Timestamp | null} A Firestore Timestamp corresponding to the provided Date object
 *     if it is a valid Date; otherwise, returns null.
 *
 * @example
 * // Convert a valid Date object to a Firestore Timestamp
 * const timestamp = safeTimestampFromDate(new Date());
 *
 * @example
 * // Returns null for invalid or non-existent dates
 * const invalidTimestamp = safeTimestampFromDate(null);
 *
 * @see {@link https://firebase.google.com/docs/reference/js/firestore_.timestamp} Firestore Timestamp documentation
 */
export const safeTimestampFromDate = (date: Date | null | undefined): Timestamp | null => {
  if (date instanceof Date) {
    return Timestamp.fromDate(date);
  }
  return null;
};

/**
 * Safely converts a Firestore Timestamp to a JavaScript Date object.
 * If the input is not a valid Timestamp or is null/undefined, returns null.
 * This function checks for the presence of the toDate() method to verify
 * that the input is indeed a Firestore Timestamp.
 *
 * @param {Timestamp | null | undefined} timestamp - The Firestore Timestamp to convert to a Date.
 * @returns {Date | null} The JavaScript Date object or null if the input is invalid.
 */
export function safeDateFromTimestamp(timestamp: Timestamp | null | undefined): Date | null {
  if (timestamp && typeof timestamp.toDate === "function") {
    return timestamp.toDate();
  }
  return null;
}

export const safeTimestampFromDate2 = (
  date: Date | string | null | undefined
): Timestamp | null => {
  if (!date) return null;

  let parsedDate: Date;

  // If the input is a string, try converting it to a Date object
  if (typeof date === "string") {
    parsedDate = new Date(date);

    // Check if the parsed date is invalid (NaN)
    if (isNaN(parsedDate.getTime())) {
      return null; // Invalid date string
    }
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    return null; // If the date is neither a string nor a Date object
  }

  // Convert the valid Date object to a Firestore Timestamp
  return Timestamp.fromDate(parsedDate);
};
