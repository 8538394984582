import React, { createContext, useState, useEffect, ReactNode } from "react";
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SignOutUser, userStateListener } from "../firebase";
import isUserSuperAdmin from "components/SuperAdminHelper";
import getUserData, { UserData } from "components/userHelper";

interface Props {
  children?: ReactNode;
}

interface CachedUserData {
  user: User;
  isSuperAdmin: boolean;
  userData?: UserData;
}

interface AuthContextType {
  currentUser: User | null;
  isSuperAdmin: boolean;
  userData?: UserData;
  setCurrentUser: (user: User) => void;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isSuperAdmin: false,
  setCurrentUser: () => {},
  signOut: () => {},
});

export const AuthProvider = ({ children }: Props) => {
  const [cachedUserData, setCachedUserData] = useState<CachedUserData | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = userStateListener(async (user: User | null) => {
      if (user) {
        try {
          await handleUserLogin(user);
        } catch (error) {
          console.error("Error handling user login:", error);
          signOut(); // Sign out if an error occurs
        }
      } else {
        setCachedUserData(null);
      }
      setIsLoadingUser(false);
    });

    return unsubscribe;
  }, []);

  const handleUserLogin = async (user: User) => {
    const prevUserId = cachedUserData?.user?.uid || null;
    const userId = user.uid;

    if (prevUserId !== userId) {
      const isSuperAdmin = await checkSuperAdmin(user);
      const userData = isSuperAdmin ? undefined : await getUserData(user.uid);

      if (!isSuperAdmin && !userData) {
        throw new Error("User data not found, logging out."); // Trigger logout if user data not found
      }

      setCachedUserData({ user, isSuperAdmin, userData });
    }
  };

  const checkSuperAdmin = async (user: User): Promise<boolean> => {
    try {
      return await isUserSuperAdmin(user);
    } catch (error) {
      console.error("Error checking super admin status:", error);
      return false;
    }
  };

  useEffect(() => {
    if (!isLoadingUser && cachedUserData && initialRender) {
      if (!cachedUserData.isSuperAdmin && !cachedUserData.userData) {
        navigate("/login");
      } else if (cachedUserData.isSuperAdmin) {
        navigate("/superadmin/customers");
      }
      setInitialRender(false);
    }
  }, [isLoadingUser, cachedUserData, navigate, initialRender]);

  const setCurrentUser = async (user: User) => {
    try {
      await handleUserLogin(user);
    } catch (error) {
      console.error("Error setting current user:", error);
      throw error;
    }
  };

  const signOut = () => {
    SignOutUser();
    setCachedUserData(null);
    setInitialRender(true);
    navigate("/");
  };

  const contextValue: AuthContextType = {
    currentUser: cachedUserData?.user || null,
    isSuperAdmin: cachedUserData?.isSuperAdmin || false,
    userData: cachedUserData?.userData,
    setCurrentUser,
    signOut,
  };

  return !isLoadingUser ? (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  ) : null;
};
