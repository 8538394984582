import { InviteDbModel } from "db/models/invites/inviteDbModel";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import CustomerContext from "./CustomerContext";
import { query, collection, where, orderBy, onSnapshot, doc } from "firebase/firestore";
import { FirestoreDb } from "../firebase";
import { InviteModel } from "db/models/invites/inviteModel";

const db = FirestoreDb();

interface InvitesContextType {
  invites: InviteModel[];
}

const InvitesContext = createContext<InvitesContextType>({
  invites: [],
});

export const InvitesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [invites, setInvites] = useState<InviteModel[]>([]);
  const { currentCustomer } = useContext(CustomerContext);

  function mapToInviteModel(dbInvite: InviteDbModel): InviteModel {
    return new InviteModel(dbInvite);
  }

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentCustomer?.id) {
      const q = query(
        collection(db, "invites"),
        where("customerId", "==", currentCustomer.id),
        orderBy("avaliableTo", "desc")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedInvites: InviteDbModel[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as InviteDbModel[]; // Correctly cast directly to InviteDbModel[]
        setInvites(fetchedInvites.map((inv) => mapToInviteModel(inv)));
      });
    } else {
      setInvites([]); // Reset invites if there is no selected customer
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [currentCustomer]);

  return <InvitesContext.Provider value={{ invites }}>{children}</InvitesContext.Provider>;
};

export default InvitesContext;
