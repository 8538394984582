/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://mmkdosemonitoring.seproduct/material-dashboard-2-pro-react-ts
* Copyright 2023 MMK Dose Monitoring (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "context/auth-context";
import { CustomerProvider } from "context/CustomerContext";
import { ProjectsProvider } from "context/ProjectsContext";
import { ObjectsProvider } from "context/ObjetcsContext";
import { AdminsProvider } from "context/AdminsContext";
import { InvitesProvider } from "context/InvitesContext";
import { UsersProvider } from "context/UsersContext";
import { ShiftsProvider } from "context/ShiftsContext";
import { SuperAdminsCustomersProvider } from "context/SuperAdminCustomersProvider";
import { SuperAdminsObjectsProvider } from "context/SuperAdminObjectsProvider";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://36c0173a3065adc64a35c2d2b4325363@o4508323997614080.ingest.de.sentry.io/4508324004954192",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://app.dosemonitoring.se/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <SuperAdminsCustomersProvider>
        <SuperAdminsObjectsProvider>
          <CustomerProvider>
            <AdminsProvider>
              <ProjectsProvider>
                <ObjectsProvider>
                  <InvitesProvider>
                    <ShiftsProvider>
                      <UsersProvider>
                        <MaterialUIControllerProvider>
                          <App />
                        </MaterialUIControllerProvider>
                      </UsersProvider>
                    </ShiftsProvider>
                  </InvitesProvider>
                </ObjectsProvider>
              </ProjectsProvider>
            </AdminsProvider>
          </CustomerProvider>
        </SuperAdminsObjectsProvider>
      </SuperAdminsCustomersProvider>
    </AuthProvider>
  </BrowserRouter>
);
