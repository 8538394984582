import { FirestoreDb } from "../../firebase"; // Import your Firestore instance
import { doc, collection, writeBatch, getDoc, arrayUnion, Timestamp } from "firebase/firestore"; // Import necessary Firestore modules

// Initialize Firestore
const db = FirestoreDb();

// Collection names
const USERS_COLLECTION = "users";
const CUSTOMERS_COLLECTION = "customers";

// Define the UserDbModel interface
export interface UserDbModel {
  id: string; // The user ID (admin ID) should be used as the document ID but not stored in the document itself
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  customer: any; // Reference to customer document
  customerId: string;
  pushInterval: number;
  personalSecurityNumber: string;
  employedFrom: Timestamp;
  employedTo: Timestamp | null;
}

// Create a new user document in Firestore and update the customer document using a batch operation
export const createUser = async (user: UserDbModel): Promise<UserDbModel> => {
  // Start a Firestore batch
  const batch = writeBatch(db);

  try {
    // Create a reference to the customer document using customerId
    const customerRef = doc(db, CUSTOMERS_COLLECTION, user.customerId);

    // Retrieve the customer document to ensure it exists
    const customerDoc = await getDoc(customerRef);
    if (!customerDoc.exists()) {
      throw new Error(`Customer document with ID ${user.customerId} not found.`);
    }

    // Use the provided user ID (same as the admin ID) to create a new user document reference
    const newUserRef = doc(db, USERS_COLLECTION, user.id); // Use user.id as the document ID

    // Exclude the "id" field from the document data before adding to Firestore
    const { id, ...userDataWithoutId } = user; // Remove the "id" field

    // Include the customer reference in the user data
    const userDataWithCustomerRef = {
      ...userDataWithoutId,
      customer: customerRef,
    };

    // Set the new user document in the batch with the specified ID (excluding "id" field in document data)
    batch.set(newUserRef, userDataWithCustomerRef);

    // Update the customer document to add the new user ID to the "appUsers" array in the batch
    batch.update(customerRef, {
      appUsers: arrayUnion(newUserRef.id),
    });

    // Commit the batch operation
    await batch.commit();

    // Return the created user with the given ID as a reference only, not stored in Firestore document fields
    return {
      id: newUserRef.id, // This will be the same as user.id (admin ID)
      ...userDataWithoutId, // Return the user data without "id" field
    } as UserDbModel;
  } catch (error) {
    console.error("Error creating user and updating customer with batch:", error);
    throw error;
  }
};
