import { useContext, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { Customer } from "db/models/Customer";
import { useNavigate } from "react-router-dom";
import getTableData from "./data/dataTableData";
import SuperAdminCustomersContext from "context/SuperAdminCustomersProvider";

function Customers(): JSX.Element {
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const { allCustomers } = useContext(SuperAdminCustomersContext);

  const navigate = useNavigate();

  function createNewCustomer(event: any): void {
    navigate("/superadmin/create-customer");
  }

  const handleRowClick = (customerId: string) => {
    // Navigate to the edit page with the customer ID as a parameter
    navigate(`/superadmin/edit-customer/${customerId}`);
  };

  if (!customers) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <br></br>
        <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="left" mt={{ xs: 2, sm: "auto" }}>
          <MDButton type="submit" variant="gradient" color="dark" onClick={createNewCustomer}>
            Create new customer
          </MDButton>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <br></br>
      <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="left" mt={{ xs: 2, sm: "auto" }}>
        <MDButton type="submit" variant="gradient" color="dark" onClick={createNewCustomer}>
          Create new customer
        </MDButton>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Customers
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={getTableData(allCustomers)}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  canSearch={true}
                  isSorted={true}
                  noEndBorder
                  onRowClick={handleRowClick}
                  showHoverStyle={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Customers;
