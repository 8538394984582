import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import EmptyIndexLayout from "layouts/baseComponents/EmptyIndexLayout";
import { getTableData } from "./data/dataTableData";
import IndexLayoutWithOutAddNew from "layouts/baseComponents/indexLayoutWithoutAddNew";
import UsersContext from "context/UsersContext";

function Employees(): JSX.Element {
  const { users } = useContext(UsersContext);
  const navigate = useNavigate();

  const handleRowClick = (employeeId: string) => {
    navigate(`/employees/overview/${employeeId}`);
  };

  const createNewInvite = () => {
    navigate("/invites");
  };

  if (!users || users.length === 0) {
    return <EmptyIndexLayout pageTitle="Bjud in personal" createNewItemClick={createNewInvite} />;
  }

  return (
    <IndexLayoutWithOutAddNew title="Personal">
      <DataTableCard data={getTableData(users)} onRowClick={handleRowClick} showHoverStyle={true} />
    </IndexLayoutWithOutAddNew>
  );
}

export default Employees;
