import React, { useContext, useEffect, useState } from "react";
import { Grid, Snackbar, Alert } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { createBatchShifts } from "db/repositories/shiftsRepository"; // Update the import path
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import DateSelection from "../helpers/dateSelectionComponent";
import { WorkObject } from "db/models/workObject";
import { ActionPlan } from "db/models/ActionPlan";
import { Project } from "db/models/Project";
import { addDays, differenceInDays, startOfDay } from "date-fns";
import { getTimeDifferenceOnlyInMinutes } from "../helpers/dateCalculator";
import { mapPlannedShiftToShift } from "db/models/shiftsModels/mappers/shiftModelMapper";
import BaseShiftForm from "../components/baseShiftForm";
import UsersContext from "context/UsersContext";
import { UserModel } from "db/models/users/UserModel";
import CustomerContext from "context/CustomerContext";
import { Shift } from "db/models/shiftsModels/Shift";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import ShiftsContext from "context/ShiftsContext";
import { calculateExposureByDateRange } from "../helpers/exposureCalcluator";
import DaySelectionComponent from "../helpers/daySelectionComponent";

interface FormValues extends PlannedShift {}

interface CreatePlannedWorkshiftProps {}

const validationSchema = Yup.object().shape({
  plannedStartTime: Yup.date().required("Required"),
  plannedEndTime: Yup.date().required("Required"),
});

const CreatePlannedWorkshift: React.FC<CreatePlannedWorkshiftProps> = ({}) => {
  const { users } = useContext(UsersContext);
  const { plannedShifts, doneShifts } = useContext(ShiftsContext);
  const { currentCustomer } = useContext(CustomerContext);

  const navigate = useNavigate();
  const [selectedWorkObject, setSelectedWorkObject] = useState<WorkObject | null>(null);
  const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlan | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [isSaving, setIsSaving] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [calculatedUsers, setCalculatedUsers] = useState<UserModel[]>([]);
  const [currentShift, setCurrentShift] = useState<PlannedShift | null>(null);

  const [selectedDays, setSelectedDays] = useState<number[]>([]); // State for selected days
  const [error, setError] = useState<string | null>(null); // Error state for validation
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for snackbar visibility
  const [shiftsCreatedCount, setShiftsCreatedCount] = useState(0); // Count of planned shifts

  useEffect(() => {
    if (users.length > 0 && plannedShifts && doneShifts) {
      let calculatedUsers = calculateExposureByDateRange(endDate, users, plannedShifts, doneShifts);
      setCalculatedUsers(calculatedUsers);
    }
  }, [users, plannedShifts, doneShifts, endDate]);

  const handleUserSelect = (selectedUsers: UserModel[]) => {
    setSelectedUsers(selectedUsers);
  };

  const { shiftId } = useParams(); // Get shiftId from the route params

  const initialValues: FormValues = {
    id: "",
    plannedStartTime: null,
    plannedEndTime: null,
    estimatedWorkMinutes: null,
    project: null,
    object: null,
    actionPlan: null,
    customer: null,
    user: null,
    radiationDose: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSaving(true);
        setIsSaveDisabled(true); // Disable the button
        setIsSubmitted(true);
        setError(null); // Reset the error state

        // Check if Object and ActionPlan are selected
        if (!selectedWorkObject || !selectedActionPlan) {
          return;
        }

        // Check if Project is selected
        if (!selectedProject) {
          setError("Vänligen välj ett projekt");
          return;
        }

        // Check if Start and End time are selected
        if (!values.plannedStartTime || !values.plannedEndTime) {
          setError("Vänligen välj start och sluttid");
          return;
        }

        // Convert plannedStartTime and plannedEndTime to Date objects if necessary
        const plannedStartTime = new Date(values.plannedStartTime);
        const plannedEndTime = new Date(values.plannedEndTime);

        // Check if Start time is before End time
        if (plannedEndTime <= plannedStartTime) {
          setError("Startiden måste vara före sluttiden");
          return;
        }

        // Check if at least one user is selected
        if (selectedUsers.length === 0) {
          setError("Du måste välja minst en personal");
          return;
        }

        // Check if at least one day is selected
        if (selectedDays.length === 0) {
          setError("Vänligen välj minst en dag för arbetspassen");
          return;
        }

        const shiftsToCreate = [] as Shift[];

        // Iterate through selected users
        for (const user of selectedUsers) {
          let currentDate = startOfDay(plannedStartTime); // Start from plannedStartTime date
          const endDate = startOfDay(plannedEndTime); // End at plannedEndTime date

          // Iterate through each day between plannedStartTime and plannedEndTime
          while (currentDate <= endDate) {
            // Only process the date if it matches one of the selected days
            if (selectedDays.includes(currentDate.getDay())) {
              const plannedStartTimeWithTime = new Date(currentDate);
              plannedStartTimeWithTime.setHours(plannedStartTime.getHours());
              plannedStartTimeWithTime.setMinutes(plannedStartTime.getMinutes());

              const plannedEndTimeWithTime = new Date(currentDate);
              plannedEndTimeWithTime.setHours(plannedEndTime.getHours());
              plannedEndTimeWithTime.setMinutes(plannedEndTime.getMinutes());

              const estimatedWorkMinutes = getTimeDifferenceOnlyInMinutes(
                plannedStartTime,
                plannedEndTime
              );

              const plannedShift: PlannedShift = {
                id: "",
                plannedStartTime: plannedStartTimeWithTime,
                plannedEndTime: plannedEndTimeWithTime,
                estimatedWorkMinutes: estimatedWorkMinutes,
                project: {
                  id: selectedProject.id,
                  name: selectedProject.code + ", " + selectedProject.name,
                },
                object: {
                  id: selectedWorkObject.id,
                  name: selectedWorkObject.name,
                },
                actionPlan: {
                  id: selectedActionPlan.id,
                  name: selectedActionPlan.name,
                  radiationLevel: selectedActionPlan.radiationLevel,
                },
                customer: {
                  id: currentCustomer.id,
                  name: currentCustomer.name,
                },
                user: {
                  id: user.id,
                  name: user.firstName + " " + user.lastName,
                },
                radiationDose: (selectedActionPlan.radiationLevel * estimatedWorkMinutes) / 60,
              };

              const savableShift = mapPlannedShiftToShift(plannedShift);
              shiftsToCreate.push(savableShift);
            }

            // Move to the next day
            currentDate = addDays(currentDate, 1);
          }
        }

        await createBatchShifts(shiftsToCreate);
        console.log("Saved: " + shiftsToCreate.length + " shifts.");

        setShiftsCreatedCount(shiftsToCreate.length); // Set the number of created shifts
        setSnackbarOpen(true); // Open snackbar
        navigate("/workshifts/plannedshifts");
      } catch (error) {
        alert("Error: " + error);
        setError("Kunde inte skapa arbetspassen. Försök igen."); // General error message
      } finally {
        setIsSaving(false);
        setIsSaveDisabled(false); // Enable the button after saving
      }
    },
  });

  return (
    <BaseCreateEdit title={"Skapa planerat arbetspass"} onSubmit={formik.handleSubmit}>
      <BaseShiftForm
        selectedWorkObject={selectedWorkObject}
        setSelectedWorkObject={setSelectedWorkObject}
        selectedActionPlan={selectedActionPlan}
        setSelectedActionPlan={setSelectedActionPlan}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        selectedUsers={selectedUsers}
        handleUserSelect={handleUserSelect}
        users={calculatedUsers}
        isSubmitted={isSubmitted}
        currentShift={currentShift}
        customerId={currentCustomer.id}
        isSaveDisabled={isSaveDisabled}
        isSaving={isSaving}
        isPlannedShift={true}
      >
        <Grid item xs={12} sm={6}>
          <DateSelection
            data-testid="planned-start-time"
            id="plannedStartTime"
            fullWidth
            label="Startar"
            name="plannedStartTime"
            value={formik.values.plannedStartTime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.plannedStartTime && Boolean(formik.errors.plannedStartTime)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateSelection
            data-testid="planned-end-time"
            id="plannedEndTime"
            fullWidth
            label="Avslutas"
            name="plannedEndTime"
            value={formik.values.plannedEndTime}
            onChange={(e) => {
              formik.handleChange(e);
              setEndDate(new Date(e.target.value));
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.plannedEndTime && Boolean(formik.errors.plannedEndTime)}
          />
        </Grid>

        <DaySelectionComponent onSelectionChange={(days) => setSelectedDays(days)} />

        {/* Show error message if no days are selected */}
        {error && (
          <Grid item xs={12}>
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Grid>
        )}
      </BaseShiftForm>

      {/* Snackbar to show the number of shifts created */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Show for 3 seconds
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: "100%" }}>
          {shiftsCreatedCount} arbetspass har skapats.
        </Alert>
      </Snackbar>
    </BaseCreateEdit>
  );
};

export default CreatePlannedWorkshift;
