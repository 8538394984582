import { Grid, Typography } from "@mui/material";
import { ReactElement } from "react";

interface WorkShiftOverviewProps {
  title: string;
  icon: ReactElement;
  shiftsCount: number;
  totalTime: string;
  exposure?: string;
}

const WorkShiftOverview: React.FC<WorkShiftOverviewProps> = ({
  title,
  icon,
  shiftsCount,
  totalTime,
  exposure,
}) => (
  <Grid item xs={12} md={6}>
    <Typography variant="h6">
      {icon} {title}
    </Typography>
    <Typography>Antal: {shiftsCount} st</Typography>
    <Typography>Tid: {totalTime}</Typography>
  </Grid>
);

export default WorkShiftOverview;
