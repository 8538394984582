import { useContext, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

// Firebase authentication
import { signInUser } from "../../../firebase";
import { AuthContext } from "context/auth-context";

function Cover(): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const navigate = useNavigate();
  const { setCurrentUser } = useContext(AuthContext);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onLoginClicked();
    }
  };

  const onLoginClicked = async () => {
    try {
      // Send the email and password to Firebase
      const userCredential = await signInUser(email, password);

      if (userCredential && userCredential.user) {
        // Set the cached user data
        await setCurrentUser(userCredential.user);

        // Navigate to the dashboard
        navigate("/dashboard");
      }
    } catch (error: any) {
      console.log("User Sign In Failed", error.message);
      alert("Fel e-post eller lösenord");
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Logga in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Ange e-postadress och lösenord och logga in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                onChange={onChangeEmail}
                onKeyPress={handleKeyPress}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                onChange={onChangePassword}
                onKeyPress={handleKeyPress}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={onLoginClicked}
                disabled={!email || !password} // Disable button if email or password is empty
              >
                logga in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Har du inget konto?{" "}
                <MDTypography
                  component={Link}
                  to="https://dosemonitoring.se"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Kontakta oss
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
