import React from "react";
import { format } from "date-fns";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import { sv } from "date-fns/locale";
import { MonthlyDose, toNamedColor } from "helpers/monthlyDose";
import ShiftUnifier from "helpers/shiftUnifier";
import ShiftAggregator from "helpers/shiftAggregator";
import { Box, Typography, Chip } from "@mui/material";
import { ShiftsOverviewService } from "layouts/reports/models/shiftsOverViewConverter";

function generateChartData(monthlyDoses: MonthlyDose[]): {
  labels: string[];
  datasets: any[];
} {
  const labels = monthlyDoses.map((monthlyDose) =>
    format(monthlyDose.period, "MMMM yyyy", { locale: sv })
  );
  const backgroundColors = monthlyDoses.map((monthlyDose) => monthlyDose.monthColor);

  const datasets = [
    {
      label: "Strålningsdos (%)",
      data: monthlyDoses.map((monthlyDose) => monthlyDose.totalRadiationPercentage),
      backgroundColor: backgroundColors,
      borderColor: "#000",
      borderWidth: 1,
    },
  ];

  return { labels, datasets };
}

const RadiationDoseChart: React.FC<{
  doneShifts: DoneShift[];
  plannedShifts: PlannedShift[];
  excludePlanned?: boolean;
  startDate: Date;
  endDate: Date;
}> = ({ doneShifts, plannedShifts, excludePlanned = false, startDate, endDate }) => {
  const unifiedShifts = ShiftUnifier.unifyShifts(
    doneShifts,
    plannedShifts,
    !excludePlanned,
    startDate,
    endDate
  );

  const timeDifference = endDate.getTime() - startDate.getTime(); // Corrected subtraction
  const daysInRange = Math.ceil(timeDifference / (1000 * 3600 * 24));

  // Calculate the maximum radiation for the given date range
  const maxRadiationInRange = (daysInRange / 365) * 720000;

  const aggregatedShifts = ShiftAggregator.aggregateShifts(
    maxRadiationInRange,
    unifiedShifts,
    startDate,
    endDate
  );
  const chartData = generateChartData(aggregatedShifts);

  const totalPercentage = aggregatedShifts.reduce(
    (acc, monthlyDose) => acc + monthlyDose.totalRadiationPercentage,
    0
  );

  return (
    <Box>
      <br />
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Total dos för perioden</Typography>
        <Chip
          label={`${totalPercentage.toFixed(0)}%`}
          color={toNamedColor(totalPercentage)}
          style={{ marginLeft: "8px" }}
        />
      </Box>
      <VerticalBarChart chart={chartData} height="500px" title="" description="" />
    </Box>
  );
};

export default RadiationDoseChart;
