import { safeDateFromTimestamp } from "db/helpers/dateMapperHelpers";
import { InviteDbModel } from "./inviteDbModel";

export class InviteModel {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  personalSecurityNumber: string;
  employedFrom: Date;
  employedTo: Date;
  avaliableTo: Date;
  acceptedAt: Date | null;
  declinedAt: Date | null;
  code: string;
  customerId: string;
  startRadiationDoseBqh: number;

  constructor(inviteDbModel: InviteDbModel) {
    this.id = inviteDbModel.id;
    this.firstName = inviteDbModel.firstName;
    this.lastName = inviteDbModel.lastName;
    this.role = inviteDbModel.role;
    this.email = inviteDbModel.email;
    this.personalSecurityNumber = inviteDbModel.personalSecurityNumber;
    this.employedFrom = safeDateFromTimestamp(inviteDbModel.employedFrom);
    this.employedTo = safeDateFromTimestamp(inviteDbModel.employedTo);
    this.avaliableTo = safeDateFromTimestamp(inviteDbModel.avaliableTo);
    this.acceptedAt = safeDateFromTimestamp(inviteDbModel.acceptedAt);
    this.declinedAt = safeDateFromTimestamp(inviteDbModel.declinedAt);
    this.code = inviteDbModel.code;
    this.customerId = inviteDbModel.customerId;
    this.startRadiationDoseBqh = inviteDbModel.startRadiationDoseBqh;
  }
}
