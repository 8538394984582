import { Box, Grid, TextField, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { AuthContext } from "context/auth-context";
import { EditUserAdminModel } from "db/models/users/EditUserAdminModel";
import { updateAdmin } from "db/repositories/adminRepository";
import { useFormik } from "formik";
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

interface FormValues extends EditUserAdminModel {}

function AccountSettings(): JSX.Element {
  const { userData, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const getValidationSchema = () =>
    Yup.object({
      firstName: Yup.string().required("Förnamn krävs"),
      lastName: Yup.string().required("Efternamn krävs"),
      email: Yup.string().email("Ogiltig e-postadress").notRequired(),
      currentPassword: Yup.string().when("newPassword", {
        is: (newPassword: string | undefined) => !!newPassword && newPassword.length > 0,
        then: (schema) => schema.required("Ditt nuvarande lösenord krävs om du vill byta lösenord"),
        otherwise: (schema) => schema.notRequired(),
      }),
      newPassword: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Lösenordet måste innehålla minst åtta tecken, en stor bokstav, en liten bokstav, ett nummer och ett specialtecken"
        )
        .notRequired(),
      repeatNewPassword: Yup.string().when("newPassword", {
        is: (newPassword: string | undefined) => !!newPassword && newPassword.length > 0,
        then: (schema) =>
          schema
            .oneOf([Yup.ref("newPassword"), null], "Lösenorden matchar inte")
            .required("Bekräfta lösenordet"),
        otherwise: (schema) => schema.notRequired(),
      }),
    });

  const validationSchema = getValidationSchema();

  const initialValues: FormValues = {
    id: userData.firstName, // Initialize with empty string or default value
    email: userData.firstName,
    firstName: "",
    lastName: "",
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await updateAdmin(currentUser.uid, values);
        alert("Profil uppdaterad");
      } catch (error) {
        alert("Fel vid sparandet av profil: " + error);
      } finally {
        setSubmitting(false); // Ensure isSubmitting is set to false once the operation is complete
      }
    },
  });

  useEffect(() => {
    // Assuming getAdminsByCustomerId and other async operations are defined
    async function fetchAndSetFormValues() {
      // Now, update the form values with userData
      if (userData) {
        formik.setValues({
          id: currentUser.uid, // Assuming userData has an id
          email: currentUser.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          currentPassword: "", // Keep sensitive fields empty
          newPassword: "",
          repeatNewPassword: "",
        });
      }
    }

    fetchAndSetFormValues();
  }, [userData]);

  return (
    <BaseCreateEdit title={"Mitt konto"} onSubmit={formik.handleSubmit}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Förnamn"
          id="surname"
          name="firstName"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Efternamn"
          id="lastName"
          name="lastName"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      {/* Email Field */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="E-post"
          id="email"
          name="email"
          type="email"
          disabled={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>

      <Grid item xs={12}>
        <hr style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <MDTypography variant="h5">Uppdatera lösenord</MDTypography>
          <MDTypography variant="p">
            För din egen säkerhet krävs ditt nuvarande lösenord. Har du glömt ditt lösenord kan du
            logga in och välja att återställa lösenordet vid inloggningsskärmen
          </MDTypography>
        </Box>
      </Grid>
      {/* Password Field */}
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          label="Nuvarande lösenord"
          id="currentPassword"
          name="currentPassword"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.currentPassword}
          error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
          helperText={formik.touched.currentPassword && formik.errors.currentPassword}
        />
      </Grid>

      {/* Password Field */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Nytt lösenord"
          id="newPassword"
          name="newPassword"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
      </Grid>

      {/* Repeat Password Field */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Bekräfta nytt lösenord"
          id="repeatNewPassword"
          name="repeatNewPassword"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.repeatNewPassword}
          error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
          helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
        />
      </Grid>
      <Grid item xs={12}>
        <MDButton variant="gradient" color="dark" type="submit" disabled={formik.isSubmitting}>
          Spara
        </MDButton>
      </Grid>
    </BaseCreateEdit>
  );
}

export default AccountSettings;
