import { UserDbModel } from "./user";

export class UserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  personalSecurityNumber: string;
  employedFrom: Date;
  employedTo: Date | null;
  exposurePercentage: number;
  customerId: string;
  isOwner: boolean;

  constructor(dbUser: UserDbModel) {
    this.id = dbUser.id;
    this.firstName = dbUser.firstName;
    this.lastName = dbUser.lastName;
    this.email = dbUser.email;
    this.role = dbUser.role;
    this.personalSecurityNumber = dbUser.personalSecurityNumber;
    this.employedFrom = dbUser.employedFrom.toDate();
    this.employedTo = dbUser.employedTo ? dbUser.employedTo.toDate() : null;
    this.exposurePercentage = dbUser.exposurePercentage;
    this.customerId = dbUser.customerId;
    this.isOwner = dbUser.isOwner;
  }
}
