// Import your custom Yup extension
import "helpers/yupExtensions";

import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AdminToUserData } from "../AdminToUserData"; // Import the AdminToUserData interface
import UsersContext from "context/UsersContext"; // Import the UsersContext

interface AdminDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (adminId: string, adminToUserData: AdminToUserData | null) => void;
  adminId: string;
  adminData: Admin;
}

interface Admin {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

// Define initial form values
const initialValues = {
  isAppUser: false,
  personalSecurityNumber: "",
  employedFrom: "",
  employedTo: "",
};

// Define the Yup validation schema and use the new custom method
const validationSchema = Yup.object().shape({
  isAppUser: Yup.boolean(),
  personalSecurityNumber: Yup.string()
    .nullable()
    .swedishPersonalNumber("Personnummer är inte giltigt") // Use the custom validation method
    .test("is-required", "Personnummer är obligatoriskt", function (value) {
      return !this.parent.isAppUser || !!value;
    }),
  employedFrom: Yup.date()
    .nullable()
    .test("is-required", "Anställd från är obligatoriskt", function (value) {
      return !this.parent.isAppUser || !!value;
    }),
  employedTo: Yup.date()
    .nullable()
    .test("is-required", "Anställd till är obligatoriskt", function (value) {
      return !this.parent.isAppUser || !!value;
    }),
});

const AdminDialog: React.FC<AdminDialogProps> = ({ open, onClose, onSave, adminId, adminData }) => {
  const { users } = useContext(UsersContext); // Get the users from the context
  const [isExistingAppUser, setIsExistingAppUser] = useState(false);

  useEffect(() => {
    // Check if the admin is already an app user
    const existingAppUser = users.some((user) => user.id === adminId);
    setIsExistingAppUser(existingAppUser);
  }, [users, adminId]);

  const handleSave = (values: typeof initialValues) => {
    const adminToUserData = values.isAppUser
      ? {
          personalSecurityNumber: values.personalSecurityNumber,
          employedFrom: new Date(values.employedFrom),
          employedTo: values.employedTo ? new Date(values.employedTo) : null,
        }
      : null;

    onSave(adminId, adminToUserData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Admin Info: {adminData.firstName} {adminData.lastName}
      </DialogTitle>
      <Typography variant="body2" color="textSecondary" sx={{ px: 2, pb: 2 }}>
        Ändra administratörsinformationen eller gör administratören till en appanvändare genom att
        fylla i nödvändiga fält.
      </Typography>
      {/* Show message if admin is already an app user */}
      {isExistingAppUser && (
        <Typography variant="body2" color="primary" sx={{ px: 2 }}>
          Den här administratören är redan registrerad som appanvändare och kan logga in i appen med
          sin e-postadress och lösenord.
        </Typography>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema} // Use the validation schema with custom method
        onSubmit={handleSave}
        validateOnChange
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isValid }) => (
          <Form>
            <DialogContent>
              {/* Email Display */}
              <Typography variant="body2" sx={{ pb: 2 }}>
                Email: {adminData.email}
              </Typography>
              {/* Checkbox to set as App User */}
              {!isExistingAppUser && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isAppUser}
                      onChange={(e) => setFieldValue("isAppUser", e.target.checked)}
                      name="isAppUser"
                      color="primary"
                    />
                  }
                  label="Gör admin till appanvändare"
                />
              )}
              {/* Personal Security Number */}
              {values.isAppUser && (
                <>
                  <TextField
                    label="Personnummer"
                    name="personalSecurityNumber"
                    value={values.personalSecurityNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    error={touched.personalSecurityNumber && Boolean(errors.personalSecurityNumber)}
                    helperText={touched.personalSecurityNumber && errors.personalSecurityNumber}
                    required
                  />
                  {/* Employed From Date */}
                  <TextField
                    label="Anställd från"
                    type="date"
                    name="employedFrom"
                    value={values.employedFrom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={touched.employedFrom && Boolean(errors.employedFrom)}
                    helperText={touched.employedFrom && errors.employedFrom}
                    required
                  />
                  {/* Employed To Date */}
                  <TextField
                    label="Anställd till"
                    type="date"
                    name="employedTo"
                    value={values.employedTo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={touched.employedTo && Boolean(errors.employedTo)}
                    helperText={touched.employedTo && errors.employedTo}
                    required
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              {/* Cancel Button */}
              <Button onClick={onClose} color="secondary" sx={{ textTransform: "none" }}>
                Avbryt
              </Button>
              {/* Save Button */}
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ textTransform: "none", color: "white" }}
                style={{ color: "white" }}
                disabled={!isValid || !values.isAppUser}
              >
                Spara
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AdminDialog;
