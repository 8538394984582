import { AdminWithAppUserProps } from "db/models/users/AdminWithUserProps";

const getTableData = (admins: AdminWithAppUserProps[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Förnamn", accessor: "firstName" },
      { Header: "Efternamn", accessor: "surName" },
      { Header: "E-post", accessor: "email" },
      { Header: "Ägare", accessor: "isOwner" },
    ],
    rows: admins.map((admni) => ({
      id: admni.id,
      firstName: admni.firstName,
      surName: admni.lastName,
      email: admni.email,
      isOwner: admni.isOwner,
    })),
  };
  return dataTableData;
};

export default getTableData;
