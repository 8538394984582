import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";

import { Customer } from "db/models/Customer";

const getTableData = (customers: Customer[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Number of Users", accessor: "numberOfUsers", align: "right" },
      { Header: "Admins", accessor: "admins", align: "right" },
      { Header: "App Users", accessor: "appUsers", align: "right" },
    ],
    rows: customers.map((customer) => ({
      id: customer.id,
      name: customer.name,
      numberOfUsers: customer.numberOfUsers,
      admins: customer.admins.length,
      appUsers: customer.appUsers.length,
    })),
  };
  return dataTableData;
};

export default getTableData;
