import { addDoc, collection } from "firebase/firestore";
import { FirestoreDb } from "../../firebase";
import { EmailModel } from "./emailModel";

const db = FirestoreDb();

// Collection name
const COLLECTION_NAME = "mailInvites";

export async function sendInviteEmail(email: EmailModel) {
  try {
    const quedMail = await addDoc(collection(db, COLLECTION_NAME), email);
    console.log("Queued email for delivery!");
  } catch (error) {
    console.error("Error sending invite email:", error);
    throw error;
  }
}
