import { ActionPlanModel } from "../dataDuplicationModels/ActionPlanModel";
import { CustomerModel } from "../dataDuplicationModels/CustomerModel";
import { ObjectModel } from "../dataDuplicationModels/ObjectModel";
import { ProjectModel } from "../dataDuplicationModels/ProjectModel";
import { UserModel } from "../dataDuplicationModels/UserModel";
import { Shift } from "./Shift";

export class BaseShiftModel {
  id: string | null;
  project: ProjectModel | null;
  object: ObjectModel;
  actionPlan: ActionPlanModel;
  customer: CustomerModel;
  user: UserModel;
  radiationDose: number;

  constructor(shift: Shift) {
    this.id = shift.id;
    this.project = shift.project;
    this.object = shift.object;
    this.actionPlan = shift.actionPlan;
    this.customer = shift.customer;
    this.user = shift.user;
    this.radiationDose = shift.radiationDose;
  }
}
