import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { AuthContext } from "context/auth-context";
import ProjectDropDown from "../components/projectDropDown";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { remove, updateShift } from "db/repositories/shiftsRepository";
import { mapDoneShiftToShift } from "db/models/shiftsModels/mappers/shiftModelMapper";
import MDButton from "components/MDButton";
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import { Formik, Form } from "formik";
import { Project } from "db/models/Project";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ShiftsContext from "context/ShiftsContext";

interface ShiftFormValues extends Partial<DoneShift> {
  // You can extend this interface to include any additional form fields or modifications
}

const EditWorkShift: React.FC = () => {
  const { shiftId } = useParams<{ shiftId: string }>();
  const { userData } = useContext(AuthContext);
  const [currentShift, setCurrentShift] = useState<DoneShift | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const { doneShifts } = useContext(ShiftsContext);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (shiftId) {
      try {
        const shift = doneShifts.find((shift) => shift.id === shiftId);
        setCurrentShift(shift);
      } catch (error) {
        console.error("Error fetching shift data:", error);
      }
    }
  }, [shiftId, doneShifts]);

  async function deleteShift(): Promise<void> {
    // Close the dialog
    setOpenDialog(false);

    if (!currentShift) return;

    try {
      // Call your API to delete the shift
      await remove(currentShift.id); // Replace with your actual API call
      navigate("/workshifts/doneshifts"); // Navigate away after deletion
    } catch (error) {
      alert("Error deleting shift: " + error);
    }
  }

  if (!currentShift) {
    return <div>Loading...</div>; // Or some loading component
  }

  // Helper function to format dates
  const formatDate = (date: Date) => date.toLocaleString(); // Adjust the format as needed

  async function saveShift(): Promise<void> {
    if (!currentShift) return;

    setIsSaving(true);

    try {
      currentShift.project = selectedProject;
      // Assuming mapDoneShiftToShift correctly prepares the data for submission
      await updateShift(currentShift.id, mapDoneShiftToShift(currentShift));
    } catch (error) {
      alert("Error saving shift data: " + error);
    } finally {
      setIsSaving(false);
      navigate("/workshifts/doneshifts");
    }
  }

  return (
    <>
      <Formik
        initialValues={currentShift} // Adjust this mapping as per your requirements
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({ setFieldValue, handleSubmit }) => (
          <BaseCreateEdit title="Genomfört arbetspass" onSubmit={handleSubmit}>
            <Form>
              <Box p={4}>
                <Box px={3} pb={3}>
                  <Grid container rowSpacing={4} alignItems="center">
                    {/* Display-only fields */}

                    <Grid item xs={12} sm={6} container alignItems="center">
                      <Grid item>
                        <Typography variant="h6" gutterBottom>
                          Tid
                        </Typography>
                        <Typography>{currentShift.actualWorkMinutes.toString()} min</Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Grid item xs>
                        <Typography variant="h6" gutterBottom>
                          Exponering
                        </Typography>
                        <Typography>{`${currentShift.radiationDose.toLocaleString("sv-SE", {
                          minimumFractionDigits: 3,
                        })} Bqh/m³`}</Typography>
                      </Grid>{" "}
                    </Grid>

                    <Grid item xs={12}>
                      <hr style={{ width: "100%" }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom>
                        Objekt
                      </Typography>
                      <Typography>{currentShift.object.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom>
                        Person
                      </Typography>
                      <Typography>{currentShift.user.name}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom>
                        Arbetsgivare
                      </Typography>
                      <Typography>{currentShift.customer.name}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ProjectDropDown
                        setProject={setSelectedProject}
                        selectedProjectId={currentShift.project?.id}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom>
                        Påbörjades
                      </Typography>
                      <Typography>{formatDate(new Date(currentShift.actualStartTime))}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom>
                        Avslutades
                      </Typography>
                      <Typography>{formatDate(new Date(currentShift.actualEndTime))}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between">
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={() => saveShift()}
                          disabled={isSaveDisabled || isSaving}
                        >
                          {isSaving ? "Sparar..." : "Uppdatera pass"}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="error" // Use the 'error' color to make the button red
                          onClick={() => setOpenDialog(true)} // Open the dialog on click
                        >
                          Ta bort arbetspass
                        </MDButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Form>
          </BaseCreateEdit>
        )}
      </Formik>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Shift"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker på att du vill radera detta passet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Avbryt
          </Button>
          <Button onClick={deleteShift} color="primary" autoFocus>
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditWorkShift;
