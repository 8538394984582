// CustomerContext.tsx
import { Customer } from "db/models/Customer";
import React, { createContext, useState, ReactNode, useContext, useEffect } from "react";
import { AuthContext } from "./auth-context";
import { getCustomerById } from "db/repositories/customersRepository";

interface CustomerContextType {
  currentCustomer: Customer | null;
  setCurrentCustomer: (customer: Customer | null) => void;
}

const CustomerContext = createContext<CustomerContextType>({
  currentCustomer: null,
  setCurrentCustomer: () => {},
});

export const CustomerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    const fetchCustomer = async () => {
      if (userData?.customerId) {
        const customerData = await getCustomerById(userData.customerId);
        setCurrentCustomer(customerData);
      }
    };

    fetchCustomer();
  }, [userData]);

  return (
    <CustomerContext.Provider value={{ currentCustomer, setCurrentCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
