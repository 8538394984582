import { Project } from "db/models/Project";
import { FirestoreDb } from "../../firebase";

import { setDoc, doc, collection, addDoc, deleteDoc } from "firebase/firestore";

// import firestore
const db = FirestoreDb();

// collection name
const COLLECTION_NAME = "projects";

// create a project
export const create = async (project: Project, customerId: string): Promise<Project> => {
  try {
    // Create a document reference for the customer
    const customerRef = doc(db, "customers", customerId);

    // Add the customer reference to the project data
    const projectDataWithCustomerRef = {
      ...project,
      customer: customerRef,
      customerId: customerId,
    };

    const { id: projectId, ...projectDataWithoutId } = projectDataWithCustomerRef;

    // Add the project to the collection
    const docRef = await addDoc(collection(db, COLLECTION_NAME), projectDataWithoutId);

    // Return the created project with the generated ID
    return {
      id: docRef.id,
      ...project,
    } as Project;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

// update project
// update project
export const update = async (
  id: string,
  project: Project,
  customerId: string
): Promise<Project> => {
  try {
    // Create a document reference for the customer
    const customerRef = doc(db, "customers", customerId);

    // Add the customer reference to the project data
    const projectDataWithCustomerRef = {
      ...project,
      customer: customerRef,
    };

    // Remove the 'id' property from the project data
    const { id: projectId, ...projectDataWithoutId } = projectDataWithCustomerRef;

    // Set the updated project document
    await setDoc(doc(db, COLLECTION_NAME, id), projectDataWithoutId);

    // Return the updated project
    return {
      ...projectDataWithCustomerRef,
    };
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

// delete the project
export const remove = async (id: string) => {
  await deleteDoc(doc(db, COLLECTION_NAME, id));
};
