import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { OngoingShift } from "db/models/shiftsModels/ongoingShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import { getTimeDifferenceInMinutes } from "./dateCalculator";
import { UserModel } from "db/models/users/UserModel";

export function calculateExposure(shift: PlannedShift): number {
  const radiationBqhPerM3 = typeof shift.radiationDose === "number" ? shift.radiationDose : 0;
  const estimatedWorkMinutes =
    typeof shift.estimatedWorkMinutes === "number" ? shift.estimatedWorkMinutes : 0;
  const exposure = radiationBqhPerM3 * (estimatedWorkMinutes / 60);
  return Number(exposure.toLocaleString("sv-SE", { minimumFractionDigits: 3 }));
}

export function calculateDoneExposure(shift: DoneShift): number {
  const radiationBqhPerM3 = typeof shift.radiationDose === "number" ? shift.radiationDose : 0;
  const estimatedWorkMinutes =
    typeof shift.actualWorkMinutes === "number" ? shift.actualWorkMinutes : 0;
  const exposure = radiationBqhPerM3 * (estimatedWorkMinutes / 60);
  return Number(exposure.toLocaleString("sv-SE", { minimumFractionDigits: 3 }));
}

export function calculateOngoingExposure(shift: OngoingShift): number {
  const radiationBqhPerM3 = typeof shift.radiationDose === "number" ? shift.radiationDose : 0;
  const estimatedWorkMinutes = getTimeDifferenceInMinutes(shift.actualStartTime, new Date());
  const exposure = radiationBqhPerM3 * (estimatedWorkMinutes / 60);
  return Number(exposure.toLocaleString("sv-SE", { minimumFractionDigits: 3 }));
}

function getTimeDifferenceInSeconds(startTime: Date, endTime: Date): number {
  return (endTime.getTime() - startTime.getTime()) / 1000; // Convert milliseconds to seconds
}

export function calculateOngoingExposureFromTime(radiation: number, startTime: Date): number {
  const estimatedWorkSeconds = getTimeDifferenceInSeconds(startTime, new Date());
  const exposure = radiation * (estimatedWorkSeconds / 3600); // 3600 seconds in an hour
  return parseFloat(exposure.toFixed(3));
}

const max365DaysDosage = 720000;
export function calcuateDailyExposurePercentage(exposure: number, daysInMonth: number): number {
  return (exposure / (daysInMonth * (max365DaysDosage / 365))) * 100;
}

export function calcuateDailyExposure(exposure: number, daysInMonth: number): number {
  return exposure;
}

export function calculateExposureByDateRange(
  endDate: Date,
  users: UserModel[],
  plannedShifts: PlannedShift[],
  doneShifts: DoneShift[]
): UserModel[] {
  const now = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - 365);

  return users.map((user) => {
    const userPlannedShifts = plannedShifts.filter(
      (shift) =>
        shift.user.id === user.id &&
        shift.plannedEndTime > startDate &&
        shift.plannedEndTime <= endDate
    );

    const userDoneShifts = doneShifts.filter(
      (shift) => shift.user.id === user.id && shift.actualEndTime && shift.actualEndTime > startDate
    );

    const totalExposure = [...userPlannedShifts, ...userDoneShifts].reduce(
      (total, shift) => total + (shift.radiationDose || 0),
      0
    );

    // Create a new user object to avoid mutating the original
    return {
      ...user,
      exposurePercentage: Math.round((totalExposure / 720000) * 100),
    };
  });
}
