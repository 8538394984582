import { CSSProperties } from "react";

export const createSquareStyle = (color: string): CSSProperties => ({
  height: "100%",
  backgroundColor: "transparent",
  border: `5px solid ${color}`,
  borderColor: color,
  borderRadius: 0,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
});
