import { Grid, Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ReactNode } from "react";

interface IndexLayoutProps {
  title: string;
  children: ReactNode;
}

const IndexLayoutWithOutAddNew: React.FC<IndexLayoutProps> = ({ title, children }) => {
  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <MDTypography variant="h3" gradient="secondary">
              {title}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <br></br>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox py={1}>{children}</MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default IndexLayoutWithOutAddNew;
