import { OverridableStringUnion } from "@mui/types";
import { ChipPropsColorOverrides } from "@mui/material/Chip";

export const colors = {
  doseGreenLight: "#30D158",
  doseOrangeLight: "#FF9F0A",
  doseRedLight: "#FF453A",
  accentPrimaryLight: "#007FBE",
};

export const toColor = (percentage: number): string => {
  if (percentage > 100) return colors.doseRedLight;
  if (percentage >= 90) return colors.doseOrangeLight;
  if (percentage >= 70) return colors.accentPrimaryLight;
  return colors.doseGreenLight;
};

export const toNamedColor = (
  percentage: number
): OverridableStringUnion<
  "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
  ChipPropsColorOverrides
> => {
  if (percentage > 100) return "error";
  if (percentage >= 90) return "warning";
  if (percentage >= 70) return "primary";
  return "success";
};

export class MonthlyDose {
  periodLimit: number;
  period: Date;
  radiationDose: number;

  constructor(yearLimit: number, period: Date, radiationDose: number) {
    this.periodLimit = yearLimit;
    this.period = period;
    this.radiationDose = radiationDose;
  }

  get totalRadiationPercentage(): number {
    return (this.radiationDose / this.periodLimit) * 100;
  }

  get monthColor(): string {
    return toColor(this.totalRadiationPercentage);
  }
}
