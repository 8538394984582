export function getTimeDifferenceInMinutes(date1: Date, date2: Date): number {
  // Get the time in milliseconds for each date
  const time1 = date1.getTime();
  const time2 = date2.getTime();

  // Calculate the difference in milliseconds
  let differenceInMilliseconds = Math.abs(time2 - time1);

  // Convert milliseconds to minutes
  const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

  return differenceInMinutes;
}

export function getTimeDifferenceInSeconds(date1: Date, date2: Date): string {
  // Get the time in milliseconds for each date
  const time1 = date1.getTime();
  const time2 = date2.getTime();

  const differenceInSeconds = Math.abs(time1 - time2) / 1000; // Convert milliseconds to seconds
  const minutes = Math.floor(differenceInSeconds / 60);
  const seconds = Math.floor(differenceInSeconds % 60);
  return `${minutes}:${seconds}`;
}

function getTimeFromMidnight(date: Date): number {
  // Get the time in milliseconds for the provided date
  const time = date.getTime();

  // Get the time in milliseconds since midnight
  const timeFromMidnight = time % (24 * 60 * 60 * 1000);

  // Convert milliseconds to minutes
  const minutesFromMidnight = Math.floor(timeFromMidnight / (1000 * 60));

  return minutesFromMidnight;
}

export function getTimeDifferenceOnlyInMinutes(date1: Date, date2: Date): number {
  // Calculate the time from midnight for each date
  const time1FromMidnight = getTimeFromMidnight(date1);
  const time2FromMidnight = getTimeFromMidnight(date2);

  // Calculate the difference in minutes
  const differenceInMinutes = Math.abs(time2FromMidnight - time1FromMidnight);

  return differenceInMinutes;
}
