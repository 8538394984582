import { Timestamp } from "firebase/firestore";
import { Shift } from "../Shift";
import { DoneShift } from "../doneShiftModel";
import { PlannedShift } from "../plannedShiftModel";

// Function to map DoneShift to Shift
export function mapDoneShiftToShift(shift: DoneShift): Shift {
  return {
    id: shift.id,
    plannedStartTime: null,
    plannedEndTime: null,
    actualStartTime: Timestamp.fromDate(shift.actualStartTime),
    actualEndTime: Timestamp.fromDate(shift.actualEndTime),
    estimatedWorkMinutes: null,
    actualWorkMinutes: shift.actualWorkMinutes,
    radiationDose: shift.radiationDose,
    project: shift.project,
    object: shift.object,
    actionPlan: shift.actionPlan,
    customer: shift.customer,
    user: shift.user,
  };
}

// Function to map PlannedShift to Shift
export function mapPlannedShiftToShift(shift: PlannedShift): Shift {
  return {
    id: shift.id,
    plannedStartTime: Timestamp.fromDate(shift.plannedStartTime),
    plannedEndTime: Timestamp.fromDate(shift.plannedEndTime),
    actualStartTime: null,
    actualEndTime: null,
    estimatedWorkMinutes: shift.estimatedWorkMinutes,
    actualWorkMinutes: null,
    radiationDose: shift.radiationDose,
    project: shift.project,
    object: shift.object,
    actionPlan: shift.actionPlan,
    customer: shift.customer,
    user: shift.user,
  };
}
