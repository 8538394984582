import React, { useState } from "react";
import {
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { UserModel } from "db/models/users/UserModel";
import { toNamedColor } from "helpers/monthlyDose";

interface UserTableProps {
  users: UserModel[];
  onUserSelect: (selectedUsers: UserModel[]) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, onUserSelect }) => {
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);

  const handleUserSelect = (user: UserModel) => {
    setSelectedUsers((prevSelectedUsers: UserModel[]) => {
      const isSelected: boolean = prevSelectedUsers.some(
        (selectedUser: UserModel) => selectedUser.id === user.id
      );
      const updatedUsers: UserModel[] = isSelected
        ? prevSelectedUsers.filter((selectedUser: UserModel) => selectedUser.id !== user.id)
        : [...prevSelectedUsers, user];

      onUserSelect(updatedUsers); // Call onUserSelect with the updated users
      return updatedUsers;
    });
  };

  return (
    <>
      <Typography variant="h5">Personal</Typography>
      <Table>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.id}
              className="user-row"
              data-testid={`user-row-${user.id}`} // Add data-testid for rows
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                  onChange={() => handleUserSelect(user)}
                  data-testid={`user-checkbox-${user.id}`} // Add data-testid for checkboxes
                />
              </TableCell>
              <TableCell>{user.firstName + " " + user.lastName}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell align="right">
                <Chip
                  label={`${user.exposurePercentage}%`}
                  color={toNamedColor(user.exposurePercentage)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default UserTable;
