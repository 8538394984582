import { InviteDbModel } from "db/models/invites/inviteDbModel";
import { FirestoreDb } from "../../firebase";
import { doc, collection, addDoc, deleteDoc, writeBatch } from "firebase/firestore"; // Import necessary Firestore modules
import { sendInviteEmail } from "db/email/emailService";
import { mapInviteToEmailModel } from "db/email/emailMapper";

// Initialize Firestore
const db = FirestoreDb();

// Collection name
const COLLECTION_NAME = "invites";

export const createInvite = async (
  invite: InviteDbModel,
  customerId: string
): Promise<InviteDbModel> => {
  try {
    // Create a document reference for the customer
    const customerRef = doc(db, "customers", customerId);

    // Add the customer reference to the invite data
    const inviteDataWithCustomerRef = {
      ...invite,
      customer: customerRef,
      customerId: customerId,
    };

    const { id: inviteId, ...inviteDataWithoutId } = inviteDataWithCustomerRef;

    // Add the invite to the collection
    const docRef = await addDoc(collection(db, COLLECTION_NAME), inviteDataWithoutId);

    // Send an email to the user that he can sign up using credentials.
    const email = mapInviteToEmailModel(inviteDataWithCustomerRef);
    await sendInviteEmail(email);

    // Return the created project with the generated ID
    return {
      id: docRef.id,
      ...invite,
    } as InviteDbModel;
  } catch (error) {
    console.error("Error creating invite:", error);
    throw error;
  }
};

export const batchSaveInvites = async (invites: InviteDbModel[]): Promise<void> => {
  try {
    // Start a Firestore batch
    const batch = writeBatch(db);

    // Add all invites to the batch
    invites.forEach((invite) => {
      const { id: inviteId, ...inviteDataWithoutId } = invite;
      // Create a document reference for the invite
      const inviteRef = doc(collection(db, COLLECTION_NAME));

      // Add invite data to the batch
      batch.set(inviteRef, {
        ...inviteDataWithoutId,
        customer: doc(db, "customers", invite.customerId), // Reference to the customer
      });
    });

    // Commit the batch operation
    await batch.commit();
    console.log("All invites saved successfully.");

    // Send invite emails after successful batch save
    await Promise.all(
      invites.map(async (invite) => {
        const email = mapInviteToEmailModel(invite);
        await sendInviteEmail(email);
      })
    );
    console.log("All invite emails sent successfully.");
  } catch (error) {
    console.error("Error saving invites in batch:", error);
    throw error;
  }
};

// delete the project
export const remove = async (id: string) => {
  await deleteDoc(doc(db, COLLECTION_NAME, id));
};
