import React, { useContext, useState } from "react";
import getTableData from "./data/dataTableData";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import IndexLayoutWithOutAddNew from "layouts/baseComponents/indexLayoutWithoutAddNew";
import ObjectsContext from "context/ObjetcsContext";
import { WorkObject } from "db/models/workObject";
import ObjectDetailsDialog from "./components/objectsDialogComponent";

function CustomerObjects(): JSX.Element {
  const { objects } = useContext(ObjectsContext);
  const [selectedObject, setSelectedObject] = useState<WorkObject | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Set the selected object and open the dialog if found
  const handleRowClick = (objectId: string) => {
    // Find the selected object using its ID
    const foundObject = objects.find((obj) => obj.id === objectId);

    // Set the selected object and open the dialog if found
    if (foundObject) {
      setSelectedObject(foundObject);
      setDialogOpen(true);
    }
  };

  // Handle dialog close
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedObject(undefined);
  };

  return (
    <IndexLayoutWithOutAddNew title="Objekt">
      <DataTableCard
        data={getTableData(objects)}
        onRowClick={handleRowClick}
        showHoverStyle={true}
      />

      {/* Dialog for Object Details */}
      <ObjectDetailsDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        workObject={selectedObject}
      />
    </IndexLayoutWithOutAddNew>
  );
}

export default CustomerObjects;
