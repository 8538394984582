import React, { useState, useEffect, useContext } from "react";
import { Grid, Card, Typography, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import CustomerDropDown from "./components/customerDropDown";
import { Customer } from "db/models/Customer";
import { Timestamp } from "firebase/firestore";
import { create, getById, remove, update } from "db/repositories/objectsRepository";
import { WorkObject } from "db/models/workObject";
import { useNavigate, useParams } from "react-router-dom";
import { ActionPlan } from "db/models/ActionPlan";
import { IconButton } from "@mui/material"; // Import IconButton from MUI
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon from MUI
import { v4 as uuidv4 } from "uuid";
import SuperAdminObjectsContext from "context/SuperAdminObjectsProvider";

interface FormValues extends WorkObject {}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  code: Yup.string().required("Required"),
  avaliableFrom: Yup.date().required("Required"),
  availableTo: Yup.date(),
  customer: Yup.object()
    .shape({
      name: Yup.string(),
    })
    .nullable(),
});

interface CreateObjectProps {
  mode: "create" | "edit";
  object?: string; // For edit mode, provide the customer object to edit
}

const now = Timestamp.now();

const CreateObject: React.FC<CreateObjectProps> = ({ mode, object }) => {
  const navigate = useNavigate();

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { allObjects } = useContext(SuperAdminObjectsContext);

  const [originalActionPlans, setOriginalActionPlans] = useState<ActionPlan[]>([]);

  const initialValues: FormValues = {
    id: "",
    avaliableFrom: now,
    avaliableTo: null,
    code: "",
    customer: null,
    name: "",
    actionPlans: [] as ActionPlan[], // Initialize actionPlans as an empty array
  };

  const haveActionPlansChanged = () => {
    // Check if any action plan's radiationBqhPerM3 has changed or measureDate is before today
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for comparison

    return formik.values.actionPlans.some((plan, index) => {
      const originalPlan = originalActionPlans.find((op) => op.id === plan.id);
      const measureDate = plan.measureDate ? plan.measureDate.toDate() : null;
      return (
        (originalPlan && originalPlan.radiationLevel !== plan.radiationLevel) ||
        (measureDate && measureDate < today)
      );
    });
  };

  const handleSubmitWithWarning = async () => {
    if (haveActionPlansChanged()) {
      // Show warning to the user
      if (
        window.confirm(
          "Om du efterregistrerar ett mätvärde till en tid före nu så kommer alla tidigare arbetspass registrerade på åtgärdsplanen att få ett annat dosvärde. Detta kan påverka redan framtagna rapporter och kommer att ändra statistiken för alla som arbetat på objektets åtgärdsplan."
        )
      ) {
        // User clicked "Ok", proceed with form submission
        formik.handleSubmit();
      }
    } else {
      // No significant changes, proceed with form submission
      formik.handleSubmit();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (!selectedCustomer) {
          throw new Error("You have to select a customer");
        }
        values.customer = selectedCustomer;

        const actionPlansWithCorrectedDates = values.actionPlans.map((plan) => ({
          ...plan,
          measureDate: plan.measureDate || null,
        }));

        const valuesWithCorrectedDates = { ...values, actionPlans: actionPlansWithCorrectedDates };

        if (mode === "create") {
          const createdObject = await create(valuesWithCorrectedDates);
          console.log("Object created:", createdObject);
        } else if (mode === "edit") {
          const updatedObject = await update(objectId, valuesWithCorrectedDates);
          console.log("Object updated:", updatedObject);
        }
        navigate("/superadmin/objects");
      } catch (error) {
        alert("Error: " + error);
      }
    },
  });

  const { objectId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (mode === "edit" && objectId) {
          const objectData = await getById(objectId);
          setSelectedCustomer(objectData.customer);
          setOriginalActionPlans(objectData.actionPlans || []);
          formik.setValues((prevValues) => ({
            ...prevValues,
            ...objectData,
          }));
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching object data:", error);
      }
    };
    fetchData();
  }, [mode, objectId]);

  const handleDelete = async () => {
    const confirmation = window.confirm("Are you sure you want to delete this object?");
    if (confirmation) {
      try {
        await remove(objectId);
        navigate("/superadmin/objects");
      } catch (error) {
        console.error("Error deleting object:", error);
        alert("Error deleting object");
      }
    }
  };

  // Function to convert a Timestamp to a string in the datetime-local format
  const timestampToString = (timestamp: Timestamp): string => {
    if (timestamp && typeof timestamp.toDate === "function") {
      const date = timestamp.toDate();
      const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
      const localTime = new Date(date.getTime() - timezoneOffset);
      return localTime.toISOString().slice(0, 16); // Convert to YYYY-MM-DDTHH:MM format
    } else {
      console.error("Invalid timestamp provided to timestampToString.");
      return ""; // Return an empty string or some default value
    }
  };

  // Function to convert a string in the datetime-local format to a Timestamp
  const stringToTimestamp = (dateString: string): Timestamp | null => {
    // Regular expression to validate the dateString format
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    if (regex.test(dateString)) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        // Check if date is valid
        return Timestamp.fromDate(date);
      } else {
        console.error("Invalid date string provided to stringToTimestamp.");
      }
    } else {
      console.error("Date string is not in the correct format.");
    }
    return null; // Return null or handle the error appropriately
  };

  // Function to add or update an action plan in the array
  const addActionPlan = (newActionPlan: ActionPlan) => {
    const existingIndex = formik.values.actionPlans.findIndex(
      (plan) => plan.id === newActionPlan.id
    );
    if (existingIndex !== -1) {
      const updatedActionPlans = [...formik.values.actionPlans];
      updatedActionPlans[existingIndex] = newActionPlan;
      formik.setFieldValue("actionPlans", updatedActionPlans);
    } else {
      formik.setFieldValue("actionPlans", [...formik.values.actionPlans, newActionPlan]);
    }
  };

  // Function to remove an action plan from the array
  const removeActionPlan = (index: number) => {
    const updatedActionPlans = [...formik.values.actionPlans];
    updatedActionPlans.splice(index, 1);
    formik.setFieldValue("actionPlans", updatedActionPlans);
  };

  // Function to parse radiation value
  const parseRadiationValue = (value: string): number => {
    // Regular expression to match valid floating-point numbers
    const regex = /^-?\d*\.?\d*$/;
    // Check if the input matches the regular expression for floating-point numbers
    if (regex.test(value)) {
      // Parse the input value as a float
      return parseFloat(value);
    } else {
      // If the input is invalid, return 0
      return 0;
    }
  };

  return (
    <DashboardLayout>
      <Box p={4}>
        <Card>
          <Box p={3}>
            <Typography variant="h5">{mode === "create" ? "Create" : "Edit"} Object</Typography>
          </Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitWithWarning();
            }}
          >
            <Box p={3}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Object Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Code"
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="datetime-local"
                    label="Available From"
                    name="avaliableFrom"
                    value={formik.values.avaliableFrom}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.avaliableFrom && Boolean(formik.errors.avaliableFrom)}
                    helperText={
                      formik.touched.avaliableFrom &&
                      typeof formik.errors.avaliableFrom === "string" && // Check if error is a string
                      formik.errors.avaliableFrom === "string" && // Check if it's a string
                      formik.errors.avaliableFrom // Render the error message
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: { position: "absolute", top: "-10px" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="datetime-local"
                    label="Available To"
                    name="avaliableTo"
                    value={formik.values.avaliableTo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.avaliableTo && Boolean(formik.errors.avaliableTo)}
                    helperText={
                      formik.touched.avaliableTo &&
                      formik.errors.avaliableTo && // Check if error exists
                      typeof formik.errors.avaliableTo === "string" && // Check if it's a string
                      formik.errors.avaliableTo // Render the error message
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: { position: "absolute", top: "-10px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {(mode === "create" || (!loading && mode === "edit")) && ( // Corrected condition
                    <CustomerDropDown
                      setCustomer={setSelectedCustomer}
                      preSelectedCustomer={selectedCustomer}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Action Plans</Typography>
                  {formik.values.actionPlans.map((actionPlan, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={1} mt={1}>
                      <Box mr={1}>
                        <TextField
                          value={actionPlan.name}
                          onChange={(e) => {
                            const newName = e.target.value;
                            addActionPlan({ ...actionPlan, name: newName });
                          }}
                          variant="outlined"
                          label="Name"
                        />
                      </Box>
                      <Box mr={1}>
                        {" "}
                        {/* Wrap both TextFields in a single Box to align them horizontally */}
                        <TextField
                          value={actionPlan.radiationLevel.toString()} // Use toString() to handle the number input as string
                          onChange={(e) => {
                            const newValue = e.target.value;
                            addActionPlan({
                              ...actionPlan,
                              radiationLevel: parseFloat(newValue),
                            });
                          }}
                          variant="outlined"
                          label="RadiationBqhPerM3"
                          type="number"
                        />
                      </Box>
                      <Box mr={1}>
                        {" "}
                        {/* Separate Box for the Measure Date TextField */}
                        <TextField
                          type="datetime-local"
                          label="Measure Date"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={
                            actionPlan.measureDate ? timestampToString(actionPlan.measureDate) : ""
                          }
                          onChange={(e) => {
                            const newMeasureDate = stringToTimestamp(e.target.value);
                            addActionPlan({ ...actionPlan, measureDate: newMeasureDate });
                          }}
                        />
                      </Box>
                      <IconButton onClick={() => removeActionPlan(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <MDButton
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      addActionPlan({
                        id: uuidv4(),
                        name: "New Action Plan",
                        radiationLevel: 0,
                        measureDate: Timestamp.fromDate(new Date()),
                      })
                    }
                  >
                    Add Action Plan
                  </MDButton>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Box paddingLeft={4} paddingBottom={1} paddingTop={4}>
                      <MDButton variant="gradient" color="dark" type="submit">
                        Submit
                      </MDButton>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box paddingLeft={1} paddingBottom={1} paddingTop={4}>
                      <MDButton
                        variant="outlined"
                        color="error"
                        type="button"
                        onClick={handleDelete}
                      >
                        Delete
                      </MDButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Card>
      </Box>
    </DashboardLayout>
  );
};

export default CreateObject;
