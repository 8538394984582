import { Timestamp, getDoc } from "firebase/firestore";
import { MeasuredObject } from "./MeaseuredObject";
import { Customer } from "./Customer";
import { ActionPlan } from "./ActionPlan";

export class WorkObject {
  id: string;
  avaliableFrom: Timestamp;
  avaliableTo: Timestamp;
  code: string;
  customer: Customer;
  name: string;
  actionPlans: Array<ActionPlan>;

  constructor(measuredObject: MeasuredObject, customer: Customer) {
    this.id = measuredObject.id;
    this.name = measuredObject.name;
    this.avaliableFrom = measuredObject.avaliableFrom;
    this.avaliableTo = measuredObject.avaliableTo;
    this.code = measuredObject.code;
    this.customer = customer;
    this.actionPlans = measuredObject.actionPlans;
  }
}
