import { useEffect, useState } from "react";
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { UserDbModel } from "db/models/users/user";
import { FirestoreDb } from "../../../../../firebase";

const db = FirestoreDb();

export const useExistingUserData = (customerId: string | null) => {
  const [existingEmailAdresses, setExistingEmailAdresses] = useState<string[]>([]);
  const [existingPersonalSecurityNumbers, setExistingPersonalSecurityNumbers] = useState<string[]>(
    []
  );

  useEffect(() => {
    let unsubscribe = () => {};

    if (customerId) {
      const q = query(
        collection(db, "users"),
        where("customerId", "==", customerId),
        orderBy("firstName", "asc")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedUsers: UserDbModel[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as UserDbModel[];
        setExistingEmailAdresses(fetchedUsers.map((user) => user.email));
        setExistingPersonalSecurityNumbers(fetchedUsers.map((user) => user.personalSecurityNumber));
      });
    } else {
      setExistingEmailAdresses([]);
      setExistingPersonalSecurityNumbers([]);
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [customerId]);

  return { existingEmailAdresses, existingPersonalSecurityNumbers };
};
