import { FirestoreDb } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import { User } from "firebase/auth";

async function isUserSuperAdmin(currentUser: User | null): Promise<boolean> {
  if (!currentUser) {
    return false; // If user is not logged in, they cannot be a superadmin
  }

  const db = FirestoreDb();

  try {
    const userId = currentUser.uid;
    const superAdminDoc = await getDoc(doc(db, "superAdmins", userId));
    return superAdminDoc.exists();
  } catch (error) {
    console.error("Error checking super admin status:", error);
    return false;
  }
}

export default isUserSuperAdmin;
