import { format, isValid } from "date-fns";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";

interface ShiftRow {
  isSelected: boolean;
  id: string;
  user: string;
  object: string;
  project: string;
  plannedStartTime: string;
  plannedEndTime: string;
  estimatedWorkMinutes: number;
  exposure: string;
}

interface TableData {
  columns: Array<{
    Header: string;
    accessor: string;
    width?: string | number;
    align?: string;
    Cell?: (props: any) => JSX.Element;
  }>;
  rows: ShiftRow[];
}

interface SelectedShiftsParam {
  id: string; // or any unique identifier type
}

const getTableData = (
  shifts: PlannedShift[],
  selectedShifts: SelectedShiftsParam[] = [], // Default to empty array if not provided
  showCheckboxes: boolean = false, // Default to false if not provided
  handleSelectShift: (id: string) => void = () => {} // Default to no-op if not provided
): TableData => {
  const columns = [
    showCheckboxes
      ? {
          Header: "",
          accessor: "select",
          width: 50, // Set a small width to fit only the checkbox
          Cell: ({ row }: { row: { original: ShiftRow } }) => (
            <input
              type="checkbox"
              checked={row.original.isSelected}
              onChange={() => handleSelectShift(row.original.id)} // Use handleSelectShift function passed from PlannedShifts
              onClick={(e) => e.stopPropagation()} // Stop row click event when clicking the checkbox
            />
          ),
        }
      : null,
    { Header: "ID", accessor: "id" },
    { Header: "Person", accessor: "user" },
    { Header: "Objekt", accessor: "object" },
    { Header: "Projekt", accessor: "project" },
    { Header: "Fr.o.m", accessor: "plannedStartTime" },
    { Header: "T.o.m", accessor: "plannedEndTime" },
    { Header: "Tid (min)", accessor: "estimatedWorkMinutes", align: "right" },
    { Header: "Exponering (Bqh/m³)", accessor: "exposure", align: "right" },
  ].filter(Boolean); // Filter out null values in case checkboxes are disabled

  const dataTableData: TableData = {
    columns,
    rows: shifts.map((shift) => ({
      isSelected: selectedShifts.some((selected) => selected.id === shift.id), // Check if the shift is selected
      id: shift.id,
      user: shift.user.name,
      plannedStartTime: isValid(shift.plannedStartTime)
        ? format(shift.plannedStartTime, "yyyy-MM-dd HH:mm:ss")
        : "",
      plannedEndTime: isValid(shift.plannedEndTime)
        ? format(shift.plannedEndTime, "yyyy-MM-dd HH:mm:ss")
        : "",
      estimatedWorkMinutes: shift.estimatedWorkMinutes,
      project: shift.project?.name ?? "",
      object: shift.object?.name ?? "",
      exposure: shift.radiationDose.toLocaleString("sv-SE", { minimumFractionDigits: 3 }),
    })),
  };

  return dataTableData;
};

export default getTableData;
