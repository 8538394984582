import React, { ReactNode } from "react";
import { Box, Grid } from "@mui/material";
import WorkObjectDropDown from "./objectDropDown";
import ActionPlanDropDown from "./actionPlanDropDown";
import UserTable from "./selectableUserTable";
import { WorkObject } from "db/models/workObject";
import { ActionPlan } from "db/models/ActionPlan";
import { Project } from "db/models/Project";
import ProjectDropDown from "./projectDropDown";
import MDButton from "components/MDButton";
import { UserModel } from "db/models/users/UserModel";
import { useNavigate } from "react-router-dom";

interface BaseShiftFormProps {
  selectedWorkObject: WorkObject | null;
  setSelectedWorkObject: React.Dispatch<React.SetStateAction<WorkObject | null>>;
  selectedActionPlan: ActionPlan | null;
  setSelectedActionPlan: React.Dispatch<React.SetStateAction<ActionPlan | null>>;
  selectedProject: Project | null;
  setSelectedProject: React.Dispatch<React.SetStateAction<Project | null>>;
  selectedUsers: UserModel[];
  handleUserSelect: (selectedUsers: UserModel[]) => void;
  users: UserModel[];
  isSubmitted: boolean;
  currentShift: {
    object: { id: string };
    project: { id: string };
    actionPlan: { id: string };
  } | null;
  customerId: string;
  children: ReactNode;
  isSaveDisabled: boolean;
  isSaving: boolean;
  isPlannedShift: boolean;
}

const BaseShiftForm: React.FC<BaseShiftFormProps> = ({
  selectedWorkObject,
  setSelectedWorkObject,
  selectedActionPlan,
  setSelectedActionPlan,
  selectedProject,
  setSelectedProject,
  selectedUsers,
  handleUserSelect,
  users,
  isSubmitted,
  customerId,
  children,
  isSaveDisabled,
  isSaving,
  isPlannedShift,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <WorkObjectDropDown setWorkObject={setSelectedWorkObject} />
        {!selectedWorkObject && isSubmitted && (
          <div data-testid="choose-object" style={{ color: "red" }}>
            Vänligen välj objekt
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <ActionPlanDropDown
          data-testid="choose-action-plan"
          setActionPlan={setSelectedActionPlan}
          workObject={selectedWorkObject}
        />
        {!selectedActionPlan && isSubmitted && (
          <div style={{ color: "red" }}>Vänligen välj åtgärsplan</div>
        )}
      </Grid>
      <Grid item xs={12}>
        <ProjectDropDown data-testid="choose-project" setProject={setSelectedProject} />
        {!selectedProject && isSubmitted && (
          <div style={{ color: "red" }}>Vänligen välj projekt</div>
        )}
      </Grid>
      {children}
      <Grid item xs={12}>
        <UserTable users={users} onUserSelect={handleUserSelect} />
        {(!selectedUsers || selectedUsers.length == 0) && isSubmitted && (
          <>
            <br></br>
            <div style={{ color: "red" }}>Vänligen välj personal</div>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <MDButton
            id="save-workshift"
            variant="gradient"
            color="dark"
            type="submit"
            disabled={isSaveDisabled || isSaving} // Disable if saving or already disabled
          >
            {isSaving ? "Sparar..." : "Spara"}
          </MDButton>
          <MDButton
            id="abort-add-workshift"
            variant="gradient"
            color="secondary" // Use the 'error' color to make the button red
            onClick={() => {
              navigate(isPlannedShift ? "/workshifts/plannedShifts" : "/workshifts/doneshifts");
            }} // Open the dialog on click
          >
            Avbryt
          </MDButton>
        </Box>
      </Grid>
    </>
  );
};

export default BaseShiftForm;
