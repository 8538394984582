import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

interface OverviewProps {
  title: string;
  subtitle: string;
}

const Overview: React.FC<OverviewProps> = ({ title, subtitle }) => (
  <>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Översikt: {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
    <Divider />
  </>
);

export default Overview;
