import { MeasuredObject } from "db/models/MeaseuredObject";
import { FirestoreDb } from "../../firebase";

import {
  setDoc,
  doc,
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  DocumentReference,
  getDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { Customer } from "db/models/Customer";
import { WorkObject } from "db/models/workObject";
import { ActionPlan } from "db/models/ActionPlan";

// import firestore
const db = FirestoreDb();

// collection name
const COLLECTION_NAME = "objects";

export const getAll = async (): Promise<Array<WorkObject>> => {
  try {
    const objectsRef = collection(db, COLLECTION_NAME);

    const snapshot = await getDocs(objectsRef);

    const data: Array<WorkObject> = [];

    // Use Promise.all to wait for all asynchronous operations to complete
    await Promise.all(
      snapshot.docs.map(async (doc) => {
        const meash = {
          id: doc.id,
          ...doc.data(),
        } as MeasuredObject;

        const customer = await fetchCustomer(meash.customer);

        // Create a new WorkObject instance with the fetched data
        const workObject = new WorkObject(meash, customer);

        data.push(workObject);
      })
    );

    return data;
  } catch (error) {
    console.error("Error fetching measured objects:", error);
    throw error;
  }
};

const fetchCustomer = async (customerRef: DocumentReference): Promise<Customer | null> => {
  try {
    const customerDoc = await getDoc(customerRef);
    if (customerDoc.exists()) {
      return customerDoc.data() as Customer;
    } else {
      console.log("Customer document not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching customer:", error);
    return null;
  }
};

export const batchSaveObjects = async (objects: Array<WorkObject>) => {
  try {
    // Start a Firestore batch
    const batch = writeBatch(db);

    // Iterate over previewData to add each object to the batch
    objects.forEach((obj) => {
      // Create a Firestore document reference for the customer
      const customerRef = doc(db, "customers", obj.customer.id);

      // Prepare the object data for Firestore
      const measuredObject = {
        avaliableFrom: obj.avaliableFrom,
        avaliableTo: obj.avaliableTo,
        code: obj.code,
        customer: customerRef,
        customerId: obj.customer.id,
        name: obj.name,
        actionPlans: obj.actionPlans,
      };

      // Add the object to the batch
      const objectRef = doc(collection(db, COLLECTION_NAME));
      batch.set(objectRef, measuredObject);
    });

    // Commit the batch
    await batch.commit();

    console.log("All objects saved successfully.");
  } catch (error) {
    console.error("Error saving objects:", error);
  }
};

export const create = async (workObject: WorkObject): Promise<WorkObject> => {
  try {
    // Convert customer object to DocumentReference
    const customerRef = doc(db, "customers", workObject.customer.id);

    // Convert avaliableFrom to a Firebase Timestamp if necessary
    const avaliableFromTimestamp =
      workObject.avaliableFrom instanceof Timestamp
        ? workObject.avaliableFrom
        : Timestamp.fromDate(new Date(workObject.avaliableFrom));

    // Convert avaliableTo to a Firebase Timestamp if necessary
    const avaliableToTimestamp =
      workObject.avaliableTo instanceof Timestamp
        ? workObject.avaliableTo
        : workObject.avaliableTo
        ? Timestamp.fromDate(new Date(workObject.avaliableTo))
        : null;

    // Create a MeasuredObject instance with the relevant data
    const measuredObject: Omit<MeasuredObject, "id"> = {
      avaliableFrom: avaliableFromTimestamp,
      avaliableTo: avaliableToTimestamp,
      code: workObject.code,
      customer: customerRef,
      customerId: workObject.customer.id,
      name: workObject.name,
      actionPlans: workObject.actionPlans,
    };

    // Add a new document to the specified collection with the provided data
    await addDoc(collection(db, COLLECTION_NAME), measuredObject);

    // Return the original WorkObject
    return workObject;
  } catch (error) {
    console.error("Error creating object:", error);
    throw error;
  }
};

// update object
export const update = async (objectId: string, updatedObject: WorkObject): Promise<WorkObject> => {
  try {
    // Convert customer object to DocumentReference if it exists
    const customerRef = updatedObject.customer
      ? doc(db, "customers", updatedObject.customer.id)
      : null;

    // Convert avaliableFrom to a Firebase Timestamp if necessary
    const avaliableFromTimestamp =
      updatedObject.avaliableFrom instanceof Timestamp
        ? updatedObject.avaliableFrom
        : Timestamp.fromDate(new Date(updatedObject.avaliableFrom));

    // Convert avaliableTo to a Firebase Timestamp if necessary
    const avaliableToTimestamp =
      updatedObject.avaliableTo instanceof Timestamp
        ? updatedObject.avaliableTo
        : updatedObject.avaliableTo
        ? Timestamp.fromDate(new Date(updatedObject.avaliableTo))
        : null;

    // Update the main object data
    const updatedData: Partial<MeasuredObject> = {
      avaliableFrom: avaliableFromTimestamp,
      avaliableTo: avaliableToTimestamp,
      code: updatedObject.code,
      customer: customerRef, // Assign the customer reference if it exists
      customerId: updatedObject.customer.id,
      name: updatedObject.name,
      actionPlans: updatedObject.actionPlans,
    };

    // Update the main object document
    await updateDoc(doc(db, COLLECTION_NAME, objectId), updatedData);

    // Return the updated WorkObject
    return { ...updatedObject, id: objectId };
  } catch (error) {
    console.error("Error updating object:", error);
    throw error;
  }
};

export const getById = async (objectId: string): Promise<WorkObject | null> => {
  try {
    // Retrieve the document snapshot using the object ID
    const docSnapshot = await getDoc(doc(db, COLLECTION_NAME, objectId));

    // Check if the document exists
    if (docSnapshot.exists()) {
      // Extract the data from the snapshot
      const data = docSnapshot.data();

      // Get the customer reference from the data
      const customerRef = data.customer;

      // Fetch the customer document
      const customerSnapshot = await getDoc(customerRef);

      // Check if the customer document exists
      if (customerSnapshot.exists()) {
        // Extract the customer data
        const customerData: Customer = {
          id: customerSnapshot.id,
          ...(customerSnapshot.data() as Omit<Customer, "id">),
        };

        // Convert timestamps to formatted dates
        const formattedAvaliableFrom = data.avaliableFrom.toDate().toISOString().slice(0, 16); // Example format: "2022-02-22T12:00"
        const formattedAvaliableTo = data.avaliableTo
          ? data.avaliableTo.toDate().toISOString().slice(0, 16)
          : null; // Example format: "2022-02-22T12:00"

        // Combine the object data with the customer data, including formatted dates, and return
        return {
          ...data,
          id: objectId,
          customer: customerData,
          avaliableFrom: formattedAvaliableFrom,
          avaliableTo: formattedAvaliableTo,
        } as WorkObject;
      } else {
        // If the customer document does not exist, return null
        return null;
      }
    } else {
      // If the document does not exist, return null
      return null;
    }
  } catch (error) {
    console.error("Error fetching object:", error);
    throw error;
  }
};

// delete the object
export const remove = async (id: string) => {
  await deleteDoc(doc(db, COLLECTION_NAME, id));
};
