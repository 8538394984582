import { ActionPlanModel } from "db/models/dataDuplicationModels/ActionPlanModel";
import { ObjectModel } from "db/models/dataDuplicationModels/ObjectModel";
import { ProjectModel } from "db/models/dataDuplicationModels/ProjectModel";

// Define static UUIDs for the dummy data
export const DummyProject: ProjectModel = {
  id: "dummyProject", // Predefined static UUID
  name: "Projekt för manuell dos",
};

export const DummyObject: ObjectModel = {
  id: "dummyObject", // Predefined static UUID
  name: "Objekt för manuell dos",
};

export const DummyActionPlan: ActionPlanModel = {
  id: "dummyAction", // Predefined static UUID
  name: "Åtgärdsplan för manuell dos",
  radiationLevel: 100000,
};
