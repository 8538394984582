import { FirestoreDb } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

export interface UserData {
  customerId: string;
  firstName: string;
  lastName: string;
}

async function getUserData(userId: string): Promise<UserData | null> {
  const db = FirestoreDb();

  try {
    const userDoc = await getDoc(doc(db, "admins", userId));
    if (userDoc.exists()) {
      // Return user data if the document exists
      return userDoc.data() as UserData;
    } else {
      return null; // Return null if the document doesn't exist
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
}

export default getUserData;
