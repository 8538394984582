import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon
import MailOutlineIcon from "@mui/icons-material/MailOutline"; // Import the email icon

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { createInvite, remove } from "db/repositories/invitesRepository"; // Adjust the import paths as necessary
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import { AuthContext } from "context/auth-context";
import MDButton from "components/MDButton";
import { InviteModel } from "db/models/invites/inviteModel";
import { convertModelToDbModel } from "db/models/invites/inviteMapper";
import DateSelection from "layouts/workshifts/helpers/dateSelectionComponent";
import InvitesContext from "context/InvitesContext";
import UsersContext from "context/UsersContext";

interface FormValues extends InviteModel {}

interface CreateEditInviteProps {
  mode: "create" | "edit";
}

Yup.addMethod(Yup.string, "swedishPersonalNumber", function (message) {
  return this.test("swedishPersonalNumber", message, function (value) {
    const { path, createError } = this;

    // Function to validate the Swedish personal number format and checksum
    const isValidSwedishPersonalNumber = (number: string) => {
      // Regular expression to check the format YYYYMMDD-XXXX
      const formatRegex = /^\d{8}-\d{4}$/;

      // First, check if the number matches the required format
      if (!formatRegex.test(number)) {
        return false;
      }

      // Remove any non-digit characters (e.g., '-') for the Luhn algorithm validation
      const cleanNumber = number.replace(/\D/g, "");

      // Basic length check is now redundant due to the format check, but left here for clarity
      if (cleanNumber.length !== 10 && cleanNumber.length !== 12) {
        return false;
      }

      // Implement the Luhn algorithm (or modulo-10 algorithm) for the last 10 digits
      const numToCheck = cleanNumber.length === 12 ? cleanNumber.substring(2) : cleanNumber;
      let sum = 0;
      for (let i = 0; i < 10; i++) {
        let digit = parseInt(numToCheck.charAt(i));
        if (i % 2 === 0) {
          // Multiply every other digit by 2
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        sum += digit;
      }

      return sum % 10 === 0;
    };

    // Check if the personal number is valid
    const isValid = value && isValidSwedishPersonalNumber(value);
    return isValid || createError({ path, message });
  });
});

declare module "yup" {
  // Extend the StringSchema interface
  interface StringSchema {
    swedishPersonalNumber(message: string): StringSchema;
  }
}

const CreateEditInvite: React.FC<CreateEditInviteProps> = ({ mode }) => {
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [existingEmailAdresses, setExistingEmailAdresses] = useState<string[]>([]);
  const [existingPersonalSecurityNumbers, SetExistingPersonalSecurityNumbers] = useState<string[]>(
    []
  );
  const { invites } = useContext(InvitesContext);
  const { users } = useContext(UsersContext);

  const getValidationSchema = () => {
    return Yup.object({
      firstName: Yup.string().required("Förnamn är obligatoriskt"),
      lastName: Yup.string().required("Efternamn är obligatoriskt"),
      role: Yup.string().required("Roll är obligatorisk"),
      email: Yup.string()
        .email("Ogiltig e-postadress")
        .required("E-post är obligatorisk")
        .notOneOf(existingEmailAdresses, "E-postadressen finns redan"),
      personalSecurityNumber: Yup.string()
        .required("Personnummer är obligatoriskt")
        .swedishPersonalNumber("Ogiltigt personnummer")
        .notOneOf(existingPersonalSecurityNumbers, "Personnumret finns redan"),
    });
  };

  const validationSchema = getValidationSchema();

  const initialValues: FormValues = {
    // Initialize with default values or empty strings
    id: "",
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    personalSecurityNumber: "",
    employedFrom: new Date(),
    employedTo: new Date(),
    avaliableTo: new Date(
      new Date().getFullYear() + 1,
      new Date().getMonth(),
      new Date().getDate()
    ),
    acceptedAt: null,
    declinedAt: null,
    code: Math.random().toString().slice(2, 11),
    customerId: "",
    startRadiationDoseBqh: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Convert form values to InviteDbModel structure before saving
        const inviteDbModel = convertModelToDbModel(values);

        if (mode === "create") {
          await createInvite(inviteDbModel, userData.customerId);
          console.log("Invite created");
        }
        navigate("/employees/invites");
      } catch (error) {
        alert("Error saving invite: " + error);
      }
    },
  });

  useEffect(() => {
    const fetchExistingUsers = async () => {
      const existingUserEmailAdresses = users.map((usr) => {
        return usr.email;
      });
      const existingUsersPersonalSecurityNumbers = users.map((usr) => {
        return usr.personalSecurityNumber;
      });

      setExistingEmailAdresses(existingUserEmailAdresses);
      SetExistingPersonalSecurityNumbers(existingUsersPersonalSecurityNumbers);
    };

    fetchExistingUsers();
  }, [userData.customerId]);

  const { inviteId } = useParams();

  useEffect(() => {
    if (mode === "edit" && inviteId && invites.length > 0) {
      const filteredInvite = invites.find((invite) => invite.id === inviteId);
      if (filteredInvite) {
        formik.setValues(filteredInvite as FormValues);
      }
    }
  }, [mode, inviteId, invites]);

  async function deleteInvite(): Promise<void> {
    // Close the dialog
    setOpenDialog(false);

    if (!formik.values) return;

    try {
      // Call your API to delete the shift
      await remove(formik.values.id); // Replace with your actual API call
      navigate("/employees/invites"); // Navigate away after deletion
    } catch (error) {
      alert("Error deleting shift: " + error);
    }
  }

  return (
    <>
      <BaseCreateEdit
        title={mode === "create" ? "Skicka inbjudan" : "Redigera inbjudan"}
        onSubmit={formik.handleSubmit}
      >
        {/* First Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="Förnamn"
            disabled={mode === "edit"}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Efternamn"
            disabled={mode === "edit"}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>

        {/* Personal Security Number */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="personalSecurityNumber"
            name="personalSecurityNumber"
            label="Personnummer"
            disabled={mode === "edit"}
            value={formik.values.personalSecurityNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personalSecurityNumber && Boolean(formik.errors.personalSecurityNumber)
            }
            helperText={
              formik.touched.personalSecurityNumber && formik.errors.personalSecurityNumber
            }
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-post"
            type="email"
            disabled={mode === "edit"}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>

        {/* Role */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="role"
            name="role"
            label="Befattning"
            disabled={mode === "edit"}
            value={formik.values.role}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.role && Boolean(formik.errors.role)}
            helperText={formik.touched.role && formik.errors.role}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="startRadiationDoseBqh"
            name="startRadiationDoseBqh"
            label="Startdos Bqh"
            type="number"
            value={formik.values.startRadiationDoseBqh}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.startRadiationDoseBqh && Boolean(formik.errors.startRadiationDoseBqh)
            }
            helperText={formik.touched.startRadiationDoseBqh && formik.errors.startRadiationDoseBqh}
          />
        </Grid>

        {/* For the Date Fields, use your DateSelection component as provided in the question */}
        {/* Employed From */}
        {mode === "create" && (
          <>
            <Grid item xs={12} sm={6}>
              <DateSelection
                fullWidth
                label="Anställd Från"
                name="employedFrom"
                value={formik.values.employedFrom}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.employedFrom && Boolean(formik.errors.employedFrom)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateSelection
                fullWidth
                label="Anställd Till"
                name="employedTo"
                value={formik.values.employedTo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.employedTo && Boolean(formik.errors.employedTo)}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* "Save" button aligned to the left */}
            <Grid item xs={6} display="flex" justifyContent="flex-start">
              <MDButton
                variant="gradient"
                color="dark"
                type="submit"
                startIcon={<MailOutlineIcon />}
                disabled={mode === "edit"} // Disable the button if in edit mode
              >
                Skicka
              </MDButton>
            </Grid>

            {/* Spacer item to push buttons to the edges */}
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              {mode === "edit" && !formik.values.acceptedAt ? (
                <MDButton
                  variant="gradient"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => setOpenDialog(true)}
                >
                  Radera injudan
                </MDButton>
              ) : (
                <MDButton
                  variant="gradient"
                  color="secondary" // Change the color as per your requirement
                  onClick={() => {
                    navigate("/employees/invites");
                  }}
                >
                  Avbryt
                </MDButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </BaseCreateEdit>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Radera inbjudan"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker på att du vill radera inbjudan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Avbryt
          </Button>
          <Button onClick={deleteInvite} color="primary" autoFocus>
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateEditInvite;
