import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  NextOrObserver,
  User,
  connectAuthEmulator,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { getFirebaseAppCheckSiteKey, getFirebaseConfig } from "./firebase-config";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

const app = initializeApp(getFirebaseConfig());

const isDebugMode = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG === "true";

if (isDebugMode) {
  // Enable debug mode for development
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_RECAPTCHA_SITE_KEY || true;
  console.log("App Check Debug Mode:", isDebugMode);
  console.log("Debug Token:", self.FIREBASE_APPCHECK_DEBUG_TOKEN);
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

const auth = getAuth(app);

export const signInUser = async (email: string, password: string) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const resetPasswordLink = async (email: string) => {
  try {
    return await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Failed to send password reset email: ", error);
    throw error;
  }
};

export const resetPassword = async (oobCode: string, newPassword: string) => {
  try {
    return await confirmPasswordReset(auth, oobCode, newPassword);
  } catch (error) {
    console.error("Failed to reset password:", error);
    throw error;
  }
};

export const changeUserPassword = async (
  email: string,
  currentPassword: string,
  newPassword: string
) => {
  const user = auth.currentUser;
  if (user && user.email === email) {
    try {
      // Create credential with the current email and password
      const credential = EmailAuthProvider.credential(email, currentPassword);

      // Reauthenticate user
      await reauthenticateWithCredential(user, credential);

      // After successful reauthentication, update password
      await updatePassword(user, newPassword);
      console.log("Password updated successfully.");
    } catch (error) {
      console.error("Error during reauthentication or password update:", error);
      throw error;
    }
  } else {
    console.log("No user signed in or no new password provided.");
  }
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const FirestoreDb = () => getFirestore(app);

/*if (location.hostname === "localhost") {
  console.log("http://localhost:3000 detected!");
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(Firestore(), "127.0.0.1", 8080);
}*/

export const SignOutUser = async () => await signOut(auth);
