import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/authentication/account/components/FormField";
import { Customer } from "db/models/Customer";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as customersRepository from "db/repositories/customersRepository";

interface CustomerDropDownProps {
  setCustomer: Dispatch<SetStateAction<Customer>>;
  preSelectedCustomer?: Customer | null; // Add preSelectedCustomer prop
}

function CustomerDropDown({
  setCustomer,
  preSelectedCustomer,
}: CustomerDropDownProps): JSX.Element {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(preSelectedCustomer); // Initialize with preSelectedCustomer
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    setCustomer(selectedCustomer);
  }, [selectedCustomer, setCustomer]);

  const fetchCustomers = async () => {
    try {
      // Fetch customers from repository
      const _customers = await customersRepository.all();
      setCustomers(_customers);

      if (preSelectedCustomer) {
        const matchedCustomer = _customers.find(
          (customer) => customer.id === preSelectedCustomer.id
        );
        if (matchedCustomer) {
          setSelectedCustomer(matchedCustomer);
        }
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  return (
    <Autocomplete
      value={selectedCustomer}
      options={customers}
      onChange={(event, newValue) => {
        setSelectedCustomer(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <FormField {...params} label="Choose customer" InputLabelProps={{ shrink: true }} />
      )}
    />
  );
}

export default CustomerDropDown;
