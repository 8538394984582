import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Grid, Typography, Box, Paper } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ShiftsContext from "context/ShiftsContext";
import UsersContext from "context/UsersContext";
import { UserModel } from "db/models/users/UserModel";
import { Project } from "db/models/Project";
import { WorkObject } from "db/models/workObject";
import PieChart from "examples/Charts/PieChart";
import employyeStatsData from "./data/employeeStatsData";
import { ShiftsOverviewService } from "./models/shiftsOverViewConverter";
import ShiftsOverview from "./components/shiftsOverview";
import ReportSettings from "./components/reportSettings";
import UsersReports from "./components/usersReports";
import { createSquareStyle } from "./styles/squareStyle";
import { calculateUserExposures } from "./components/helpers/shiftCalculations";
import UsersExposureChart from "./components/usersExposureChart";
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import { Timestamp } from "firebase/firestore";

function Reports() {
  const { doneShifts, plannedShifts, loadMoreDoneShifts } = useContext(ShiftsContext);
  const { users } = useContext(UsersContext);
  const navigate = useNavigate();

  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  // All objects and projects should be incuded, even if the shift has no project id.
  // const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  // const [selectedWorkObjects, setSelectedWorkObjects] = useState<WorkObject[]>([]);

  const onYearPrevoiusFromNow = new Date();
  onYearPrevoiusFromNow.setFullYear(onYearPrevoiusFromNow.getFullYear() - 1);

  const [startDate, setStartDate] = useState<Date>(onYearPrevoiusFromNow);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [includePlannedShifts, setIncludePlannedShifts] = useState(false);
  const [includeListWithWorkShifts, setIncludeListWithWorkShifts] = useState(false);
  const [includeDiagram, setIncludeDiagram] = useState(false);

  const handleIncludePlannedShiftsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludePlannedShifts(event.target.checked);
  };

  const handleIncludeListWithWorkShiftsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeListWithWorkShifts(event.target.checked);
  };

  const handleIncludeDiagramChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeDiagram(event.target.checked);
  };

  useEffect(() => {
    loadMoreDoneShifts(Timestamp.fromDate(startDate));
  }, [startDate]);

  const { filteredDoneShifts, filteredPlannedShifts } = ShiftsOverviewService.filterShifts(
    doneShifts,
    plannedShifts,
    // selectedProjects,
    // selectedWorkObjects,
    selectedUsers,
    startDate,
    endDate,
    includePlannedShifts
  );

  const allShifts = includePlannedShifts
    ? [...filteredDoneShifts, ...filteredPlannedShifts]
    : [...filteredDoneShifts];
  const result = calculateUserExposures(allShifts, selectedUsers);

  const squareStyle = createSquareStyle(result.color);

  return (
    <DashboardLayout>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3" color="secondary">
              Rapporter
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Card>
          <ReportSettings
            startDate={startDate}
            endDate={endDate}
            includePlannedShifts={includePlannedShifts}
            includeListWithWorkShifts={includeListWithWorkShifts}
            includeDiagram={includeDiagram}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setIncludePlannedShifts={setIncludePlannedShifts}
            setIncludeListWithWorkShifts={setIncludeListWithWorkShifts}
            setIncludeDiagram={setIncludeDiagram}
            setSelectedUsers={setSelectedUsers}
            // setSelectedProjects={setSelectedProjects}
            // setSelectedWorkObjects={setSelectedWorkObjects}
          />
        </Card>
      </Box>
      {selectedUsers.length > 0 && (
        // selectedProjects.length > 0 &&
        // selectedWorkObjects.length > 0 && (
        <>
          <br></br>
          <Box mt={4}>
            {/* <Card>
                <Grid container spacing={0}>
                  <ShiftsOverview
                    title={startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString()}
                    subtitle={selectedUsers.length + " personer"}
                    doneShifts={filteredDoneShifts}
                    plannedShifts={filteredPlannedShifts}
                  />
                  <Grid item sm={4}>
                    {" "}
                    <Paper style={squareStyle} elevation={0}>
                      <Typography variant="h5">Personalöversikt</Typography>
                      <UsersExposureChart shifts={allShifts} users={selectedUsers} />
                    </Paper>
                  </Grid>
                </Grid>
              </Card> */}
            <UsersReports
              filteredDoneShifts={filteredDoneShifts}
              filteredPlannedShifts={filteredPlannedShifts}
              selectedUsers={selectedUsers}
              includeDiagram={includeDiagram}
              includeListOfWorkshifts={includeListWithWorkShifts}
              includePlannedWorkshifts={includePlannedShifts}
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
        </>
      )}
    </DashboardLayout>
  );
}

export default Reports;
