import React from "react";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";

interface DataTableCardProps {
  data: { columns: { [key: string]: any }[]; rows: { [key: string]: any }[] };
  onRowClick: (id: string) => void;
  showHoverStyle: boolean;
}

const DataTableCard: React.FC<DataTableCardProps> = ({ data, onRowClick, showHoverStyle }) => {
  return (
    <>
      <MDBox px={2}>
        <DataTable
          table={data}
          entriesPerPage={{ defaultValue: 50, entries: [50, 150, 250] }}
          showTotalEntries={true}
          isSorted={true}
          canSearch={true}
          noEndBorder
          onRowClick={onRowClick}
          showHoverStyle={showHoverStyle}
        />
      </MDBox>
    </>
  );
};

export default DataTableCard;
