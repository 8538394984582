import React, { ReactNode } from "react";
import { Grid, Card, Icon, Box, IconButton, Tooltip, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface IndexLayoutProps {
  title: string;
  createLink: string;
  onNewItemClick: () => void;
  addNewText: string;
  children: ReactNode;
}

const IndexLayout: React.FC<IndexLayoutProps> = ({
  title,
  createLink,
  onNewItemClick,
  addNewText,
  children,
}) => {
  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <MDTypography variant="h3" gradient="secondary">
              {title}
            </MDTypography>
          </Grid>
          <Grid item>
            <MDBox
              display="flex"
              alignItems="center"
              onClick={onNewItemClick}
              mt={{ xs: 2, sm: 1 }}
              sx={{ cursor: "pointer" }}
            >
              <MDButton
                id="add-new-button"
                color="info"
                iconOnly
                circular
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  minWidth: "2.5rem",
                  padding: "0.5rem",
                  "& .MuiButton-startIcon": {
                    margin: 0,
                  },
                }}
              >
                <Icon fontSize="large">add</Icon>
              </MDButton>
              <MDTypography variant="p" gradient="secondary" ml={1}>
                Lägg till {addNewText}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <br></br>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox py={1}>{children}</MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default IndexLayout;
