import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { createBatchShifts } from "db/repositories/shiftsRepository"; // Update the import path
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import BaseCreateEdit from "layouts/baseComponents/baseCreateEdit";
import DateSelection from "../helpers/dateSelectionComponent";
import { WorkObject } from "db/models/workObject";
import { ActionPlan } from "db/models/ActionPlan";
import { Project } from "db/models/Project";
import { addDays, differenceInDays, startOfDay } from "date-fns";
import { getTimeDifferenceOnlyInMinutes } from "../helpers/dateCalculator";
import { mapDoneShiftToShift } from "db/models/shiftsModels/mappers/shiftModelMapper";
import BaseShiftForm from "../components/baseShiftForm";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { UserModel } from "db/models/users/UserModel";
import UsersContext from "context/UsersContext";
import CustomerContext from "context/CustomerContext";
import { Shift } from "db/models/shiftsModels/Shift";
import { calculateExposureByDateRange } from "../helpers/exposureCalcluator";
import ShiftsContext from "context/ShiftsContext";

interface FormValues extends DoneShift {}

interface CreatePlannedWorkshiftProps {}

const validationSchema = Yup.object().shape({
  actualStartTime: Yup.date().required("Required"),
  actualEndTime: Yup.date().required("Required"),
  // Add validation for other fields if needed
});

const CreateDoneWorkshift: React.FC<CreatePlannedWorkshiftProps> = ({}) => {
  const navigate = useNavigate();
  const [selectedWorkObject, setSelectedWorkObject] = useState<WorkObject | null>(null);
  const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlan | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const { users } = useContext(UsersContext);
  const { currentCustomer } = useContext(CustomerContext);

  const { plannedShifts, doneShifts } = useContext(ShiftsContext);

  const [startDate, setStartDate] = useState<Date>(new Date());

  const [calculatedUsers, setCalculatedUsers] = useState<UserModel[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [currentShift, setCurrentShift] = useState<PlannedShift | null>(null);

  const handleUserSelect = (selectedUsers: UserModel[]) => {
    setSelectedUsers(selectedUsers);
  };

  useEffect(() => {
    if (users.length > 0 && plannedShifts && doneShifts) {
      // Allways go 365 days back from the selected date.
      startDate.setDate(startDate.getDate());
      let calcuatedUsers = calculateExposureByDateRange(
        startDate,
        users,
        plannedShifts,
        doneShifts
      );
      setCalculatedUsers(calcuatedUsers);
    }
  }, [users, plannedShifts, doneShifts, startDate]);

  const { shiftId } = useParams(); // Get shiftId from the route params

  const initialValues: FormValues = {
    id: "",
    actualStartTime: null,
    actualEndTime: null,
    actualWorkMinutes: null,
    project: null,
    object: null,
    actionPlan: null,
    customer: null,
    user: null,
    radiationDose: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSaving(true);
        setIsSaveDisabled(true); // Disable the button
        setIsSubmitted(true);

        // Check if Object and ActionPlan are selected
        if (!selectedWorkObject) {
          return;
        }

        if (!selectedActionPlan) {
          return;
        }

        // Check if Project is selected
        if (!selectedProject) {
          throw new Error("Vänligen välj ett projekt");
        }

        // Check if Start and End time are selected
        if (!values.actualStartTime || !values.actualEndTime) {
          throw new Error("Vänligen välj start och sluttid");
        }

        // Check if Start time is before End time
        if (values.actualEndTime <= values.actualStartTime) {
          throw new Error("Startiden måste vara före sluttiden");
        }

        // Check if at least one user is selected
        if (selectedUsers.length === 0) {
          throw new Error("Du måste välja minst en personal");
        }

        // Remove id from the values object
        const { id, ...shiftData } = values;

        let startCurrentDate = startOfDay(values.actualStartTime); // Start from plannedStartTime date
        const endCurrentDate = startOfDay(values.actualEndTime); // End at plannedEndTime date

        // Check if endDate is greater than currentDate
        if (endCurrentDate > startCurrentDate) {
          const daysDifference = differenceInDays(endCurrentDate, startCurrentDate);
          const confirmation = await confirm(
            `Det kommer att registreras ${
              daysDifference + 1
            } arbetpass för varje vald personal (en per dag med det valda tidsspannet). Är du säker på att du vill fortsätta?`
          );

          if (!confirmation) {
            // User chose not to proceed
            return;
          }
        }

        let shiftsToCreate = [] as Shift[];

        // Iterate through selected users
        for (const user of selectedUsers) {
          let currentDate = startOfDay(values.actualStartTime); // Start from actualStartTime date
          const endDate = startOfDay(values.actualEndTime); // End at actualEndTime date

          // Iterate through each day between actualStartTime and actualEndTime
          var actualStartTime = new Date(values.actualStartTime);
          var actualEndTime = new Date(values.actualEndTime);

          while (currentDate <= endDate) {
            const actualStartTimeWithTime = new Date(currentDate);
            actualStartTimeWithTime.setHours(actualStartTime.getHours());
            actualStartTimeWithTime.setMinutes(actualStartTime.getMinutes());

            const actualEndTimeWIthTime = new Date(currentDate); // Start with the same date as actualStartTime
            actualEndTimeWIthTime.setHours(actualEndTime.getHours()); // Set hours from actualEndTime
            actualEndTimeWIthTime.setMinutes(actualEndTime.getMinutes());

            const actualWorkMinutes = getTimeDifferenceOnlyInMinutes(
              actualStartTime,
              actualEndTime
            );

            const plannedShift: DoneShift = {
              id: "",
              actualStartTime: actualStartTimeWithTime,
              actualEndTime: actualEndTimeWIthTime,
              actualWorkMinutes: actualWorkMinutes,
              project: {
                id: selectedProject.id,
                name: selectedProject.code + ", " + selectedProject.name,
              },
              object: {
                id: selectedWorkObject.id,
                name: selectedWorkObject.name,
              },
              actionPlan: {
                id: selectedActionPlan.id,
                name: selectedActionPlan.name,
                radiationLevel: selectedActionPlan.radiationLevel,
              },
              customer: {
                id: currentCustomer.id,
                name: currentCustomer.name,
              },
              user: {
                id: user.id,
                name: user.firstName + " " + user.lastName, // Combine firstName and lastName
              },
              radiationDose: (selectedActionPlan.radiationLevel * actualWorkMinutes) / 60,
            };

            var savableShift = mapDoneShiftToShift(plannedShift);
            shiftsToCreate.push(savableShift);

            // Move to the next day
            currentDate = addDays(currentDate, 1);
          }
        }

        await createBatchShifts(shiftsToCreate);
        console.log("Saved: " + shiftsToCreate.length + " shifts.");
        navigate("/workshifts/doneshifts");
      } catch (error) {
        alert("Error: " + error);
        // Handle error, show error message, etc.
      } finally {
        setIsSaving(false);
        setIsSaveDisabled(false); // Enable the button after saving
      }
    },
  });

  return (
    <BaseCreateEdit title={"Skapa genomfört arbetspass"} onSubmit={formik.handleSubmit}>
      <BaseShiftForm
        selectedWorkObject={selectedWorkObject}
        setSelectedWorkObject={setSelectedWorkObject}
        selectedActionPlan={selectedActionPlan}
        setSelectedActionPlan={setSelectedActionPlan}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        selectedUsers={selectedUsers}
        handleUserSelect={handleUserSelect}
        users={calculatedUsers}
        isSubmitted={isSubmitted}
        currentShift={currentShift}
        customerId={currentCustomer.id}
        isSaveDisabled={isSaveDisabled}
        isSaving={isSaving}
        isPlannedShift={false}
      >
        <Grid item xs={12} sm={6}>
          <DateSelection
            id="actualStartTime"
            fullWidth
            label="Startar"
            name="actualStartTime"
            value={formik.values.actualStartTime}
            onChange={(e) => {
              formik.handleChange(e);
              setStartDate(new Date(e.target.value));
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.actualStartTime && Boolean(formik.errors.actualStartTime)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateSelection
            id="actualEndTime"
            fullWidth
            label="Avslutas"
            name="actualEndTime"
            value={formik.values.actualEndTime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.actualEndTime && Boolean(formik.errors.actualEndTime)}
          />
        </Grid>
      </BaseShiftForm>
    </BaseCreateEdit>
  );
};

export default CreateDoneWorkshift;
