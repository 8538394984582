import React, { useEffect, useState } from "react";
import { Dialog, Grid, CircularProgress, Typography } from "@mui/material";
import { query, collection, where, orderBy, onSnapshot } from "firebase/firestore";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import { FirestoreDb } from "../../../../firebase";
import { UserAdminModel } from "db/models/users/UserAdminModel";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import getTableData from "./data/dataTableData";
import CreateAdminDialog from "./createAdminDialogComponent";
import EditAdminDialog from "./editAdminDialogComponent";
import { AdminWithAppUserProps } from "db/models/users/AdminWithUserProps";
import { UserDbModel } from "db/models/users/user";

const db = FirestoreDb();

interface CustomerAdminsProps {
  customerId: string;
}

function CustomerAdmins({ customerId }: CustomerAdminsProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState<AdminWithAppUserProps[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<AdminWithAppUserProps | null>(null);

  useEffect(() => {
    let unsubscribeAdmins = () => {};
    let unsubscribeUsers = () => {};

    if (customerId) {
      const adminsQuery = query(
        collection(db, "admins"),
        where("customerId", "==", customerId),
        orderBy("firstName", "asc")
      );

      unsubscribeAdmins = onSnapshot(adminsQuery, (adminsSnapshot) => {
        const fetchedAdmins: UserAdminModel[] = adminsSnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as UserAdminModel)
        );

        const usersQuery = query(
          collection(db, "users"),
          where("customerId", "==", customerId),
          orderBy("firstName", "asc")
        );

        unsubscribeUsers = onSnapshot(usersQuery, (usersSnapshot) => {
          const fetchedUsers: UserDbModel[] = usersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as UserDbModel[];

          const combinedAdmins = fetchedAdmins.map((admin) => {
            const user = fetchedUsers.find((user) => user.id === admin.id);
            return new AdminWithAppUserProps(admin, user);
          });

          setAdmins(combinedAdmins);
          setIsLoading(false);
        });
      });
    } else {
      setAdmins([]);
    }

    return () => {
      unsubscribeAdmins();
      unsubscribeUsers();
    };
  }, [customerId]);

  const handleRowClick = (adminId: string) => {
    const admin = admins.find((adm) => adm.id === adminId);
    if (admin) {
      setSelectedAdmin(admin);
      setOpenEditDialog(true);
    }
  };

  const createNewAdmin = () => {
    setSelectedAdmin(null);
    setOpenCreateDialog(true);
  };

  const closeCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const closeEditDialog = () => {
    setOpenEditDialog(false);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid item xs={12} m={3}>
        <MDTypography variant="h5" gradient="secondary">
          Customer Admins
        </MDTypography>
      </Grid>
      {admins.length === 0 ? (
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          No admins
        </Typography>
      ) : (
        <DataTableCard
          data={getTableData(admins)}
          onRowClick={(id) => handleRowClick(id)}
          showHoverStyle={true}
        />
      )}
      <Grid item xs={12} m={3}>
        <MDButton variant="gradient" color="dark" onClick={createNewAdmin}>
          Create New Admin
        </MDButton>
      </Grid>
      <CreateAdminDialog
        open={openCreateDialog}
        onClose={closeCreateDialog}
        customerId={customerId}
      />
      {selectedAdmin && (
        <EditAdminDialog open={openEditDialog} onClose={closeEditDialog} admin={selectedAdmin} />
      )}
    </>
  );
}

export default CustomerAdmins;
