import { getTimeDifferenceInMinutes } from "layouts/workshifts/helpers/dateCalculator";
import { Shift } from "./Shift";
import { BaseShiftModel } from "./baseShiftModel";

export class OngoingShift extends BaseShiftModel {
  actualStartTime: Date;
  estimatedWorkMinutes: Number;

  constructor(shift: Shift) {
    super(shift);
    this.actualStartTime = shift.actualStartTime.toDate();
    this.estimatedWorkMinutes = getTimeDifferenceInMinutes(
      shift.actualStartTime.toDate(),
      new Date()
    );
  }
}
