import React, { ReactNode, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import configs from "examples/Charts/BarCharts/VerticalBarChart/configs";

interface ChartDataSet {
  label: string;
  backgroundColor: string[] | string; // Directly use backgroundColor here
  data: number[];
}

interface ChartData {
  labels: string[];
  datasets: ChartDataSet[];
}

interface Props {
  icon?: {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: ChartData; // Use the refined ChartData type
  [key: string]: any;
}

const VerticalBarChart: React.FC<Props> = ({ icon, title, description, height, chart }: Props) => {
  const { data, options } = useMemo(() => configs(chart.labels, chart.datasets), [chart]);

  return (
    <>
      <MDBox mx={-2} display="flex" flexDirection="column" p={2}>
        {icon?.component && (
          <MDBox display="flex" alignItems="center" mb={2}>
            {/* Ensure the Icon component correctly interprets the color prop */}
            <Icon style={{ color: colors[icon.color ?? "info"].main }}>{icon.component}</Icon>
          </MDBox>
        )}
        {title && (
          <MDTypography variant="h6" gutterBottom>
            {title}
          </MDTypography>
        )}
        {description && <MDTypography variant="body2">{description}</MDTypography>}
      </MDBox>
      <MDBox height={height}>
        <Bar data={data} options={options} />
      </MDBox>
    </>
  );
};

VerticalBarChart.defaultProps = {
  height: "19.125rem", // Providing a default height
};

export default VerticalBarChart;
