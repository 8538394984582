import { CalculatableShiftModel, ShiftStatus } from "../CalculatableShiftModel";
import { DoneShift } from "../doneShiftModel";
import { OngoingShift } from "../ongoingShiftModel";
import { PlannedShift } from "../plannedShiftModel";

// Convert a DoneShift to CalculatableShiftModel
export function convertDoneShift(doneShift: DoneShift): CalculatableShiftModel {
  return new CalculatableShiftModel(
    doneShift.id,
    doneShift.project,
    doneShift.object,
    doneShift.actionPlan,
    doneShift.customer,
    doneShift.user,
    doneShift.radiationDose,
    doneShift.actualStartTime,
    doneShift.actualEndTime,
    ShiftStatus.Done
  );
}

// Convert an OngoingShift to CalculatableShiftModel
export function convertOngoingShift(ongoingShift: OngoingShift): CalculatableShiftModel {
  return new CalculatableShiftModel(
    ongoingShift.id,
    ongoingShift.project,
    ongoingShift.object,
    ongoingShift.actionPlan,
    ongoingShift.customer,
    ongoingShift.user,
    ongoingShift.radiationDose,
    ongoingShift.actualStartTime,
    null,
    ShiftStatus.Ongoing
  );
}

// Convert a PlannedShift to CalculatableShiftModel
export function convertPlannedShift(plannedShift: PlannedShift): CalculatableShiftModel {
  return new CalculatableShiftModel(
    plannedShift.id,
    plannedShift.project,
    plannedShift.object,
    plannedShift.actionPlan,
    plannedShift.customer,
    plannedShift.user,
    plannedShift.radiationDose,
    plannedShift.plannedStartTime,
    plannedShift.plannedEndTime,
    ShiftStatus.Planned
  );
}
