import React from "react";
import { Box, Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { SimpleDateSelection } from "layouts/employees/components/dateSelection";
import UsersDropDown from "layouts/workshifts/components/usersDropDown";
import ProjectsDropDown from "layouts/workshifts/components/projectsDropDown";
import WorkObjectsDropDown from "layouts/workshifts/components/objectsDropDown";
import MDButton from "components/MDButton";
import { UserModel } from "db/models/users/UserModel";
import { Project } from "db/models/Project";
import { WorkObject } from "db/models/workObject";

interface ReportSettingsProps {
  startDate: Date;
  endDate: Date;
  includePlannedShifts: boolean;
  includeListWithWorkShifts: boolean;
  includeDiagram: boolean;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setIncludePlannedShifts: (value: boolean) => void;
  setIncludeListWithWorkShifts: (value: boolean) => void;
  setIncludeDiagram: (value: boolean) => void;
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserModel[]>>;
  // setSelectedProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  // setSelectedWorkObjects: React.Dispatch<React.SetStateAction<WorkObject[]>>;
}

const ReportSettings: React.FC<ReportSettingsProps> = ({
  startDate,
  endDate,
  includePlannedShifts,
  includeListWithWorkShifts,
  includeDiagram,
  setStartDate,
  setEndDate,
  setIncludePlannedShifts,
  setIncludeListWithWorkShifts,
  setIncludeDiagram,
  setSelectedUsers,
  // setSelectedProjects,
  // setSelectedWorkObjects,
}) => (
  <Box p={3}>
    <Grid container spacing={3}>
      {/* Date selection and dropdowns */}
      <Grid item xs={12} sm={6} px={3}>
        <Typography variant="h5">Rapportinställningar</Typography>
        {/* Date Selection */}
        <Grid container spacing={2} my={2} alignItems="center">
          <Grid item xs={12} py={2}>
            <SimpleDateSelection label="Från" startDate={startDate} setStartDate={setStartDate} />
          </Grid>
          <Grid item xs={12} py={2}>
            <SimpleDateSelection label="Till" startDate={endDate} setStartDate={setEndDate} />
          </Grid>
        </Grid>
        {/* User, Project, and WorkObject Dropdowns */}
        <Grid item xs={12} style={{ marginTop: "16px" }}>
          <UsersDropDown setUsers={setSelectedUsers} />
        </Grid>
        {/* <Grid item xs={12} style={{ marginTop: "16px" }}>
          <ProjectsDropDown setProjects={setSelectedProjects} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "16px" }}>
          <WorkObjectsDropDown setWorkObjects={setSelectedWorkObjects} />
        </Grid> */}
      </Grid>
      {/* Inclusion options and submit button */}
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">Inkludera också</Typography>
        {/* Include options */}
        <Box borderBottom="1px solid lightgrey" paddingTop="32px">
          <FormControlLabel
            control={
              <Checkbox
                checked={includePlannedShifts}
                onChange={(e) => setIncludePlannedShifts(e.target.checked)}
                name="includePlannedShifts"
                color="primary"
              />
            }
            label="Planerade arbetspass"
          />
        </Box>
        <Box borderBottom="1px solid lightgrey">
          <FormControlLabel
            control={
              <Checkbox
                checked={includeListWithWorkShifts}
                onChange={(e) => setIncludeListWithWorkShifts(e.target.checked)}
                name="includeListWithWorkShifts"
                color="primary"
              />
            }
            label="Lista med arbetspass"
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeDiagram}
              onChange={(e) => setIncludeDiagram(e.target.checked)}
              name="includeDiagram"
              color="primary"
            />
          }
          label="Diagram"
        />
      </Grid>
    </Grid>
  </Box>
);

export default ReportSettings;
