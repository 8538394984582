import { InviteDbModel } from "db/models/invites/inviteDbModel";
import { EmailModel } from "./emailModel";
import { CreateUserAdminModel } from "db/models/users/CreateUserAdminModel";

export function mapInviteToEmailModel(invite: InviteDbModel): EmailModel {
  return {
    customerId: invite.customerId,
    to: invite.email,
    message: {
      subject: "Välkommen till MMK Dose Monitoring",
      text: `Hej ${invite.firstName},\n\nVälkommen till MMK Dose Monitoring. Vänligen ladda ner vår app från:\n\nAppStore om du använder iOS: https://apps.apple.com/se/app/dosemonitoring/id6499460930\nGoogle Play om du använder Android: https://play.google.com/store/apps/details?id=se.dosemonitoring.app&hl=sv\n\nRegistrera dig med denna emailadress: ${invite.email} och ange koden: ${invite.code}.\n\nVänliga hälsningar,\nMMK Dose Monitoring Team`,
      html: `Hej ${invite.firstName},<br/><br/>
            Välkommen till MMK Dose Monitoring. Vänligen ladda ner vår app från:<br/><br/>
            <a href="https://apps.apple.com/se/app/dosemonitoring/id6499460930">AppStore</a> om du använder iOS<br/>
            <a href="https://play.google.com/store/apps/details?id=se.dosemonitoring.app&hl=sv">Google Play</a> om du använder Android.<br/><br/>
            Registrera dig med denna emailadress: ${invite.email} och ange koden:<br/><br/>
            <span style="background-color: #f0f0f0; padding: 10px; border-radius: 5px; font-size: 20px; display: inline-block;">
              <span style="letter-spacing: 3px;" aria-hidden="true">${invite.code.replace(
                /(\d{3})(?=\d)/g,
                "$1 "
              )}</span>
              <span style="position: absolute; left: -9999px;">${invite.code}</span>
            </span><br/><br/>
            Vänliga hälsningar,<br/>
            MMK Dose Monitoring Team
            `,
    },
  };
}

export function mapAdminToEmailModel(admin: CreateUserAdminModel): EmailModel {
  return {
    customerId: admin.customerId,
    to: admin.email,
    message: {
      subject: "Välkommen till MMK Dose Monitoring, administrationsgränssnitt",
      text: `Hej ${admin.firstName},\n\nVälkommen till MMK Dose Monitoring. Du kan nu logga in i vårt administrationsgränssnitt (https://app.dosemonitoring.se) med denna emailadress: ${admin.email}.\n\nVänliga hälsningar,\nMMK Dose Monitoring Team`,
      html: `Hej ${admin.firstName},<br/><br/>Välkommen till MMK Dose Monitoring. Du kan nu logga in i vårt <a href="https://app.dosemonitoring.se">administrationsgränssnitt</a> med denna emailadress: ${admin.email}.<br/><br/>Vänliga hälsningar,<br/>MMK Dose Monitoring Team`,
    },
  };
}
