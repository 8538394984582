import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  Timestamp,
  Unsubscribe,
} from "firebase/firestore";
import { FirestoreDb } from "../firebase";
import CustomerContext from "./CustomerContext";
import { OngoingShift } from "db/models/shiftsModels/ongoingShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { Shift } from "db/models/shiftsModels/Shift";
import {
  convertDoneShift,
  convertOngoingShift,
  convertPlannedShift,
} from "db/models/shiftsModels/mappers/calculatableShiftConverter";
import { CalculatableShiftModel } from "db/models/shiftsModels/CalculatableShiftModel";

const db = FirestoreDb();

interface ShiftsContextType {
  ongoingShifts: OngoingShift[];
  plannedShifts: PlannedShift[];
  doneShifts: DoneShift[];
  allShifts: CalculatableShiftModel[];
  loadMoreDoneShifts: (startDate: Timestamp) => void;
}

const ShiftsContext = createContext<ShiftsContextType>({
  ongoingShifts: [],
  plannedShifts: [],
  doneShifts: [],
  allShifts: [],
  loadMoreDoneShifts: () => {},
});

export const ShiftsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ongoingShifts, setOngoingShifts] = useState<OngoingShift[]>([]);
  const [plannedShifts, setPlannedShifts] = useState<PlannedShift[]>([]);
  const [doneShifts, setDoneShifts] = useState<DoneShift[]>([]);
  const [allShifts, setAllShifts] = useState<CalculatableShiftModel[]>([]);
  const { currentCustomer } = useContext(CustomerContext);

  const loadMoreDoneShifts = (startDate: Timestamp) => {
    if (!currentCustomer?.id) return;

    const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

    if (startDate.seconds > oneYearAgo.seconds) {
      return;
    }

    const additionalDoneQuery = query(
      collection(db, "shifts"),
      where("customer.id", "==", currentCustomer.id),
      where("actualEndTime", "!=", null),
      where("actualEndTime", ">=", startDate),
      orderBy("actualEndTime", "desc")
    );

    onSnapshot(additionalDoneQuery, (snapshot) => {
      const additionalShifts = snapshot.docs.map(
        (doc) => new DoneShift({ id: doc.id, ...doc.data() } as Shift)
      );
      setDoneShifts((prevShifts) => {
        const newShifts = additionalShifts.filter(
          (newShift) => !prevShifts.some((prevShift) => prevShift.id === newShift.id)
        );
        return [...prevShifts, ...newShifts];
      });
    });
  };

  useEffect(() => {
    const convertedDoneShifts = doneShifts.map(convertDoneShift);
    const convertedOngoingShifts = ongoingShifts.map(convertOngoingShift);
    const convertedPlannedShifts = plannedShifts.map(convertPlannedShift);

    const combinedShifts = [
      ...convertedDoneShifts,
      ...convertedOngoingShifts,
      ...convertedPlannedShifts,
    ].sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

    setAllShifts(combinedShifts);
  }, [doneShifts, plannedShifts, ongoingShifts]);

  useEffect(() => {
    let unsubscribes: Unsubscribe[] = [];

    if (currentCustomer?.id) {
      const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

      // Ongoing Shifts
      const ongoingQuery = query(
        collection(db, "shifts"),
        where("customer.id", "==", currentCustomer.id),
        where("actualStartTime", "!=", null),
        where("actualEndTime", "==", null),
        orderBy("actualStartTime", "asc")
      );
      unsubscribes.push(
        onSnapshot(ongoingQuery, (snapshot) => {
          const shifts = snapshot.docs.map(
            (doc) => new OngoingShift({ id: doc.id, ...doc.data() } as Shift)
          );
          setOngoingShifts(shifts);
        })
      );

      // Planned Shifts
      const plannedQuery = query(
        collection(db, "shifts"),
        where("customer.id", "==", currentCustomer.id),
        where("actualStartTime", "==", null),
        orderBy("plannedStartTime", "asc")
      );
      unsubscribes.push(
        onSnapshot(plannedQuery, (snapshot) => {
          const shifts = snapshot.docs.map(
            (doc) => new PlannedShift({ id: doc.id, ...doc.data() } as Shift)
          );
          setPlannedShifts(shifts);
        })
      );

      // Done Shifts
      const doneQuery = query(
        collection(db, "shifts"),
        where("customer.id", "==", currentCustomer.id),
        where("actualEndTime", "!=", null),
        where("actualEndTime", ">=", oneYearAgo),
        orderBy("actualEndTime", "desc")
      );
      unsubscribes.push(
        onSnapshot(doneQuery, (snapshot) => {
          const shifts = snapshot.docs.map(
            (doc) => new DoneShift({ id: doc.id, ...doc.data() } as Shift)
          );
          setDoneShifts(shifts);
        })
      );
    }

    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [currentCustomer]);

  return (
    <ShiftsContext.Provider
      value={{ ongoingShifts, plannedShifts, doneShifts, allShifts, loadMoreDoneShifts }}
    >
      {children}
    </ShiftsContext.Provider>
  );
};

export default ShiftsContext;
