import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import IndexLayout from "layouts/baseComponents/indexLayout";
import EmptyIndexLayout from "layouts/baseComponents/EmptyIndexLayout";
import getTableData from "./data/dataTableData";
import ShiftsContext from "context/ShiftsContext";
import { Button, Modal, Box, CircularProgress, Alert, Snackbar } from "@mui/material"; // Import necessary UI components
import { deleteBatchShifts } from "db/repositories/shiftsRepository";

function PlannedShifts(): JSX.Element {
  const { plannedShifts } = useContext(ShiftsContext);
  const navigate = useNavigate();

  // State to track selected shifts by their IDs
  const [selectedShifts, setSelectedShifts] = useState<{ id: string }[]>([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false); // Control to show/hide checkboxes
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for the delete confirmation modal
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [error, setError] = useState<string | null>(null); // State for error handling
  const [showSuccess, setShowSuccess] = useState(false); // State for success notification

  const createNewPlannedShift = () => {
    navigate("/workshifts/plannedshifts/create-planned-shift");
  };

  // Handle row click based on whether checkboxes are visible
  const handleRowClick = (shiftId: string) => {
    if (!showCheckboxes) {
      navigate(`/workshifts/plannedshifts/edit-planned-shift/${shiftId}`);
    } else {
      handleSelectShift(shiftId); // If checkboxes are visible, clicking the row will select the shift
    }
  };

  // Handles the selection of a shift by its ID
  const handleSelectShift = (shiftId: string) => {
    setSelectedShifts((prevSelected) => {
      if (prevSelected.some((shift) => shift.id === shiftId)) {
        return prevSelected.filter((shift) => shift.id !== shiftId);
      } else {
        return [...prevSelected, { id: shiftId }];
      }
    });
  };

  const handleDeleteShifts = async () => {
    setIsLoading(true);
    setError(null); // Clear previous error
    try {
      const shiftIdsToDelete = selectedShifts.map((shift) => shift.id); // Get the IDs of selected shifts

      // Call the batch delete function
      await deleteBatchShifts(shiftIdsToDelete);

      // Clear the selected shifts after deletion
      setSelectedShifts([]);

      // Close the modal
      setShowDeleteModal(false);

      // Show success notification
      setShowSuccess(true);

      // Reset checkboxes and button to default state
      setShowCheckboxes(false); // Hide the checkboxes
      setSelectedShifts([]); // Clear selected shifts

      // Automatically hide the success message after 2 seconds
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);

      console.log("Shifts deleted successfully.");
    } catch (error) {
      console.error("Error deleting shifts:", error);
      setError("Failed to delete selected shifts. Please try again."); // Set error message
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes((prev) => !prev); // Toggle the checkbox visibility
    setSelectedShifts([]); // Clear selected shifts when toggling checkboxes
  };

  if (!plannedShifts || plannedShifts.length === 0) {
    return (
      <EmptyIndexLayout
        pageTitle="Planerade arbetspass"
        createNewItemClick={createNewPlannedShift}
      />
    );
  }

  return (
    <IndexLayout
      title="Planerade arbetspass"
      createLink="/workshifts/plannedshifts/create-planned-shift"
      onNewItemClick={createNewPlannedShift}
      addNewText="planerat arbetspass"
    >
      {/* Display an error alert if the delete operation fails */}
      {error && (
        <Box mb={2}>
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Box>
      )}

      {/* Render the button to toggle checkboxes or delete shifts */}
      <Box mb={2} padding={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (selectedShifts.length > 0) {
              setShowDeleteModal(true); // Show delete modal if shifts are selected
            } else {
              toggleCheckboxes(); // Toggle checkbox visibility
            }
          }}
          style={{
            backgroundColor: selectedShifts.length > 0 ? "#d32f2f" : "#1976d2", // Red if ready to delete, blue otherwise
            color: "white",
          }}
        >
          {selectedShifts.length > 0
            ? "Ta bort valda arbetspass"
            : showCheckboxes
            ? "Sluta välja flera"
            : "Välj flera arbetspass"}
        </Button>
      </Box>

      <DataTableCard
        data={getTableData(plannedShifts, selectedShifts, showCheckboxes, handleSelectShift)}
        onRowClick={handleRowClick} // Conditionally allow row clicks or selection
        showHoverStyle={!showCheckboxes} // Disable hover style when checkboxes are shown
      />

      {/* Modal for delete confirmation */}
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: 0,
            borderRadius: 2,
            width: "400px",
          }}
        >
          <h2 id="modal-title">Är du helt säker?</h2>
          <p id="modal-description">
            Dessa {selectedShifts.length} arbetspass kommer raderas permanent
          </p>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            {/* Show loading spinner when deleting */}
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button onClick={() => setShowDeleteModal(false)} color="primary">
                  Avbryt
                </Button>
                <Button
                  onClick={handleDeleteShifts}
                  color="secondary"
                  variant="contained"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#1976d2", // Keep your desired background color
                    color: "white",
                  }}
                >
                  Ta bort
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Success Snackbar notification */}
      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        message="Arbetspassen togs bort"
      />
    </IndexLayout>
  );
}

export default PlannedShifts;
