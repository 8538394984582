import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import getTableData from "./data/dataTableData";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/auth-context";
import CustomerContext from "context/CustomerContext";
import ShiftsContext from "context/ShiftsContext";

function Dashboard(): JSX.Element {
  const { currentCustomer } = useContext(CustomerContext);
  const { ongoingShifts } = useContext(ShiftsContext);

  // userData is still needed for user-specific information not related to customer or shifts
  const { userData } = useContext(AuthContext);

  const [dataTableData, setDataTableData] = useState(() => getTableData(ongoingShifts));

  useEffect(() => {
    // Function to recalculate and update data table data
    const updateData = () => {
      setDataTableData(getTableData(ongoingShifts));
    };

    // Update the data immediately upon component mount or when ongoingShifts changes
    updateData();

    // Set up an interval to update the data every second
    const intervalId = setInterval(() => {
      updateData();
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts or ongoingShifts changes
    return () => clearInterval(intervalId);
  }, [ongoingShifts]);

  return (
    <DashboardLayout>
      <MDBox py={3} pb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item xs={12} pt={2} pb={4}>
              <MDTypography variant="h3" gradient="secondary">
                Översikt
              </MDTypography>
            </Grid>
            <Card>
              <MDBox pt={4} px={4}>
                <MDTypography variant="span">
                  Hej{" "}
                  <MDTypography variant="span" fontWeight="bold">
                    {userData?.firstName + " " + userData?.lastName}
                  </MDTypography>
                </MDTypography>
                <br></br>
                <br></br>
                <MDTypography variant="span">
                  Välkommen till MMK Dose Monitorings administrationsgränssnitt.
                </MDTypography>
                <br></br>
                <MDTypography variant="span">
                  Du är inloggad som administratör för:{" "}
                  <MDTypography variant="span" fontWeight="bold">
                    {currentCustomer?.name}.
                  </MDTypography>
                </MDTypography>
                <br></br>
                <br></br>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3} pb={2}>
        <Grid container spacing={3} py={2}>
          <Grid item xs={12}>
            <Card>
              <MDTypography py={4} px={4} variant="h5">
                Pågående arbeten
              </MDTypography>
              <DataTableCard data={dataTableData} onRowClick={() => {}} showHoverStyle={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
