import React, { useContext, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Project } from "db/models/Project";
import FormField from "layouts/authentication/account/components/FormField";
import { Dispatch, SetStateAction } from "react";
import ProjectsContext from "context/ProjectsContext";

interface ProjectDropDownProps {
  setProject: Dispatch<SetStateAction<Project | null>>;
  selectedProjectId?: string | null;
}

function ProjectDropDown({ setProject, selectedProjectId }: ProjectDropDownProps): JSX.Element {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const { projects } = useContext(ProjectsContext);

  useEffect(() => {
    // Match selected project with provided ID in edit mode
    if (selectedProjectId && projects.length > 0) {
      const foundProject = projects.find((project) => project.id === selectedProjectId);
      setSelectedProject(foundProject || null);
      setProject(foundProject);
    }
  }, [selectedProjectId, projects, setProject]);

  return (
    <Autocomplete
      value={selectedProject}
      options={projects}
      onChange={(event, newValue) => {
        setSelectedProject(newValue);
        setProject(newValue);
      }}
      getOptionLabel={(option) => option.code + ", " + option.name}
      renderInput={(params) => (
        <FormField
          id="project-drop-drown"
          {...params}
          label="Välj projekt"
          InputLabelProps={{ shrink: true }}
          placeholder="Vänligen välj projekt"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} data-testid={`project-option-${option.id}`}>
          {`${option.code}, ${option.name}`}
        </li>
      )}
    />
  );
}

export default ProjectDropDown;
