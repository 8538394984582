import { FirestoreDb, changeUserPassword } from "../../firebase";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore"; // Import necessary Firestore modules
import { UserAdminModel } from "db/models/users/UserAdminModel";
import { CreateUserAdminModel } from "db/models/users/CreateUserAdminModel";
import { mapAdminToEmailModel } from "db/email/emailMapper";
import { sendInviteEmail } from "db/email/emailService";
import { EditUserAdminModel } from "db/models/users/EditUserAdminModel";

// Initialize Firestore
const db = FirestoreDb();

// Collection name
const COLLECTION_NAME = "admins";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const createAdmin = async (
  admin: CreateUserAdminModel,
  customerId: string
): Promise<CreateUserAdminModel> => {
  try {
    const customerRef = doc(db, "customers", customerId);

    // Add the customer reference to the project data
    const adminWithCustomerRef = {
      ...admin,
      customerId: customerId,
      customer: customerRef,
    };

    const { id: projectId, ...adminDataWithoutId } = adminWithCustomerRef;

    // Add the admin to the collection
    const docRef = await addDoc(collection(db, "pendingAdmins"), adminDataWithoutId);

    const email = mapAdminToEmailModel(adminWithCustomerRef);
    await sendInviteEmail(email);

    // Wait a bit to let the backend service create the new admin
    await delay(2500);

    return {
      id: docRef.id,
      ...admin,
    } as CreateUserAdminModel;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

export const updateAdmin = async (
  userId: string,
  admin: EditUserAdminModel
): Promise<EditUserAdminModel | null> => {
  try {
    if (!userId) throw new Error("Admin ID is required");

    // Conditionally update the admin's password if newPassword is provided
    if (admin.newPassword) {
      await changeUserPassword(admin.email, admin.currentPassword, admin.newPassword);
    }

    // Update Firestore document for the admin
    const adminRef = doc(db, COLLECTION_NAME, userId);
    await updateDoc(adminRef, {
      firstName: admin.firstName,
      lastName: admin.lastName,
    });

    return {
      ...admin,
    };
  } catch (error) {
    console.error("Error updating admin data:", error);
    throw error;
  }
};
