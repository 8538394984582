import { Project } from "db/models/Project";
import { BaseShiftModel } from "db/models/shiftsModels/baseShiftModel";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import { UserModel } from "db/models/users/UserModel";
import { WorkObject } from "db/models/workObject";

export class ShiftsOverviewService {
  static filterShifts(
    doneShifts: DoneShift[],
    plannedShifts: PlannedShift[],
    // selectedProjects: Project[],
    // selectedWorkObjects: WorkObject[],
    selectedUsers: UserModel[],
    startDate: Date,
    endDate: Date,
    includePlannedShifts: boolean
  ): { filteredDoneShifts: DoneShift[]; filteredPlannedShifts: PlannedShift[] } {
    const filterLogic = (shift: BaseShiftModel) => {
      // All objects and projects should be incuded, even if the shift has no project id.
      // const matchesProject = selectedProjects.some((project) => shift.project?.id === project.id);
      // const matchesObject = selectedWorkObjects.some((object) => shift.object.id === object.id);
      const matchesUser = selectedUsers.some((user) => shift.user.id === user.id);

      let shiftStartTime: Date;
      let shiftEndTime: Date;

      if ("actualStartTime" in shift && shift instanceof DoneShift) {
        shiftStartTime = new Date(shift.actualStartTime);
        shiftEndTime = new Date(shift.actualEndTime);
      } else if ("plannedStartTime" in shift && shift instanceof PlannedShift) {
        shiftStartTime = new Date(shift.plannedStartTime);
        shiftEndTime = new Date(shift.plannedEndTime);
      } else {
        // Handle unexpected shift type or missing data
        console.error("Unexpected shift type or missing date properties", shift);
        return false; // Exclude this shift from results
      }

      const isInTimeSpan = shiftStartTime >= startDate && shiftEndTime <= endDate;
      // return matchesProject && matchesObject && matchesUser && isInTimeSpan;
      return matchesUser && isInTimeSpan;
    };

    const filteredDoneShifts = doneShifts.filter(filterLogic);
    const filteredPlannedShifts = includePlannedShifts ? plannedShifts.filter(filterLogic) : [];

    return { filteredDoneShifts, filteredPlannedShifts };
  }

  static calculateTotalWorkTime(shifts: Array<DoneShift | PlannedShift>): string {
    const totalWorkMinutes = shifts.reduce((acc, shift) => {
      // Using a type guard to differentiate between DoneShift and PlannedShift
      if ("actualWorkMinutes" in shift) {
        return acc + shift.actualWorkMinutes; // For DoneShift
      } else {
        return acc + shift.estimatedWorkMinutes; // For PlannedShift
      }
    }, 0);
    return this.formatTimeAsHoursAndMinutes(totalWorkMinutes);
  }

  static calculateTotalRadiation(shifts: BaseShiftModel[]): string {
    const totalRadiation = shifts.reduce((acc: number, shift) => acc + shift.radiationDose, 0);
    return totalRadiation.toLocaleString("sv-SE", { minimumFractionDigits: 2 });
  }

  static calculateTotalRadiationNumber(shifts: BaseShiftModel[]): number {
    return shifts.reduce((acc: number, shift) => acc + shift.radiationDose, 0);
  }

  static filterShiftsWithoutMeasurement(
    shifts: Array<DoneShift | PlannedShift>
  ): Array<DoneShift | PlannedShift> {
    return shifts.filter((shift) => shift.radiationDose === 0);
  }

  static formatTimeAsHoursAndMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours === 0
      ? `${minutes}min`
      : `${hours.toLocaleString("sv-SE", { minimumFractionDigits: 0 })}h ${minutes}min`;
  }
}
