import { format, isValid } from "date-fns";
import { sv } from "date-fns/locale";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import { UserModel } from "db/models/users/UserModel";
import { calculateDoneExposure } from "layouts/workshifts/helpers/exposureCalcluator";

export const getTableData = (users: UserModel[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Förnamn", accessor: "firstName" },
      { Header: "Efternamn", accessor: "lastName" },
      { Header: "Befattning", accessor: "role" },
      { Header: "Anställd från", accessor: "employedFrom" },
      { Header: "Anställd till", accessor: "employedTo" },
      { Header: "Dos", accessor: "exposurePercentage", align: "right" },
    ],
    rows: users.map((user) => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      employedFrom: isValid(new Date(user.employedFrom))
        ? format(new Date(user.employedFrom), "yyyy-MM-dd HH:mm:ss")
        : "-",
      employedTo:
        user.employedTo && isValid(new Date(user.employedTo))
          ? format(new Date(user.employedTo), "yyyy-MM-dd HH:mm:ss")
          : "-",
      exposurePercentage: user.exposurePercentage,
    })),
  };
  return dataTableData;
};

export const getDoneWorkShiftsData = (shifts: DoneShift[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Person", accessor: "user" },
      { Header: "Objekt", accessor: "object" },
      { Header: "Projekt", accessor: "project" },
      { Header: "Fr.o.m", accessor: "plannedStartTime" },
      { Header: "T.o.m", accessor: "plannedEndTime" },
      { Header: "Tid (min)", accessor: "estimatedWorkMinutes", align: "right" },
      { Header: "Exponering (Bqh/m³)", accessor: "exposure", align: "right" },
    ],
    rows: shifts.map((shift) => ({
      id: shift.id,
      user: shift.user.name,
      plannedStartTime: isValid(shift.actualStartTime)
        ? format(shift.actualStartTime, "yyyy-MM-dd HH:mm:ss")
        : "",
      plannedEndTime: isValid(shift.actualEndTime)
        ? format(shift.actualEndTime, "yyyy-MM-dd HH:mm:ss")
        : "",
      estimatedWorkMinutes: shift.actualWorkMinutes ?? "",
      project: shift.project?.name ?? "",
      object: shift.object?.name ?? "",
      exposure: shift.radiationDose.toLocaleString("sv-SE", { minimumFractionDigits: 3 }),
    })),
  };
  return dataTableData;
};
