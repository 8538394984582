import React from "react";
import { useFormikContext } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

interface DateSelectionProps extends Omit<TextFieldProps, "error" | "helperText"> {
  name: string;
  error?: boolean;
  helperText?: React.ReactNode;
}

const DateSelection: React.FC<DateSelectionProps> = ({ name, ...rest }) => {
  const { errors, touched } = useFormikContext<any>();

  const error = touched[name] && Boolean(errors[name]);
  const helperText = touched[name] && errors[name] ? errors[name].toString() : "";

  return (
    <TextField
      fullWidth
      type="datetime-local"
      name={name}
      error={error}
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
};

export default DateSelection;
