import { format, isValid } from "date-fns";
import { InviteModel } from "db/models/invites/inviteModel";

export const getTableData = (invites: InviteModel[]) => {
  const dataTableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Namn", accessor: "name" },
      { Header: "E-post", accessor: "email" },
      { Header: "Befattning", accessor: "role" },
      { Header: "Startdos", accessor: "radiationDos" },
      { Header: "Kod", accessor: "code" },
      { Header: "Giltig till", accessor: "avaliableTo" },
      { Header: "Accepterad", accessor: "acceptedAt" },
      { Header: "Nekad", accessor: "declinedAt" },
    ],
    rows: invites.map((invite) => ({
      id: invite.id,
      name: invite.firstName + " " + invite.lastName,
      email: invite.email,
      role: invite.role,
      radiationDos: invite.startRadiationDoseBqh,
      code: invite.code,
      avaliableTo: isValid(invite.avaliableTo)
        ? format(invite.avaliableTo, "yyyy-MM-dd HH:mm:ss")
        : "-",
      acceptedAt: isValid(invite.acceptedAt)
        ? format(invite.employedFrom, "yyyy-MM-dd HH:mm:ss")
        : "-",
      declinedAt:
        invite.declinedAt && isValid(invite.declinedAt)
          ? format(invite.declinedAt, "yyyy-MM-dd HH:mm:ss")
          : "-",
    })),
  };
  return dataTableData;
};
