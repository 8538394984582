import { startOfMonth, endOfMonth, addMonths, isWithinInterval } from "date-fns";
import { MonthlyDose } from "./monthlyDose";
import { UnifiedShift } from "./shiftUnifier";

class ShiftAggregator {
  static getMonthsInRange(start: Date, end: Date): Date[] {
    const months = [];
    let date = startOfMonth(new Date(start));
    while (date <= end) {
      months.push(new Date(date));
      date = addMonths(date, 1);
    }
    return months;
  }

  static aggregateShifts(
    periodLimit: number,
    unifiedShifts: UnifiedShift[],
    startDate: Date,
    endDate: Date
  ): MonthlyDose[] {
    const monthsInRange = this.getMonthsInRange(startDate, endDate);
    const monthlyDoses: MonthlyDose[] = [];

    monthsInRange.forEach((month) => {
      const monthStart = startOfMonth(month);
      const monthEnd = endOfMonth(month);

      const completedDose = unifiedShifts
        .filter(({ endTime }) => isWithinInterval(endTime, { start: monthStart, end: monthEnd }))
        .reduce((sum, { radiationDose }) => sum + radiationDose, 0);

      var montlyDose = new MonthlyDose(periodLimit, month, completedDose);

      monthlyDoses.push(montlyDose);
    });

    return monthlyDoses;
  }
}

export default ShiftAggregator;
