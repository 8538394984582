import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import { FirestoreDb } from "../firebase";
import { collection, query, where, orderBy, onSnapshot, doc, getDocs } from "firebase/firestore";
import { WorkObject } from "db/models/workObject";
import { AuthContext } from "./auth-context";
import SuperAdminCustomersContext from "./SuperAdminCustomersProvider";
import { ActionPlan } from "db/models/ActionPlan";
import { MeasuredObject } from "db/models/MeaseuredObject";
import { dA } from "@fullcalendar/core/internal-common";

const db = FirestoreDb();

interface WorkObjectExtended extends WorkObject {
  actionPlans: ActionPlan[];
}

interface SuperAdminObjectsContextType {
  allObjects: WorkObjectExtended[];
}

const SuperAdminObjectsContext = createContext<SuperAdminObjectsContextType>({ allObjects: [] });

export const SuperAdminsObjectsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [allObjects, setAllObjects] = useState<WorkObjectExtended[]>([]);
  const { isSuperAdmin } = useContext(AuthContext);
  const { allCustomers } = useContext(SuperAdminCustomersContext);

  useEffect(() => {
    if (isSuperAdmin && allCustomers.length > 0) {
      const q = query(collection(db, "objects"));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const objects = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = { id: doc.id, ...doc.data() } as MeasuredObject;
            const customer = allCustomers.find((customer) => customer.id === data.customer.id);
            return new WorkObject(data, customer);
          })
        );
        setAllObjects(objects);
      });

      return () => unsubscribe();
    }
  }, [isSuperAdmin, allCustomers]);

  return (
    <SuperAdminObjectsContext.Provider value={{ allObjects }}>
      {children}
    </SuperAdminObjectsContext.Provider>
  );
};

export default SuperAdminObjectsContext;
