import React, { ReactNode } from "react";
import { Grid, Card, Typography, Box } from "@mui/material";
import { Formik, Form } from "formik"; // Import Formik components

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

interface BaseCreateEditProps {
  title: string;
  onSubmit: () => void;
  children: ReactNode;
}

const BaseCreateEdit: React.FC<BaseCreateEditProps> = ({ title, onSubmit, children }) => {
  return (
    <DashboardLayout>
      <Box p={4}>
        <Card>
          <Box p={3}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          {/* Formik context wrapped around the form content */}
          <Formik initialValues={{}} onSubmit={onSubmit}>
            <Form>
              <Box p={3}>
                <Grid container spacing={4}>
                  {children}
                </Grid>
              </Box>
            </Form>
          </Formik>
        </Card>
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default BaseCreateEdit;
