import * as Yup from "yup";

export class InviteValidationSchema {
  private existingEmailAdresses: string[];
  private existingPersonalSecurityNumbers: string[];

  constructor(existingEmailAdresses: string[], existingPersonalSecurityNumbers: string[]) {
    this.existingEmailAdresses = existingEmailAdresses;
    this.existingPersonalSecurityNumbers = existingPersonalSecurityNumbers;
  }

  public getSchema() {
    return Yup.object({
      firstName: Yup.string().required("Förnamn är obligatoriskt"),
      lastName: Yup.string().required("Efternamn är obligatoriskt"),
      role: Yup.string().required("Roll är obligatorisk"),
      email: Yup.string()
        .email("Ogiltig e-postadress")
        .required("E-post är obligatorisk")
        .notOneOf(this.existingEmailAdresses, "E-postadressen finns redan"),
      personalSecurityNumber: Yup.string()
        .required("Personnummer är obligatoriskt")
        .test("swedishPersonalNumber", "Ogiltigt personnummer", (value) => {
          const isValidSwedishPersonalNumber = (number: string) => {
            const formatRegex = /^\d{8}-\d{4}$/;
            if (!formatRegex.test(number)) return false;
            const cleanNumber = number.replace(/\D/g, "");
            if (cleanNumber.length !== 10 && cleanNumber.length !== 12) return false;
            const numToCheck = cleanNumber.length === 12 ? cleanNumber.substring(2) : cleanNumber;
            let sum = 0;
            for (let i = 0; i < 10; i++) {
              let digit = parseInt(numToCheck.charAt(i));
              if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) digit -= 9;
              }
              sum += digit;
            }
            return sum % 10 === 0;
          };
          return value && isValidSwedishPersonalNumber(value);
        })
        .notOneOf(this.existingPersonalSecurityNumbers, "Personnumret finns redan"),
    });
  }
}
