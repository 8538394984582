import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createInvite } from "db/repositories/invitesRepository";
import MDButton from "components/MDButton";
import { InviteModel } from "db/models/invites/inviteModel";
import { convertModelToDbModel } from "db/models/invites/inviteMapper";
import { FirestoreDb } from "../../../../firebase";
import { InviteValidationSchema } from "helpers/validationSchemes/InviteValidationScheme";
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { UserDbModel } from "db/models/users/user";

const db = FirestoreDb();

interface FormValues extends InviteModel {}

interface CreateInviteDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
}

const CreateInviteDialog: React.FC<CreateInviteDialogProps> = ({ open, onClose, customerId }) => {
  const [existingEmailAdresses, setExistingEmailAdresses] = useState<string[]>([]);
  const [existingPersonalSecurityNumbers, setExistingPersonalSecurityNumbers] = useState<string[]>(
    []
  );

  const validationSchema = new InviteValidationSchema(
    existingEmailAdresses,
    existingPersonalSecurityNumbers
  ).getSchema();

  const initialValues: FormValues = {
    id: "",
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    personalSecurityNumber: "",
    employedFrom: new Date(),
    employedTo: new Date(),
    avaliableTo: new Date(
      new Date().getFullYear() + 1,
      new Date().getMonth(),
      new Date().getDate()
    ),
    acceptedAt: null,
    declinedAt: null,
    code: Math.random().toString().slice(2, 11),
    customerId: customerId,
    startRadiationDoseBqh: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const inviteDbModel = convertModelToDbModel(values);
        await createInvite(inviteDbModel, customerId); // Pass customerId when creating invite
        console.log("Invite created");
        onClose();
      } catch (error) {
        alert("Error saving invite: " + error);
      }
    },
  });

  useEffect(() => {
    let unsubscribe = () => {};

    if (customerId) {
      const q = query(
        collection(db, "users"),
        where("customerId", "==", customerId),
        orderBy("firstName", "asc")
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedUsers: UserDbModel[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as UserDbModel[];
        setExistingEmailAdresses(fetchedUsers.map((user) => user.email));
        setExistingPersonalSecurityNumbers(fetchedUsers.map((user) => user.personalSecurityNumber));
      });
    } else {
      setExistingEmailAdresses([]);
      setExistingPersonalSecurityNumbers([]);
    }

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [customerId]);

  useEffect(() => {
    formik.resetForm();
  }, [customerId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Invite</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="Förnamn"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Efternamn"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="personalSecurityNumber"
                name="personalSecurityNumber"
                label="Personnummer"
                value={formik.values.personalSecurityNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.personalSecurityNumber &&
                  Boolean(formik.errors.personalSecurityNumber)
                }
                helperText={
                  formik.touched.personalSecurityNumber && formik.errors.personalSecurityNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-post"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="role"
                name="role"
                label="Befattning"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="startRadiationDoseBqh"
                name="startRadiationDoseBqh"
                label="Startdos Bqh"
                type="number"
                value={formik.values.startRadiationDoseBqh}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.startRadiationDoseBqh &&
                  Boolean(formik.errors.startRadiationDoseBqh)
                }
                helperText={
                  formik.touched.startRadiationDoseBqh && formik.errors.startRadiationDoseBqh
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} display="flex" justifyContent="flex-start">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    type="submit"
                    startIcon={<MailOutlineIcon />}
                  >
                    Skicka
                  </MDButton>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="secondary" onClick={onClose}>
                    Avbryt
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateInviteDialog;
