import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material"; // Import AlertColor for Snackbar severity
import { UserModel } from "db/models/users/UserModel"; // Adjust the import path as necessary
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { SimpleDateSelection } from "./components/dateSelection";
import DataTableCard from "layouts/baseComponents/dataTableCard";
import { getDoneWorkShiftsData } from "./data/dataTableData";
import { DoneShift } from "db/models/shiftsModels/doneShiftModel";
import calculateExposureChartData from "./data/lineChartData";
import { PlannedShift } from "db/models/shiftsModels/plannedShiftModel";
import { configs } from "./chart/chartConfiguration";
import ShiftsContext from "context/ShiftsContext";
import UsersContext from "context/UsersContext";
import CustomerContext from "context/CustomerContext";
import RadiationDoseChart from "./data/radiationDoseChart";
import { Timestamp } from "firebase/firestore";
import getTableData from "layouts/workshifts/plannedWorkshifts/data/dataTableData";
import SetDoseDialog from "./components/setDoseDialog";
import { DummyActionPlan, DummyObject, DummyProject } from "helpers/manualDoseMock";
import { mapDoneShiftToShift } from "db/models/shiftsModels/mappers/shiftModelMapper";
import { create } from "db/repositories/shiftsRepository";

const Employee: React.FC = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [user, setUser] = useState<UserModel | null>(null);
  const [usersDoneShifts, setUsersDoneShifts] = useState<Array<DoneShift>>([]);
  const [usersPlannedShifts, setUsersPlannedShifts] = useState<Array<PlannedShift>>([]);
  const { plannedShifts, doneShifts, loadMoreDoneShifts } = useContext(ShiftsContext);
  const { users } = useContext(UsersContext);
  const { currentCustomer } = useContext(CustomerContext);

  const oneYearPreviousFromNow = new Date();
  oneYearPreviousFromNow.setFullYear(oneYearPreviousFromNow.getFullYear() - 1);

  const [startDate, setStartDate] = useState<Date>(oneYearPreviousFromNow);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [includePlannedShifts, setIncludePlannedShifts] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  // Snackbar state for displaying messages
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({ open: false, message: "", severity: "success" });

  const handleIncludePlannedShiftsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludePlannedShifts(event.target.checked);
  };

  async function initUserData(employeeId: string) {
    if (!employeeId) return;

    try {
      const userData = users.find((user) => user.id === employeeId);
      if (userData && (!user || user.id !== employeeId)) {
        setUser(userData);
      }

      const filteredShifts = doneShifts.filter(
        (shift) => shift.user.id === employeeId && shift.actualEndTime >= startDate
      );
      setUsersDoneShifts(filteredShifts);

      const filteredPlannedShifts = plannedShifts.filter((shift) => shift.user.id === employeeId);
      setUsersPlannedShifts(filteredPlannedShifts);
    } catch (error) {
      console.error("Error initializing user data:", error);
    }
  }

  useEffect(() => {
    initUserData(employeeId);
  }, [employeeId, doneShifts, plannedShifts, users, startDate]);

  useEffect(() => {
    if (employeeId) {
      loadMoreDoneShifts(Timestamp.fromDate(startDate));
    }
  }, [startDate, employeeId]);

  if (!employeeId) {
    return <div>Loading employee data...</div>;
  }

  if (!user) {
    return <div>Loading user data...</div>;
  }

  // Handler to open the SetDoseDialog
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  // Handler to close the SetDoseDialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Function to update the Snackbar state
  const setSnackbarState = (open: boolean, message: string, severity: AlertColor) => {
    setSnackbar({ open, message, severity });
  };

  // Handler to save the dose data with error handling
  const handleSaveDose = async (doseData: { date: string; radiationDose: number }) => {
    try {
      const startDateTime = new Date(`${doseData.date}T00:00:01`);
      const endDateTime = new Date(`${doseData.date}T00:00:02`);

      const workMinutes = 480;

      const dummyShift: DoneShift = {
        id: "",
        actualStartTime: startDateTime,
        actualEndTime: endDateTime,
        actualWorkMinutes: workMinutes,
        project: DummyProject,
        object: DummyObject,
        actionPlan: DummyActionPlan,
        customer: {
          id: currentCustomer.id,
          name: currentCustomer.name,
        },
        user: {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        },
        radiationDose: doseData.radiationDose,
      };

      var savableDummyShift = mapDoneShiftToShift(dummyShift);

      await create(savableDummyShift);

      // Show success message using Snackbar
      setSnackbarState(true, "Manuell dos uppdaterad!", "success");
    } catch (error) {
      console.error("Error saving dose data:", error);

      const retry = window.confirm("Sparandet av manuell dos misslyckades, vill du försöka igen?");
      if (retry) {
        await handleSaveDose(doseData);
      } else {
        setSnackbarState(true, "Sparandet av manuell dos misslyckades.", "error");
      }
    }
  };

  return (
    <DashboardLayout>
      <Box p={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Box p={3}>
                <Typography variant="h5">
                  {user.firstName} {user.lastName}
                </Typography>
              </Box>
              <Box p={4}>
                <Grid container rowSpacing={4} alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom>
                      Personnummer
                    </Typography>
                    <Typography>{user.personalSecurityNumber}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom>
                      E-post
                    </Typography>
                    <Typography>{user.email}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom>
                      Befattning
                    </Typography>
                    <Typography>{user.role}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <hr style={{ width: "100%" }} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Box p={3}>
                <Grid container alignItems="center" justifyContent="space-between">
                  {/* Title on the left */}
                  <Grid item>
                    <Typography variant="h5">Dosöversikt</Typography>
                  </Grid>
                  {/* Button on the right */}
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenDialog}
                      style={{ color: "white" }}
                    >
                      Ange manuellt dosvärde
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box p={4}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={2}>
                    <SimpleDateSelection
                      label="Från"
                      startDate={startDate}
                      setStartDate={setStartDate}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SimpleDateSelection
                      label="Till"
                      startDate={endDate}
                      setStartDate={setEndDate}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item xs={12} style={{ marginTop: "16px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includePlannedShifts}
                          onChange={handleIncludePlannedShiftsChange}
                          name="includePlannedShifts"
                          color="primary"
                        />
                      }
                      label="Inkludera planerade arbetspass"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <RadiationDoseChart
                    doneShifts={usersDoneShifts}
                    plannedShifts={usersPlannedShifts}
                    excludePlanned={!includePlannedShifts}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Grid>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Box p={3}>
                <Typography variant="h5">Genomförda arbetspass</Typography>
              </Box>
              <DataTableCard
                data={getDoneWorkShiftsData(usersDoneShifts)}
                onRowClick={() => {}}
                showHoverStyle={false}
              />
            </Card>
          </Grid>
          {includePlannedShifts && (
            <Grid item xs={12}>
              <Card>
                <Box p={3}>
                  <Typography variant="h5">Planerade arbetspass</Typography>
                </Box>
                <DataTableCard
                  data={getTableData(usersPlannedShifts)}
                  onRowClick={() => {}}
                  showHoverStyle={false}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* SetDoseDialog Component */}
      <SetDoseDialog open={dialogOpen} onClose={handleCloseDialog} onSave={handleSaveDose} />

      {/* Snackbar Component for Success/Error Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbarState(false, "", "success")}
      >
        <Alert onClose={() => setSnackbarState(false, "", "success")} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Footer />
    </DashboardLayout>
  );
};

export default Employee;
