import { useContext, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import getTableData from "./data/dataTableData";
import { useNavigate } from "react-router-dom";
import SuperAdminObjectsContext from "context/SuperAdminObjectsProvider";
import ImportObjectList from "./ImportObjectList";

function Objects(): JSX.Element {
  const { allObjects } = useContext(SuperAdminObjectsContext);

  const navigate = useNavigate();

  function createNewObject(event: any): void {
    navigate("/superadmin/create-object");
  }

  const handleRowClick = (objectId: string) => {
    // Navigate to the edit page with the customer ID as a parameter
    navigate(`/superadmin/edit-object/${objectId}`);
  };

  if (!allObjects) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <br></br>
        <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="left" mt={{ xs: 2, sm: "auto" }}>
          <MDButton type="submit" variant="gradient" color="dark" onClick={createNewObject}>
            Create new object
          </MDButton>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <br></br>
      <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="left" mt={{ xs: 2, sm: "auto" }}>
        <MDButton type="submit" variant="gradient" color="dark" onClick={createNewObject}>
          Create new object
        </MDButton>
      </MDBox>
      <MDBox py={3}>
        <ImportObjectList />
      </MDBox>

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Objects
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={getTableData(allObjects)}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  isSorted={true}
                  canSearch={true}
                  noEndBorder
                  onRowClick={handleRowClick}
                  showHoverStyle={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Objects;
