import * as Yup from "yup";

// Define the custom validation function for Swedish personal numbers
Yup.addMethod(Yup.string, "swedishPersonalNumber", function (message) {
  return this.test("swedishPersonalNumber", message, function (value) {
    const { path, createError } = this;

    // Function to validate the Swedish personal number format and checksum
    const isValidSwedishPersonalNumber = (number: string) => {
      // Regular expression to check the format YYYYMMDD-XXXX
      const formatRegex = /^\d{8}-\d{4}$/;

      // First, check if the number matches the required format
      if (!formatRegex.test(number)) {
        return false;
      }

      // Remove any non-digit characters (e.g., '-') for the Luhn algorithm validation
      const cleanNumber = number.replace(/\D/g, "");

      // Implement the Luhn algorithm (or modulo-10 algorithm) for the last 10 digits
      const numToCheck = cleanNumber.length === 12 ? cleanNumber.substring(2) : cleanNumber;
      let sum = 0;
      for (let i = 0; i < 10; i++) {
        let digit = parseInt(numToCheck.charAt(i));
        if (i % 2 === 0) {
          // Multiply every other digit by 2
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        sum += digit;
      }

      return sum % 10 === 0;
    };

    // Check if the personal number is valid
    const isValid = value && isValidSwedishPersonalNumber(value);
    return isValid || createError({ path, message });
  });
});

// Extend Yup's StringSchema with the new method type definition
declare module "yup" {
  interface StringSchema {
    swedishPersonalNumber(message: string): StringSchema;
  }
}
