import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { FirestoreDb } from "../firebase";
import { WorkObject } from "db/models/workObject";
import CustomerContext from "./CustomerContext";
// import firestore
const db = FirestoreDb();

// collection name
const COLLECTION_NAME = "objects";

interface ObjectContextType {
  objects: WorkObject[];
}

const ObjectsContext = createContext<ObjectContextType>({ objects: [] });

export const ObjectsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [objects, setObjects] = useState<WorkObject[]>([]);
  const { currentCustomer } = useContext(CustomerContext);

  useEffect(() => {
    if (!currentCustomer) return;

    const objectsRef = collection(db, COLLECTION_NAME);
    const byCustomerQuery = query(objectsRef, where("customerId", "==", currentCustomer.id));

    const unsubscribe = onSnapshot(byCustomerQuery, async (snapshot) => {
      const objectsArray: WorkObject[] = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const objectData = doc.data() as Omit<WorkObject, "id">;
          return {
            id: doc.id,
            ...objectData,
          } as WorkObject;
        })
      );

      setObjects(objectsArray);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [currentCustomer]);

  return <ObjectsContext.Provider value={{ objects }}>{children}</ObjectsContext.Provider>;
};

export default ObjectsContext;
