import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import UsersContext from "context/UsersContext";
import { UserModel } from "db/models/users/UserModel";

interface MappedUser {
  id: string;
  firstName: string;
  lastName: string;
}

interface UserDropDownProps {
  setUsers: Dispatch<SetStateAction<UserModel[]>>;
  selectedUsersIds?: string[];
}

const ALL_PERSONAL: MappedUser = {
  id: "all",
  firstName: "All",
  lastName: "Personal",
};

function UsersDropDown({ setUsers, selectedUsersIds = [] }: UserDropDownProps): JSX.Element {
  const { users } = useContext(UsersContext);
  const [mappedUsers, setMappedUsers] = useState<MappedUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<MappedUser[]>([]);

  useEffect(() => {
    if (users) {
      const initialMappedUsers: MappedUser[] = users.map((user) => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      }));
      setMappedUsers(initialMappedUsers);

      if (selectedUsersIds.length > 0) {
        const initiallySelectedUsers = initialMappedUsers.filter((user) =>
          selectedUsersIds.includes(user.id)
        );
        setSelectedUsers(initiallySelectedUsers);
        setUsers(users.filter((user) => selectedUsersIds.includes(user.id)));
      }
    }
  }, [users, selectedUsersIds, setUsers]);

  const handleOnChange = (event: React.SyntheticEvent, newValue: MappedUser[]) => {
    if (newValue.some((user) => user.id === ALL_PERSONAL.id)) {
      const allSelected = newValue.includes(ALL_PERSONAL);
      setSelectedUsers(allSelected ? mappedUsers : []);
      setUsers(allSelected ? users : []);
    } else {
      setSelectedUsers(newValue);
      setUsers(users.filter((user) => newValue.some((mappedUser) => mappedUser.id === user.id)));
    }
  };

  const options = [ALL_PERSONAL, ...mappedUsers];

  return (
    <Autocomplete
      multiple
      id="users-autocomplete"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedUsers}
      onChange={handleOnChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            className="users-option"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={
              selected ||
              (option.id === ALL_PERSONAL.id && selectedUsers.length === mappedUsers.length)
            }
          />
          {option.firstName} {option.lastName}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          id="user-drop-drown"
          {...params}
          label="Välj personal"
          placeholder="Välj en eller flera ur personalen"
        />
      )}
    />
  );
}

export default UsersDropDown;
