import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

// Array of days with corresponding JavaScript DayOfWeek values
const daysOfWeek = [
  { label: "Måndag", value: 1 },
  { label: "Tisdag", value: 2 },
  { label: "Onsdag", value: 3 },
  { label: "Torsdag", value: 4 },
  { label: "Fredag", value: 5 },
  { label: "Lördag", value: 6 },
  { label: "Söndag", value: 0 },
];

// Props interface for the component
interface DaySelectionComponentProps {
  onSelectionChange: (selectedDays: number[]) => void; // Callback to send selected days (as numbers) to parent
}

const DaySelectionComponent: React.FC<DaySelectionComponentProps> = ({ onSelectionChange }) => {
  // Initialize selectedDays state with Monday to Friday selected by default
  const [selectedDays, setSelectedDays] = useState<number[]>([1, 2, 3, 4, 5]); // Mon-Fri selected by default

  // Effect to notify parent of the initial default selection
  useEffect(() => {
    onSelectionChange([1, 2, 3, 4, 5]); // Notify parent of default selection
  }, []); // Empty dependency array to run only on mount

  const handleDayToggle = (dayValue: number) => {
    setSelectedDays((prevSelected) => {
      const isSelected = prevSelected.includes(dayValue);
      const newSelection = isSelected
        ? prevSelected.filter((selectedDay) => selectedDay !== dayValue) // Deselect the day
        : [...prevSelected, dayValue]; // Select the day

      onSelectionChange(newSelection); // Pass selected days back to parent
      return newSelection;
    });
  };

  return (
    <Box my={4} mx={4}>
      {/* Title positioned above the buttons */}
      <Typography variant="h6" gutterBottom my={2}>
        Vilka dagar skall arbetspassen skapas på
      </Typography>

      <Grid container spacing={2} justifyContent="flex-start">
        {daysOfWeek.map((day) => (
          <Grid item key={day.value}>
            <Button
              variant={selectedDays.includes(day.value) ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleDayToggle(day.value)}
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                minWidth: 50,
                color: selectedDays.includes(day.value) ? "white" : "#1976d2", // Primary color if not selected
              }}
            >
              {day.label.substring(0, 3)} {/* Display only first 3 letters of the day */}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DaySelectionComponent;
